import type { AppPreloadedContextQuery } from '__generated__/AppPreloadedContextQuery.graphql';
import { createContext, useContext, type PropsWithChildren } from 'react';
import { loadQuery, usePreloadedQuery, type PreloadedQuery } from 'react-relay';
import { graphql } from 'relay-runtime';
import MainEnvironment from 'relay/environment';
import { readViewerBizProfileBroker } from 'utils/bizProfile';

const InitialAppQuery = graphql`
  query AppPreloadedContextQuery {
    viewer {
      id
      profileImageUrl
      nickname
      originalId
      ...bizProfileBroker_viewer
    }
  }
`;

const queryReference = loadQuery(MainEnvironment, InitialAppQuery, {});

const AppPreloadedContext = createContext({
  queryReference,
  InitialAppQuery,
});

export const AppPreloadedContextProvider = ({ children }: PropsWithChildren) => {
  return (
    <AppPreloadedContext.Provider value={{ queryReference, InitialAppQuery }}>
      {children}
    </AppPreloadedContext.Provider>
  );
};

export const useAppPreloadedQuery = () => {
  const { queryReference, InitialAppQuery } = useContext(AppPreloadedContext);

  const query = usePreloadedQuery(
    InitialAppQuery,
    queryReference as PreloadedQuery<AppPreloadedContextQuery>
  );

  return {
    ...query,
    bizProfileBroker: readViewerBizProfileBroker(query.viewer),
  };
};
