import { IconSearchRegular } from '@seed-design/react-icon';
import { vars } from '@seed-design/design-token';
import { forwardRef } from 'react';
import TextField from 'components/Base/TextField';
import { PropsOf } from '@chakra-ui/system';
import TextFieldRemoveButton from 'components/Base/TextFieldRemoveButton';
import { ClassNames } from '@emotion/react';

type Props = Omit<
  PropsOf<typeof TextField> & {
    onClickDelete: () => void;
  },
  'size' | 'prefix' | 'suffix'
>;

const SearchField = forwardRef<HTMLInputElement, Props>(
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ({ value, onClickDelete, UNSAFE_className, ...props }: Props, ref) => {
    return (
      <ClassNames>
        {({ css }) => (
          <TextField
            ref={ref}
            size="small"
            value={value}
            prefix={<IconSearchRegular color={vars.$scale.color.gray500} width={16} height={16} />}
            suffix={!!value && <TextFieldRemoveButton onClickDelete={onClickDelete} />}
            UNSAFE_className={css([
              css`
                &[data-focus] [data-part='field'] {
                  background: var(--seed-text-field-enabled-field-background);
                  border-color: var(--seed-text-field-enabled-field-border-color);
                }
              `,
              UNSAFE_className,
            ])}
            {...props}
          />
        )}
      </ClassNames>
    );
  }
);

export default SearchField;
