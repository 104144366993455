export const zIndices = {
  hide: -1,
  auto: 'auto',
  base: 0,
  /** 동일 스택에서 다른 zIndex 값을 적용할 때 사용해요. */
  docked: 10,
  /** 드롭다운 아이콘 등에 사용해요. */
  dropdown: 1000,
  /** position: sticky 컴포넌트에 사용해요. */
  sticky: 1100,
  /** 페이지 맥락에서 벗어나면서 overlay 아래에 깔리는 컴포넌트에 사용해요. */
  banner: 1200,
  /** Dim과 같은 overlay 용도로 사용해요. */
  overlay: 1300,
  /** Dialog와 같은 모달에 사용해요. */
  modal: 1400,
  /** 매너온도 툴팁과 같이 팝업 컴포넌트에 사용해요. */
  popover: 1500,
  /** toast를 띄울 때 사용해요. */
  toast: 1600,
  /** 페이지를 덮는 툴팁에 사용해요. */
  tooltip: 1700,
  /** GlobalLoading과 같이 최상단에 위치하는 로딩 컴포넌트에 사용해요. */
  outermost: 1800,
};
