import authPlugin from '@daangn/plantae-plugin-auth';
import requestIdPlugin from '@daangn/plantae-plugin-request-id';
import commonHeadersPlugin from '@daangn/plantae-plugin-common-headers';
import { AxiosError, AxiosInstance } from 'axios';
import axiosRetry, { isNetworkOrIdempotentRequestError } from 'axios-retry';
import { karrotBridge } from 'bridge';
import { getApp, getUser } from 'bridge/info';
import { createAxiosInterceptors } from 'plantae/axios';
import { logApiError } from 'utils/axios';

export const addDaangnInterceptor = (instance: AxiosInstance) => {
  const daangnAuthPlugin = authPlugin({
    bridge: karrotBridge,
    options: {
      fallbackAuthToken: false,
    },
  });
  const daangnRequestIdPlugin = requestIdPlugin();
  const daangnCommonHeadersPlugin = commonHeadersPlugin({
    bridge: karrotBridge,
  });

  const { request, response } = createAxiosInterceptors({
    client: instance,
    plugins: [daangnAuthPlugin, daangnRequestIdPlugin, daangnCommonHeadersPlugin],
  });
  const appPromise = getApp();
  const userPromise = getUser();

  instance.interceptors.request.use(async (config) => {
    const app = await appPromise;
    const user = await userPromise;

    if (user?.authToken) {
      config.headers.set('X-AUTH-TOKEN', user.authToken);
    }

    if (app?.userAgent) {
      config.headers.set('X-User-Agent', app.userAgent);
    }

    if (user?.isAlphaLogin) {
      config.headers.delete('authorization');
    }

    return config;
  });
  instance.interceptors.request.use(request.onFulfilled, request.onRejected);
  instance.interceptors.response.use(response.onFulfilled, response.onRejected);
};

export const addRetryInterceptor = (
  instance: AxiosInstance,
  { retryCount, retryDelay }: { retryCount: number; retryDelay: number }
) => {
  instance.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
      // https://github.com/softonic/axios-retry/blob/3822205c6d2efc8eaa6a67d000fe7509bdde99b3/es/index.mjs#L216
      const isMaxRetryError = (error?.config?.['axios-retry']?.retryCount ?? 0) >= retryCount;
      const canRetry = isNetworkOrIdempotentRequestError(error);

      if (!canRetry || isMaxRetryError) {
        logApiError(error);
      }
      return Promise.reject(error);
    }
  );

  axiosRetry(instance, {
    retries: retryCount,
    retryDelay: () => retryDelay,
    onRetry: (_, error, requestConfig) => {
      if (error.config) {
        // https://github.com/axios/axios/issues/5089
        requestConfig.headers = JSON.parse(JSON.stringify(error.config.headers));
      }
    },
  });
};
