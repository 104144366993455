import {
  DialogContainer as SproutDialogContainer,
  AlertDialog as SproutAlertDialog,
  SeedAlertDialogProps,
  SeedDialogContainerProps,
} from '@daangn/sprout-components-dialog';
import { LoggerComponentProps } from 'logger/types/common';
import { ReactElement, useEffect } from 'react';
import Logger from 'utils/Logger';

function isAlertDialog(element: ReactElement<any>): element is ReactElement<AlertDialogProps> {
  return element.type === AlertDialog;
}

export type DialogContainerProps = Omit<SeedDialogContainerProps, 'onOpenChange'> & {
  children: ReactElement<AlertDialogProps>;
  closeOnOutsideClick?: boolean;
  isOpen: boolean;
  onClose: () => void;
  onOpen?: () => void;
};

export function DialogContainer({
  isOpen,
  onOpen,
  onClose,
  onOutsideClick,
  closeOnOutsideClick = true,
  children,
  ...props
}: DialogContainerProps) {
  if (!isAlertDialog(children)) {
    // eslint-disable-next-line no-console
    console.warn('The child of DialogContainer should be an AlertDialog.');
  }

  const handleOutsideClick = () => {
    onOutsideClick && onOutsideClick();
    closeOnOutsideClick && onClose();
  };

  const handleOpenChange = (isOpen: boolean) => {
    if (isOpen) {
      onOpen?.();
    } else {
      onClose();
    }
  };

  return (
    <SproutDialogContainer
      onOutsideClick={handleOutsideClick}
      onOpenChange={handleOpenChange}
      {...props}
    >
      {isOpen && children}
    </SproutDialogContainer>
  );
}

export type AlertDialogProps = SeedAlertDialogProps &
  LoggerComponentProps<
    'show_dialog' | 'click_dialog_primary_action' | 'click_dialog_secondary_action'
  >;

export function AlertDialog({
  onPrimaryAction,
  onSecondaryAction,
  event,
  ...props
}: AlertDialogProps) {
  const handlePrimaryAction = () => {
    onPrimaryAction && onPrimaryAction();

    event && Logger.track('click_dialog_primary_action', event.params);
  };

  const handleSecondaryAction = () => {
    onSecondaryAction && onSecondaryAction();

    event && Logger.track('click_dialog_secondary_action', event.params);
  };

  useEffect(() => {
    if (event) {
      Logger.track('show_dialog', event.params);
    }
  }, []);

  return (
    <SproutAlertDialog
      onPrimaryAction={handlePrimaryAction}
      onSecondaryAction={handleSecondaryAction}
      {...props}
    />
  );
}
