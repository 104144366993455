/**
 * @generated SignedSource<<be04a3944b19710c855c7037d5f78fbf>>
 * @relayHash 6bfb46ea48467fe1b4ea304c7c1d60c7
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 6bfb46ea48467fe1b4ea304c7c1d60c7

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RecapUserYearReportQuery$variables = Record<PropertyKey, never>;
export type RecapUserYearReportQuery$data = {
  readonly userYearReport: {
    readonly readArticleCount: number;
    readonly " $fragmentSpreads": FragmentRefs<"Step1_userYearReport" | "Step2_userYearReport" | "Step5_userYearReport">;
  };
};
export type RecapUserYearReportQuery = {
  response: RecapUserYearReportQuery$data;
  variables: RecapUserYearReportQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "readArticleCount",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "RecapUserYearReportQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserYearReport",
        "kind": "LinkedField",
        "name": "userYearReport",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Step1_userYearReport"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Step2_userYearReport"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Step5_userYearReport"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "RecapUserYearReportQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserYearReport",
        "kind": "LinkedField",
        "name": "userYearReport",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "countPercentile",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tags",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "6bfb46ea48467fe1b4ea304c7c1d60c7",
    "metadata": {},
    "name": "RecapUserYearReportQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "4ee95039a4d1887f2030eb7b29b1e624";

export default node;
