import React from 'react';
import { Center, Flex, Stack } from '@chakra-ui/layout';
import Text from 'components/Base/Text';
import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import RollingNumber from 'components/Base/RollingNumber';

export default function Step4() {
  return (
    <Wrapper px={20}>
      <Stack gap={16}>
        <Text variant="title1Bold">올해 거래된 가장 비싼 매물은</Text>

        <Text variant="title3Bold">
          부산에 있는 엘시티 아파트가
          <br />
          <ColoredText>
            <RollingNumber targetNumber={34} />
            억원
          </ColoredText>
          에 거래됐어요
        </Text>
      </Stack>

      <Center flexDirection="column" height="70%" justifyContent="center">
        <img
          src="https://assetstorage.krrt.io/1420322515413545053/094c2de0-7bd8-48f4-a573-b82d63146efe/width=480,height=480.webp"
          width={200}
          height={200}
        />
      </Center>
    </Wrapper>
  );
}

const Wrapper = styled(Stack)`
  display: relative;
  width: 100%;
  height: 100%;

  overflow: hidden;
`;

const ColoredText = styled(Flex)`
  color: ${vars.$semantic.color.primary};
  display: inline-flex;
`;
