import { isWeb, karrotBridge } from 'bridge';
import { IS_ALPHA } from 'config';
import { captureException } from '@sentry/react';
import { devLocalStorage } from 'utils/devStorage';
import type { KarrotBridge } from '@daangn/karrotbridge';

export type BridgeUser = Awaited<ReturnType<KarrotBridge['getUserInfo']>>['info']['user'];
export type BridgeRegion = Awaited<ReturnType<KarrotBridge['getRegionInfo']>>['info']['region'];
export type BridgeApp = Awaited<ReturnType<KarrotBridge['getAppInfo']>>['info']['app'];

export const getUser = async (): Promise<(BridgeUser & { isAlphaLogin?: boolean }) | null> => {
  const defaultUser = {
    id: +(process.env.REACT_APP_USER_ID ?? ''),
    displayRegionCheckinsCount: 1,
    authToken: process.env.REACT_APP_USER_AUTH_TOKEN ?? '',
    nickname: process.env.REACT_APP_USER_NICKNAME ?? '',
    hashId: '',
    phone: process.env.REACT_APP_USER_PHONE_NUMBER ?? '',
    profileImage: '',
  };
  const alphaLoginInfo = devLocalStorage.getSelectedAlphaLoginInfo();

  // alpha_login을 통해 로그인 한 경우
  if (IS_ALPHA && alphaLoginInfo) {
    return {
      ...defaultUser,
      isAlphaLogin: true,
      id: Number(alphaLoginInfo.userId),
      authToken: alphaLoginInfo.authToken,
      nickname: alphaLoginInfo.nickname,
    };
  }

  if (isWeb) {
    return defaultUser;
  }

  try {
    const {
      info: { user },
    } = await karrotBridge.getUserInfo({});

    return user;
  } catch (e) {
    captureException(e, {
      level: 'warning',
    });

    return null;
  }
};

export const getRegion = async (): Promise<BridgeRegion> => {
  const defaultRegion = {
    id: Number(process.env.REACT_APP_REGION_ID ?? 366),
    centerCoordinates: {
      latitude: Number(process.env.REACT_APP_DEFAULT_LATITUDE ?? 37.502675),
      longitude: Number(process.env.REACT_APP_DEFAULT_LONGITUDE ?? 127.022388),
    },
    name1: '서울특별시',
    name2: '서초구',
    name3: '서초4동',
    name1Id: 1,
    name2Id: 362,
    name3Id: 366,
    parentId: 362,
    name: '서초4동',
    fullname: '서울 서초구 서초4동',
  };

  if (isWeb) {
    return defaultRegion;
  }

  try {
    const alphaLoginInfo = devLocalStorage.getSelectedAlphaLoginInfo();

    // alpha_login을 통해 로그인 한 경우
    if (IS_ALPHA && alphaLoginInfo) {
      return {
        ...defaultRegion,
        id: Number(alphaLoginInfo.regionId),
      };
    }

    const {
      info: { region: regionFromBridge },
    } = await karrotBridge.getRegionInfo({});

    return regionFromBridge;
  } catch (e) {
    captureException(e, {
      level: 'warning',
    });

    return defaultRegion;
  }
};

export const getApp = async (): Promise<BridgeApp> => {
  const defaultApp = {
    userAgent: process.env.REACT_APP_USER_AGENT! ?? window.navigator.userAgent,
    deviceIdentity: process.env.REACT_APP_USER_DEVICE_IDENTITY!,
    locale: 'ko-KR',
    country: 'KR' as const,
    appHost: '',
    appVersion: '',
    appVersionCode: '',
  };

  if (isWeb) {
    return defaultApp;
  }

  try {
    const {
      info: { app },
    } = await karrotBridge.getAppInfo({});

    return app;
  } catch (e) {
    captureException(e, {
      level: 'warning',
    });

    return defaultApp;
  }
};
