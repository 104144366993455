import { RealtyInstance } from 'apis/instance';
import { atom } from 'jotai';
import { z } from 'zod';

const daangnErrorScheme = z.object({
  status: z.object({
    code: z.enum(['status_maintenance', 'client_update_required']),
    message: z.string(),
    type: z.enum(['alert', 'toast']),
  }),
});

export const daangnErrorState = atom(async () => {
  try {
    const { data } = await RealtyInstance.get('/graphql', {
      params: {
        query: '{__typename}',
      },
    });

    const result = daangnErrorScheme.parse(data);

    return result;
  } catch (error) {
    return null;
  }
});
