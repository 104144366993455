import { FC, memo, Suspense, useEffect } from 'react';
import { RelayEnvironmentProvider } from 'react-relay';
import environment from 'relay/environment';
import { Stack } from 'stackflow';
import { BrowserRouter } from 'react-router-dom';
import useInitApp from 'hooks/useInitApp';
import useNudgeController from 'hooks/useNudgeController';
import useDebugModeObserver from 'hooks/useDebugModeObserver';
import { AppPreloadedContextProvider, useAppPreloadedQuery } from 'contexts/AppPreloadedContext';
import { LocalStorage } from 'utils/Storage';
import { UserSegmentType } from 'utils/segment';

const App: FC = () => {
  useInitApp();
  useNudgeController();
  useDebugModeObserver();

  return (
    <RelayEnvironmentProvider environment={environment}>
      <AppPreloadedContextProvider>
        <AppNavigator />
        <Suspense fallback={null}>
          <SegmentSetter />
        </Suspense>
      </AppPreloadedContextProvider>
    </RelayEnvironmentProvider>
  );
};

export default App;

const AppNavigator = memo(() => {
  return (
    <BrowserRouter>
      <Stack />
    </BrowserRouter>
  );
});

const SegmentSetter = () => {
  const { bizProfileBroker } = useAppPreloadedQuery();

  useEffect(() => {
    if (bizProfileBroker) {
      LocalStorage.addUserSegment(UserSegmentType.Broker);
    }
  }, [bizProfileBroker]);

  return null;
};
