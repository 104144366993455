import { RealtyInstance as api } from 'apis/instance';

type HoainArticle = {
  id: number;
  title: string;
  content: string;
  type: string;
  userId: number;
  reviewsWithUserId: any[];
  publishRange: number;
  userPublishRange: number;
  publishedAt: string;
  createdAt: string;
  updatedAt: string;
  destroyedAt: string | null;
  categoryUid: string;
  price: string;
  priceDescription: string;
  reservedUserId: number | null;
  freeShareEventEnabled: boolean;
  firstImage: {
    file: string;
    filepath: string;
  };
  advertisement: {
    id: number | null;
  } | null;
  smb: any | null;
  readsCount: number;
  imageIds: any[];
  pictureIds: string[];
  images: {
    file: string;
    pictureId: string;
  }[];
  status: string;
  visible: boolean;
  offers: any[];
};

export default {
  /** In use on useMigrateArticle */
  fetchArticle: async (hoianArticleId: string) => {
    const { data } = await api.get<{
      data: {
        article: HoainArticle;
      };
    }>(`/api/v1/hoian_article/${hoianArticleId}`);

    return data.data.article;
  },
};
