import useIsDebug from 'hooks/useIsDebug';
import { PropsWithChildren } from 'react';

// Alpha - 항상 노출
// Production - debug 유저에게만 필요할 떄 노출

function withDev<P>(Component: React.ComponentType<P>) {
  return function WithDev(props: PropsWithChildren<P>) {
    const isDebug = useIsDebug();

    if (isDebug) {
      return <Component {...props} />;
    }

    return null;
  };
}

export default withDev;
