import sha256 from 'sha256';
import CONFIG from 'config';
import instance from './instance';
import { getApp, getUser } from 'bridge/info';

const api = instance({ baseURL: CONFIG.BIGPICTURE_HOST, isExternal: true });

const userPromise = getUser();
const appPromise = getApp();

export default {
  requestUploadUrl: async ({
    blob,
    file,
    image,
  }: {
    blob: Blob;
    file: File;
    image: HTMLCanvasElement;
  }) => {
    const user = await userPromise;
    const app = await appPromise;

    const { data } = await api.post(
      '/api/v2/request_upload_url',
      JSON.stringify({
        body: blob,
        region: 'kr',
        service: 'realty',
        type: 'article',
        width: image.width,
        height: image.height,
        content_type: file.type,
        user_id: user?.id,
        filepath: `realty/articles/${sha256(file.name)}_${Date.now()}.${file.type.replace(
          'image/',
          ''
        )}`,
      }),
      {
        headers: {
          'Content-Type': 'application/json',
          'X-AUTH-TOKEN': user?.authToken,
          'X-User-Agent': app?.userAgent || '',
        },
      }
    );

    return data;
  },
  uploadFileToS3: async ({
    url,
    blob,
    file,
    imageId,
  }: {
    url: string;
    blob: Blob;
    file: File;
    imageId: string;
  }): Promise<any> => {
    const { data } = await api.put(url, blob, {
      headers: {
        'Content-Type': file.type,
        'x-amz-meta-id': imageId,
      },
    });

    return data;
  },
};
