import { toast } from 'bridge/toast';
import { RelayErrorCode } from 'constants/error';
import { errorMessage } from 'constants/errorMessage';
import { GraphQLError, RelayNetworkError } from 'types/global';
import { actionToast } from 'utils/actionToast';

export function getRelayErrorMessage(error: RelayNetworkError) {
  const errors = error?.source?.errors ?? [];

  if (errors.length === 0) {
    return null;
  }

  return errors[0]?.message ?? errors[0]?.extensions?.message;
}

export const getRelayNetworkErrorHandler =
  (fallbackMessage = errorMessage.common) =>
  (networkError: RelayNetworkError) => {
    const message = getRelayErrorMessage(networkError);
    toast(message ?? fallbackMessage);
  };

/* eslint-disable @typescript-eslint/naming-convention */
type PopupError = {
  __typename: 'PopupError';
  popupError: {
    message: string;
  };
};
type ToastError = {
  __typename: 'ToastError';
  toastError: {
    message: string;
  };
};
type OtherResult = {
  __typename: `%other`;
};

const defaultToastHandler = (toastError: ToastError) =>
  actionToast({ message: toastError.toastError.message });
const defaultPopupHandler = (popupError: PopupError) => window.alert(popupError.popupError.message);

export function handleMutationResponse<
  ValidResult extends { __typename: string },
  T extends ToastError,
  P extends PopupError,
  Response extends ValidResult | T | P | OtherResult,
>(
  response: Response,
  handler: {
    onResult: (result: Exclude<Response, T | P | OtherResult>) => void;
    onPopupError?: (popupError: Extract<Response, PopupError>) => void;
    onToastError?: (toastError: Extract<Response, ToastError>) => void;
  }
) {
  if (response.__typename === 'PopupError') {
    (handler.onPopupError || defaultPopupHandler)?.(response as Extract<Response, PopupError>);
  } else if (response.__typename === 'ToastError') {
    (handler.onToastError || defaultToastHandler)?.(response as Extract<Response, ToastError>);
  } else {
    handler.onResult(response as Exclude<Response, T | P | OtherResult>);
  }
}

/* eslint-enable @typescript-eslint/naming-convention */

// code 존재시 서비스 레이어 레벨 에러
// https://daangn.slack.com/archives/C019D3X6CGZ/p1671074876092999
export const checkIsServiceError = (error?: GraphQLError) => {
  return error?.extensions?.code;
};

export const checkIsRelayNetworkError = (error?: Error): error is RelayNetworkError => {
  return !!error?.name?.match('RelayNetwork');
};

export const checkIsRelayErrorCode = (error: Error, relayErrorCode: RelayErrorCode) => {
  return (
    checkIsRelayNetworkError(error) &&
    error.source?.errors?.[0]?.extensions?.code === relayErrorCode
  );
};
