import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';

// https://www.figma.com/file/ty7UxJ61CVPeVU2Gf1LJGQ/App-Components?node-id=77%3A54&t=eO5LCUTFdrq9yD7a-0

const ColorSchemas = {
  basic: {
    primary: vars.$scale.color.gray700,
    onPrimary: vars.$scale.color.gray00,
    secondary: vars.$scale.color.gray100,
    onSecondary: vars.$scale.color.gray700,
    outline: vars.$scale.color.gray900,
  },
  primary: {
    primary: vars.$semantic.color.primary,
    onPrimary: vars.$semantic.color.onPrimary,
    secondary: vars.$scale.color.carrotAlpha100,
    onSecondary: vars.$semantic.color.primary,
    outline: vars.$semantic.color.primary,
  },
  success: {
    primary: vars.$semantic.color.success,
    onPrimary: vars.$static.color.staticWhite,
    secondary: vars.$semantic.color.successLow,
    onSecondary: vars.$scale.color.green700,
    outline: vars.$semantic.color.success,
  },
  error: {
    primary: vars.$semantic.color.danger,
    onPrimary: vars.$static.color.staticWhite,
    secondary: vars.$semantic.color.dangerLow,
    onSecondary: vars.$semantic.color.danger,
    outline: vars.$semantic.color.danger,
  },
  accent: {
    primary: vars.$scale.color.blue600,
    onPrimary: vars.$static.color.staticWhite,
    secondary: vars.$scale.color.blue50,
    onSecondary: vars.$scale.color.blue800,
    outline: vars.$scale.color.blue700,
  },
};

const SizeStyles = {
  large: css`
    padding: 4px 8px;
    min-height: 24px;
    ${vars.$semantic.typography.label4Regular}
  `,
  medium: css`
    padding: 2px 6px;
    min-height: 19px;
    ${vars.$semantic.typography.label5Regular}
  `,
  small: css`
    padding: 2px 4px;
    min-height: 18px;
    ${vars.$semantic.typography.label6Regular}
  `,
};

const TypeStyles = {
  square: {
    large: css`
      border-radius: 4px;
    `,
    medium: css`
      border-radius: 2px;
    `,
    small: css`
      border-radius: 2px;
    `,
  },
  pill: {
    large: css`
      border-radius: 12px;
    `,
    medium: css`
      border-radius: 10px;
    `,
    small: css`
      border-radius: 1000px;
    `,
  },
};

type BadgeType = keyof typeof TypeStyles;
type BadgeSize = keyof typeof SizeStyles;
export type BadgeProperty = keyof typeof ColorSchemas;
type Variant = 'normal' | 'fill' | 'outline';

type Props = {
  type?: BadgeType;
  size?: BadgeSize;
  property?: BadgeProperty;
  variant?: Variant;
};

const Badge = styled.span<Props>`
  display: inline-block;
  text-align: center;
  flex-shrink: 0;

  ${({ size = 'large', type = 'square' }) => {
    return [TypeStyles[type][size], SizeStyles[size]];
  }};

  ${({ property = 'basic', variant = 'normal' }) => {
    const colors = ColorSchemas[property];

    switch (variant) {
      case 'normal':
        return css`
          color: ${colors.onSecondary};
          background: ${colors.secondary};
        `;
      case 'fill':
        return css`
          font-weight: bold;
          color: ${colors.onPrimary};
          background: ${colors.primary};
        `;
      case 'outline':
        return css`
          font-weight: bold;
          color: ${colors.outline};
          border: 1px solid ${colors.outline};
        `;
    }
  }}
`;

export default Badge;
