import { useActivity } from '@stackflow/react';
import ErrorBase, { ErrorProps } from 'components/Error/ErrorBase';
import CONFIG from 'config';
import useCustomFlow from 'hooks/useCustomFlow';

const ErrorPage = ({ children, buttonOption }: Partial<ErrorProps>) => {
  const { isRoot, name } = useActivity();
  const { replace, pop } = useCustomFlow();

  const shouldFallbackToMain = isRoot && name !== 'main';

  const handleClick = () => {
    if (buttonOption?.onClick) {
      buttonOption.onClick();
      return;
    }

    if (shouldFallbackToMain) {
      replace('main', {});
    } else {
      pop();
    }
  };

  const label =
    buttonOption?.label ||
    (shouldFallbackToMain ? `${CONFIG.SERVICE_NAME} 홈으로 가기` : '뒤로가기');

  return <ErrorBase buttonOption={{ label, onClick: handleClick }}>{children}</ErrorBase>;
};

export default ErrorPage;
