import { EventParams, NudgeState } from 'store/event';
import { SalesTypeEnum } from 'types/schemaEnums';
import { LocalStorage } from 'utils/Storage';

type EventCollectorState = {
  articleClick: {
    currentType: SalesTypeEnum | null;
    count: number;
  };
};

type Listener = (nudgeState: NudgeState) => void;

class EventCollector {
  private state: EventCollectorState = {
    articleClick: {
      currentType: null,
      count: 0,
    },
  };
  private listeners: Listener[] = [];

  addListener(listener: Listener) {
    this.listeners.push(listener);
  }

  removeListener(listener: Listener) {
    this.listeners = this.listeners.filter((l) => l !== listener);
  }

  onChange(nextTooltipState: NudgeState) {
    this.listeners.forEach((listener) => listener(nextTooltipState));
  }

  push(event: EventParams) {
    let nextNudgeState: NudgeState | null = null;

    switch (event.key) {
      case 'ClickArticle':
        if (this.state.articleClick.currentType === event.value) {
          this.state.articleClick.count++;
        }
        this.state.articleClick.currentType = event.value;

        if (this.state.articleClick.count === 3) {
          nextNudgeState = {
            type: 'Filter',
            value: {
              salesType: event.value,
            },
          };
        }

        break;
      case 'ScrollBottom':
      case 'ClickMapButton': {
        nextNudgeState = {
          type: 'Region',
          value: true,
        };
        break;
      }
      case 'MapDetail':
      case 'CopyAddress': {
        nextNudgeState = {
          type: 'Map',
          value: true,
        };
        break;
      }
      case 'FirstVisit': {
        nextNudgeState = {
          type: 'MyPage',
          value: true,
        };
        break;
      }
      case 'ClickFilterButton': {
        LocalStorage.setNudgeHistory('Filter');
        break;
      }
      case 'ClickRegionButton': {
        LocalStorage.setNudgeHistory('Region');
        break;
      }
      case 'ClickMapButton': {
        LocalStorage.setNudgeHistory('Map');
        break;
      }
    }

    nextNudgeState && this.onChange(nextNudgeState);
  }
}

const NudgeEventCollector = new EventCollector();

export default NudgeEventCollector;
