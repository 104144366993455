/**
 * @generated SignedSource<<fa9c557e3aaa1d0bf34be50e06e3db59>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type SalesTypeEnum = "APART" | "ETC" | "OFFICETEL" | "OPEN_ONE_ROOM" | "SPLIT_ONE_ROOM" | "STORE" | "TWO_ROOM";
import { FragmentRefs } from "relay-runtime";
export type ArticleCaptionText_article$data = {
  readonly addressInfo: string;
  readonly buildingName: string | null;
  readonly region: {
    readonly name: string;
    readonly originalId: number;
  };
  readonly salesTypeV2: {
    readonly type: SalesTypeEnum;
  };
  readonly " $fragmentSpreads": FragmentRefs<"ArticleCostText_article">;
  readonly " $fragmentType": "ArticleCaptionText_article";
};
export type ArticleCaptionText_article$key = {
  readonly " $data"?: ArticleCaptionText_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArticleCaptionText_article">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "ArticleCaptionText_article"
};

(node as any).hash = "6035929c89d85997796b2611bc36d7eb";

export default node;
