import { PropsWithChildren, ReactNode } from 'react';

type Props = PropsWithChildren<{
  condition: boolean;
  wrapper: (children: ReactNode) => ReactNode;
}>;

function ConditionalWrapper({ condition, wrapper, children }: Props) {
  return <>{condition ? wrapper(children) : children}</>;
}

export default ConditionalWrapper;
