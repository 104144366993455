import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { IconChevronRightRegular } from '@seed-design/react-icon';
import { vars } from '@seed-design/design-token';
import { Box, Flex, HStack, Stack } from '@chakra-ui/layout';
import Text from 'components/Base/Text';
import { useAppPreloadedQuery } from 'contexts/AppPreloadedContext';
import useCustomFlow from 'hooks/useCustomFlow';
import { horizontalScroll, lineBreakText } from 'styles/mixin';
import { AllRecap } from 'pages/Recap';
import { graphql, useFragment } from 'react-relay';
import { Result_userYearReport$key } from '__generated__/Result_userYearReport.graphql';
import ArticleCarouselItem from 'components/Article/ArticleCarouselItem';
import Logger from 'utils/Logger';

export default function Result({
  userYearReportRef,
}: {
  userYearReportRef: Result_userYearReport$key;
}) {
  const { push } = useCustomFlow();
  const { viewer } = useAppPreloadedQuery();

  const userYearReport = useFragment(
    graphql`
      fragment Result_userYearReport on UserYearReport {
        readArticleCount
        countPercentile
        tags
        recommendArticles {
          id
          ...ArticleCarouselItem_article
        }
      }
    `,
    userYearReportRef
  );

  const contents = useMemo(() => {
    if (userYearReport.readArticleCount >= 5)
      return {
        title: (
          <>
            {viewer?.nickname}님과 이웃들의 <br />
            2024 당근 부동산 여정을 <br />
            함께 살펴봤어요!
          </>
        ),
        subtitle1: (
          <>
            {viewer?.nickname}님은 총 {userYearReport.readArticleCount}개의 <br />
            매물을 조회한 <ColoredText>상위 {userYearReport.countPercentile}%</ColoredText>{' '}
            이웃이에요
          </>
        ),
        subtitle2: <>{viewer?.nickname}님의 최애 유형은 다음과 같아요</>,
        tags: userYearReport.tags,
        subtitle3: <>{viewer?.nickname}님이 놓쳤던 관심 매물 🧡</>,
        recommendArticles: userYearReport.recommendArticles,
      };
    else
      return {
        title: (
          <>
            이웃들의 <br />
            2024 당근 부동산 여정을 <br />
            함께 살펴봤어요!
          </>
        ),
        subtitle1: <>이웃들은 평균 {AllRecap.readArticleCount}개의 매물을 조회했어요</>,
        tags: AllRecap.tags,
        subtitle2: <>이웃들의 최애 유형은 다음과 같아요</>,
        subtitle3: <>우리 동네 추천 매물 🧡</>,
        recommendArticles: userYearReport.recommendArticles,
      };
  }, [viewer]);

  const handleClickSeeDanggnRealty = () => {
    Logger.track('click_recap_more_button', {});
    push('main', {});
  };

  return (
    <Wrapper>
      <Box px={20}>
        <Flex flexDirection="column" gap={16} alignItems="center" marginTop={12}>
          <img
            src="https://s3-alpha-sig.figma.com/img/e63b/b90b/c85c18be13d51ce4997bf673af0f0dd4?Expires=1731888000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=PoRX~Qsp9OT9fqaS3U37RENE~Q53dra7SDFA5ZXBpB8toSPXsDp~0Qa83EuWKbBLCHkZ3QHUBb3K8VwFbQJkDpUKr5CROo3bl9TQrgYvbT5VAWB5w1cCtphh84i42WvPKsm2FGivi8Pt3V--NnmW1asneI5Szba-NU8vy1QFztT7spawI2q8WYveoF~h7hzQ07lr7~jkWVHZgbhxoF6Mh8ZBL3vH6rGuWmxnb-sFrHyEosQ0jUxR0lCJpgQEP~rHLFs6qdW0EgVF8-qiGyITyZYtY0NJIQTAn0SpqWbrFmHuytmbcfxG6TDMZBsnoL4b62BZVNjNSBMbGRwD-raa0g__"
            width={64}
            height={64}
          />

          <Text variant="title2Bold" textAlign="center" css={lineBreakText}>
            {contents.title}
          </Text>
        </Flex>

        <Stack gap={12} mt={24}>
          {/* 매물 조회수 */}
          <ResultBox>
            <Text variant="subtitle1Bold">{contents.subtitle1}</Text>
          </ResultBox>

          {/* 매물 유형 태그 */}
          <ResultBox gap={4}>
            <Text variant="subtitle1Bold">{contents.subtitle2}</Text>
            <Text variant="bodyL2Regular">{contents.tags.map((tag) => `#${tag}`).join(', ')}</Text>
          </ResultBox>
        </Stack>

        {/* 관심 매물 */}
        {contents.recommendArticles.length > 0 && (
          <Stack gap={16} mt={51}>
            <Flex justifyContent="space-between">
              <Text variant="subtitle1Bold">{contents.subtitle3}</Text>
              <IconChevronRightRegular
                width={16}
                height={16}
                onClick={handleClickSeeDanggnRealty}
              />
            </Flex>

            <List gap={10}>
              {contents.recommendArticles.map((article, index) => (
                <ArticleCarouselItem
                  key={article.id}
                  index={index}
                  articleRef={article}
                  section="realty_recap"
                />
              ))}
            </List>
          </Stack>
        )}
      </Box>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: relative;
  width: 100%;
  height: 100%;
  padding-bottom: 20px;

  overflow: scroll;
`;

const ResultBox = styled(Stack)`
  border-radius: 12px;
  background: ${vars.$static.color.staticWhiteAlpha200};
  padding: 16px 20px;
`;

const ColoredText = styled.span`
  color: ${vars.$semantic.color.primary};
`;

const List = styled(HStack)`
  ${horizontalScroll}
`;
