import { Portal } from '@daangn/karrot-clothes';
import { captureException } from '@sentry/react';
import { useActivity } from '@stackflow/react';
import { PropsWithChildren, useEffect, useState } from 'react';
import { PortalDataKey } from 'utils/portal';

const PageNavPortal = ({ children }: PropsWithChildren<unknown>) => {
  const activity = useActivity();
  const activityId = activity?.id;
  const [showNavPortal, setShowNavPortal] = useState(true);

  useEffect(() => {
    // Stackflow 구조가 바뀌어서 PageLayout에서 navbar portal placeholder를 못잡은 경우에는 dim을 띄우지 않음.
    // page portal placeholder는 Realty 코드에서 넣어주므로 없을 수 없음.
    const navbarPortal = document.querySelector(`[data-${PortalDataKey.navbar}="${activityId}"]`);

    if (!navbarPortal) {
      captureException(new Error(`navbar portal not found: ${activityId}`));
    }

    setShowNavPortal(!!navbarPortal);
  }, []);

  if (!showNavPortal) {
    return null;
  }

  return (
    <Portal selector={activityId ? `[data-${PortalDataKey.navbar}="${activityId}"]` : undefined}>
      {children}
    </Portal>
  );
};

export default PageNavPortal;
