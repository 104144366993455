import { ChipToggleButton } from '@daangn/sprout-components-chips';
import FilterSection from 'components/Filter/FilterSection';
import { ArticleOptionNameEnum } from 'types/schemaEnums';
import { xor } from 'lodash-es';
import { Wrap, WrapItem } from '@chakra-ui/layout';
import { getArticleOptionNameText } from '@daangn/realty-sdk';
import { filterType } from 'constants/article';

export const OptionTypes: ArticleOptionNameEnum[] = [
  'PARKING',
  'PET',
  'MORTGAGE',
  'ELEVATOR',
  'ROOFTOP',
  'LOFT',
];
const PossibleLabelNeedOption: ArticleOptionNameEnum[] = ['PARKING', 'PET', 'MORTGAGE'];

type Props = {
  options?: readonly ArticleOptionNameEnum[] | null;
  onChangeOptions: (options: readonly ArticleOptionNameEnum[]) => void;
  moveInNow?: boolean | null;
  onChangeMoveInNow?: (moveInNow: boolean | null) => void;
  useMoveInNow?: boolean;
};

const OptionsSetting = ({
  options,
  moveInNow,
  onChangeOptions,
  onChangeMoveInNow,
  useMoveInNow,
}: Props) => {
  const handleClickOption = (option: ArticleOptionNameEnum) => {
    onChangeOptions(xor(options, [option]));
  };

  const handleClickMoveInOption = () => {
    onChangeMoveInNow?.(moveInNow ? null : true);
  };

  return (
    <FilterSection title={filterType.options}>
      <Wrap spacing={8}>
        {OptionTypes.map((option) => {
          const isChecked = options?.includes(option);

          return (
            <WrapItem key={option}>
              <ChipToggleButton
                size="medium"
                isSelected={isChecked}
                onClick={() => handleClickOption(option)}
              >
                {getArticleOptionNameText(option, { isBroker: false })}
                {PossibleLabelNeedOption.includes(option) && ' 가능'}
              </ChipToggleButton>
            </WrapItem>
          );
        })}
        {useMoveInNow && (
          <WrapItem>
            <ChipToggleButton
              size="medium"
              onClick={handleClickMoveInOption}
              isSelected={moveInNow ?? false}
            >
              즉시 입주 가능
            </ChipToggleButton>
          </WrapItem>
        )}
      </Wrap>
    </FilterSection>
  );
};

export default OptionsSetting;
