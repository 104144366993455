// src/hooks/useLatest.ts
import { useRef } from "react";
var useLatest = (value) => {
  const ref = useRef(value);
  ref.current = value;
  return ref;
};

// src/hooks/useIO.ts
import { useEffect, useState } from "react";
var useIO = (target, onObserve, options) => {
  const { root, rootMargin, threshold, once = false } = options || {};
  const callback = useLatest(onObserve);
  const [intersectedMap] = useState(() => /* @__PURE__ */ new Map());
  useEffect(() => {
    const $target = target.current;
    if (!$target) {
      return void 0;
    }
    if (once && intersectedMap.get($target)) {
      return void 0;
    }
    const observer = new IntersectionObserver(
      ([entry]) => {
        callback.current(entry);
        if (entry.isIntersecting) {
          intersectedMap.set($target, true);
          once && observer.disconnect();
        }
      },
      { root, rootMargin, threshold }
    );
    observer.observe($target);
    return () => observer.disconnect();
  }, [target.current, once, root, rootMargin, threshold]);
};

export {
  useLatest,
  useIO
};
