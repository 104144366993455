import { Divider, Stack } from '@chakra-ui/layout';
import ArticleCardSkeleton from 'components/Base/Skeleton/ArticleCardSkeleton';

const ArticleListSkeleton = () => {
  return (
    <Stack spacing={16} divider={<Divider />}>
      {[...Array(10)].map((_, i) => (
        <ArticleCardSkeleton key={i} />
      ))}
    </Stack>
  );
};

export default ArticleListSkeleton;
