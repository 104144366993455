import { uniqBy } from 'lodash-es';
import type { AlphaLoginUser } from 'pages/AlphaLogin';
import CommonStorage from 'utils/commonStorage';

const DevLocalStorageKey = {
  // 알파 웹 로그인 정보 저장
  AlphaLoginInfoList: 'alphaLoginInfoList',
} as const;

class DevLocalStorage extends CommonStorage<typeof DevLocalStorageKey> {
  constructor() {
    super('realty-dev', window.localStorage);
  }

  getAlphaLoginInfoList() {
    const item = this.getItem(DevLocalStorageKey.AlphaLoginInfoList);

    return item ? (JSON.parse(item) as AlphaLoginUser[]) : [];
  }

  addAlphaLoginInfo(AlphaLoginInfo: AlphaLoginUser) {
    const alphaLoginInfoList = this.getAlphaLoginInfoList();

    this.setItem(
      DevLocalStorageKey.AlphaLoginInfoList,
      JSON.stringify(uniqBy([AlphaLoginInfo, ...alphaLoginInfoList], 'userId'))
    );
  }

  removeAlphaLoginInfo(userId: string) {
    const alphaLoginInfoList = this.getAlphaLoginInfoList();

    this.setItem(
      DevLocalStorageKey.AlphaLoginInfoList,
      JSON.stringify(alphaLoginInfoList.filter((info) => info.userId !== userId))
    );
  }

  setSelectedAlphaLoginInfo(userId: string) {
    const alphaLoginInfoList = this.getAlphaLoginInfoList();

    this.setItem(
      DevLocalStorageKey.AlphaLoginInfoList,
      JSON.stringify(
        alphaLoginInfoList.map((info) => ({
          ...info,
          isSelected: info.userId === userId,
        }))
      )
    );
  }

  getSelectedAlphaLoginInfo() {
    const alphaLoginInfoList = this.getAlphaLoginInfoList();

    return alphaLoginInfoList.find((info) => info.isSelected);
  }

  resetSelectedAlphaLoginInfo() {
    const alphaLoginInfoList = this.getAlphaLoginInfoList();

    this.setItem(
      DevLocalStorageKey.AlphaLoginInfoList,
      JSON.stringify(
        alphaLoginInfoList.map((info) => ({
          ...info,
          isSelected: false,
        }))
      )
    );
  }
}

export const devLocalStorage = new DevLocalStorage();
