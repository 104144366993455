import GlobalLoading from 'components/Base/GlobalLoading';
import { useEffect, useMemo, useState } from 'react';
import { getCurrentPageSkeleton } from 'utils/skeleton';

const BASE_SPINNER_MS = 3000;

const PageLoading = () => {
  const Skeleton = useMemo(() => getCurrentPageSkeleton(), []);
  const [showSpinner, setShowSpinner] = useState(false);
  const shouldUseSpinnerFallback = !Skeleton;

  useEffect(() => {
    if (!shouldUseSpinnerFallback) {
      return;
    }

    const timer = setTimeout(() => {
      setShowSpinner(true);
    }, BASE_SPINNER_MS);

    return () => clearTimeout(timer);
  }, [shouldUseSpinnerFallback]);

  if (Skeleton) {
    return <Skeleton />;
  }

  return showSpinner ? <GlobalLoading /> : null;
};

export default PageLoading;
