import type { BridgeUser } from 'bridge/info';
import { toast } from 'bridge/toast';
import { useAtomValue } from 'jotai';
import { useCallback } from 'react';
import sessionState from 'store/session';

const useSessionState = () => {
  const { user, region, app } = useAtomValue(sessionState);

  const checkIsLogin = useCallback(
    (errorMessage = '로그인 후 가능해요.') => {
      if (!user?.id) {
        toast(errorMessage);
        return false;
      }
      return true;
    },
    [user]
  );

  const isMe = useCallback((userId: BridgeUser['id']) => userId === user?.id, [user]);

  return {
    user,
    region,
    checkIsLogin,
    isMe,
    app,
  };
};

export default useSessionState;
