import ActionToast from 'components/Toast/ActionToast';
import { AnimatePresence } from 'framer-motion';
import { useMemo, useSyncExternalStore } from 'react';
import { actionToastStore, TActionToastState } from 'utils/actionToast';

type Props = {
  baseOptions?: Partial<TActionToastState>;
};

const ToastContainer = ({ baseOptions }: Props) => {
  const toast = useSyncExternalStore(actionToastStore.listen, actionToastStore.getState);
  const toastState = useMemo(() => (!toast ? null : { ...baseOptions, ...toast }), [toast]);

  return (
    <AnimatePresence>
      {toastState && <ActionToast key={toastState.id} toastState={toastState} />}
    </AnimatePresence>
  );
};

export default ToastContainer;
