import { StackflowPlugin } from '@stackflow/core';
import { karrotBridge } from 'bridge';
import Logger from 'utils/Logger';

let userInteracted = false;

window.addEventListener(
  'click',
  () => {
    userInteracted = true;
  },
  { once: true }
);

const closeWhenRootPopPlugin = (): StackflowPlugin => {
  return () => ({
    key: 'closeWhenRootPop',
    onBeforePop({ actions }) {
      const stack = actions.getStack();
      const activeActivity = stack.activities.find((activity) => activity.isActive);

      if (activeActivity?.isRoot) {
        actions.preventDefault();

        Logger.click({
          name: 'click_close',
          description: 'X 버튼 클릭',
          user_interacted: userInteracted,
        });

        karrotBridge.closeRouter({});
      }
    },
  });
};

export default closeWhenRootPopPlugin;
