/**
 * @generated SignedSource<<04ca57c0c9b9a8a2c60eebe2113e7d63>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type BizProfileBrokerRoleEnum = "ADMIN" | "OWNER";
import { FragmentRefs } from "relay-runtime";
export type bizProfileBroker_viewer$data = {
  readonly bizProfileBroker: {
    readonly autoApproveArticles: boolean;
    readonly bizProfile: {
      readonly businessRegistrationNumber: string;
      readonly createdAt: string;
      readonly id: string;
      readonly jibunAddress: string;
      readonly licenseOwnerContact: string;
      readonly licenseOwnerName: string;
      readonly licenseRegistrationNumber: string;
      readonly localProfileId: string;
      readonly name: string;
      readonly originalId: string;
      readonly thumbImageUrl: string;
    };
    readonly id: string;
    readonly role: BizProfileBrokerRoleEnum;
    readonly userPhoneNumber: string;
  } | null;
  readonly id: string;
  readonly " $fragmentType": "bizProfileBroker_viewer";
};
export type bizProfileBroker_viewer$key = {
  readonly " $data"?: bizProfileBroker_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"bizProfileBroker_viewer">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "bizProfileBroker_viewer"
};

(node as any).hash = "1c427ed99c7242ac57b3feb2b752428b";

export default node;
