import { type BridgeRegion, getApp, getRegion, getUser } from 'bridge/info';
import regionApi from 'apis/realty/regions';
import { atomWithDefault } from 'jotai/utils';

const getRegionWithApi = async (): Promise<BridgeRegion> => {
  const regionFromBridge = await getRegion();

  try {
    const user = await getUser();

    if (!regionFromBridge?.centerCoordinates) {
      if (!user?.userExtend?.region1) {
        return regionFromBridge;
      }

      const regionCenterCoordinate = await regionApi.getCenterCoordinate(
        Number(user.userExtend?.region1?.id)
      );

      return {
        ...regionFromBridge,
        ...(regionCenterCoordinate && {
          centerCoordinates: {
            latitude: Number(regionCenterCoordinate.lat),
            longitude: Number(regionCenterCoordinate.lon),
          },
        }),
      };
    }

    return regionFromBridge;
  } catch {
    return regionFromBridge;
  }
};

const sessionState = atomWithDefault(async () => {
  const [user, region, app] = await Promise.all([getUser(), getRegionWithApi(), getApp()]);

  return {
    user,
    region,
    app,
  };
});

export default sessionState;
