type Primitive = number | string | boolean | null | undefined;

export const filterNil = <T extends { [key: string]: any }>(obj: T) => {
  const entries = Object.entries(obj).filter(([, v]) => v !== null && v !== undefined);

  return Object.fromEntries(entries) as { [key in keyof T]-?: NonNullable<T[key]> };
};

const getFlatEntries = (o: { [key: string]: any }, prefix = ''): [string, Primitive][] =>
  Object.entries(o).flatMap(([k, v]: [string, any]) =>
    Object(v) === v ? getFlatEntries(v, `${prefix}${k}_`) : [[`${prefix}${k}`, v]]
  );

export const getFlatObject = <T extends { [key: string]: any }>(object: T) => {
  return Object.fromEntries(getFlatEntries(object)) as { [key in keyof T]: string } & {
    [key: string]: string;
  };
};

export const upsertWith = <Arr extends readonly any[]>(
  arr: Arr,
  item: any,
  comparator: (item: Arr[number]) => boolean
) => {
  const index = arr.findIndex(comparator);

  if (index === -1) {
    return [...arr, item];
  }

  return [...arr.slice(0, index), item, ...arr.slice(index + 1)];
};

type Truthy<T> = T extends false | '' | 0 | null | undefined ? never : T;

export const truthy = <T>(value: T): value is Truthy<T> => {
  return !!value;
};
