import { Portal } from '@daangn/karrot-clothes';
import { useActivity } from '@stackflow/react';
import { PropsWithChildren } from 'react';
import { PortalDataKey } from 'utils/portal';

const PageContentPortal = ({ children }: PropsWithChildren<unknown>) => {
  const activity = useActivity();
  const activityId = activity?.id;

  return (
    <Portal selector={activityId ? `[data-${PortalDataKey.content}="${activityId}"]` : undefined}>
      {children}
    </Portal>
  );
};

export default PageContentPortal;
