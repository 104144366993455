import { PropsOf } from '@emotion/react';
import { IOOptions } from '@daangn/realty-react/hooks';
import Logger from 'utils/Logger';
import { InViewChecker } from '@daangn/realty-react/components';
import { LoggerEventParams, LoggerEventType } from 'logger/schema/eventSchema';

type ImpressionLoggerProps<TEventName extends LoggerEventType> = {
  event: {
    name: TEventName;
    params: LoggerEventParams<TEventName>;
  };
};

type Props<TEventName extends LoggerEventType> = {
  children: React.ReactNode;
  options?: IOOptions;
  onImpression?: () => void;
} & Omit<PropsOf<typeof InViewChecker>, 'options' | 'onInView'> &
  ImpressionLoggerProps<TEventName>;

const ImpressionLog = <TEventName extends LoggerEventType>({
  event,
  options,
  children,
  onImpression,
  ...props
}: Props<TEventName>) => {
  return (
    <InViewChecker
      {...props}
      options={{ threshold: 0.5, once: true, ...options }}
      onInView={() => {
        Logger.track(event.name, event.params);
        onImpression?.();
      }}
    >
      {children}
    </InViewChecker>
  );
};

export default ImpressionLog;
