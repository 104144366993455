import { useState } from 'react';
import { ConnectionHandler, graphql, useFragment, useMutation } from 'react-relay';
import { getRelayNetworkErrorHandler } from 'utils/relay';
import { useWatchArticle_Article$key } from '__generated__/useWatchArticle_Article.graphql';
import { useWatchArticle_MakeViewerUnwatchArticleMutation } from '__generated__/useWatchArticle_MakeViewerUnwatchArticleMutation.graphql';
import { useWatchArticle_MakeViewerWatchArticleMutation } from '__generated__/useWatchArticle_MakeViewerWatchArticleMutation.graphql';
import { LocalStorage } from 'utils/Storage';
import { UserSegmentType } from 'utils/segment';

const useWatchArticle = (articleRef: useWatchArticle_Article$key) => {
  const [connections] = useState(() => [
    ConnectionHandler.getConnectionID('root', 'useWatchArticlesResult_viewerWatchArticles'),
  ]);

  const article = useFragment<useWatchArticle_Article$key>(
    graphql`
      fragment useWatchArticle_Article on Article {
        id
        originalId
        isViewerWatched
        watchCount
      }
    `,
    articleRef
  );

  const [makeViewerWatchArticle, isWatchArticleInFlight] =
    useMutation<useWatchArticle_MakeViewerWatchArticleMutation>(graphql`
      mutation useWatchArticle_MakeViewerWatchArticleMutation(
        $input: MakeViewerWatchArticleInput!
        $connections: [ID!]!
      ) {
        makeViewerWatchArticle(input: $input) {
          ... on MakeViewerWatchArticleOutput_Result {
            result {
              article
                @prependNode(connections: $connections, edgeTypeName: "ViewerWatchArticleEdge") {
                id
                isViewerWatched
                watchCount
              }
            }
          }
        }
      }
    `);

  const [makeViewerUnwatchArticle, isUnWatchArticleInFlight] =
    useMutation<useWatchArticle_MakeViewerUnwatchArticleMutation>(graphql`
      mutation useWatchArticle_MakeViewerUnwatchArticleMutation(
        $input: MakeViewerUnwatchArticleInput!
        $connections: [ID!]!
      ) {
        makeViewerUnwatchArticle(input: $input) {
          ... on MakeViewerWatchArticleOutput_Result {
            result {
              article {
                id @deleteEdge(connections: $connections)
                isViewerWatched
                watchCount
              }
            }
          }
        }
      }
    `);

  const watchArticle = (
    config?: Pick<Parameters<typeof makeViewerWatchArticle>[0], 'onCompleted'>
  ) => {
    LocalStorage.addUserSegment(UserSegmentType.Buyer);

    makeViewerWatchArticle({
      variables: { input: { originalArticleId: article.originalId }, connections },
      onError: getRelayNetworkErrorHandler(),
      onCompleted: config?.onCompleted,
      optimisticResponse: {
        makeViewerWatchArticle: {
          __typename: 'MakeViewerWatchArticleOutput_Result',
          result: {
            article: {
              id: article.id,
              isViewerWatched: true,
              watchCount: article.watchCount + 1,
            },
          },
        },
      },
    });
  };

  const unWatchArticle = (
    config?: Pick<Parameters<typeof makeViewerUnwatchArticle>[0], 'onCompleted'>
  ) => {
    makeViewerUnwatchArticle({
      variables: { input: { originalArticleId: article.originalId }, connections },
      onError: getRelayNetworkErrorHandler(),
      optimisticResponse: {
        makeViewerUnwatchArticle: {
          __typename: 'MakeViewerWatchArticleOutput_Result',
          result: {
            article: {
              id: article.id,
              isViewerWatched: false,
              watchCount: Math.max(article.watchCount - 1, 0),
            },
          },
        },
      },
      onCompleted: config?.onCompleted,
    });
  };

  return {
    watchArticle,
    unWatchArticle,
    isWatchArticleInFlight,
    isUnWatchArticleInFlight,
  };
};

export default useWatchArticle;
