import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { IconRemoveCircleFill } from '@seed-design/react-icon';

type Props = {
  onClickDelete: () => void;
};

const TextFieldRemoveButton = ({ onClickDelete }: Props) => {
  return (
    <RemoveButton tabIndex={-1} type="button" onClick={onClickDelete}>
      <IconRemoveCircleFill width={22} height={22} />
    </RemoveButton>
  );
};

export default TextFieldRemoveButton;

const RemoveButton = styled.button`
  padding: 0;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    color: ${vars.$scale.color.gray600};
  }
`;
