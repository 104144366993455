import { receive, send } from '@stackflow/compat-await-push';
import { useActions } from '@stackflow/react';
import { useCallback } from 'react';
import { ActivitiesType } from 'stackflow';

type OverloadParams<T> = T extends {
  (...args: infer Params): void;
  (...args: infer Params2): void;
  (...args: infer Params3): void;
}
  ? Params | Params2 | Params3
  : never;

const useCustomFlow = () => {
  const originalFlow = useActions<ActivitiesType>();

  const asyncPush = useCallback(
    <T = undefined>(...args: Parameters<typeof originalFlow.push>) =>
      receive<T>(originalFlow.push(...args)),
    [originalFlow.push]
  );

  const pop = useCallback(
    (...args: OverloadParams<typeof originalFlow.pop>) => {
      // @ts-expect-error pop 인자 검증 후 그대로 bypass 하기 때문에 에러 ignore
      originalFlow.pop(...args);
      return {
        send: <T = any>(activityId: string, data: T) => {
          send({
            activityId,
            data,
          });
        },
      };
    },
    [originalFlow.pop]
  );

  return { ...originalFlow, pop, asyncPush };
};

export default useCustomFlow;
