export const errorMessage = {
  common: '알 수 없는 에러가 발생했어요. 다시 시도해주세요.',
  failGetArticlesInMap: '지도의 매물 정보를 가져오지 못했어요. 다시 시도해주세요.',
  failArticle: '매물 정보를 가져오지 못했어요. 다시 시도해주세요.',
  failCurrentPosition: '알 수 없는 이유로 현재 위치를 가져오지 못했어요.',
  failAddressList: '주소 목록을 가져오지 못했어요. 다시 시도해주세요.',
  pleaseAddTrade: '가격 정보를 입력해주세요.',
  WrongAccess: '잘못된 접근이에요. 다시 시도해주세요.',
  failGetChatChannelInfo: '채팅방 정보를 가져오지 못했어요.',
  noWritable: '현재 게시글을 작성할 수 없어요.',
  failCreateArticle: '알 수 없는 이유로 게시글 생성에 실패했어요. 빠진 부분이 없는지 확인해주세요.',
  failEditArticle: '알 수 없는 이유로 게시글 수정에 실패했어요. 빠진 부분이 없는지 확인해주세요.',
};
