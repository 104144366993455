import {
  AlertDialog,
  AlertDialogProps,
  DialogContainer,
  DialogContainerProps,
} from 'components/Base/AlertDialog';
import { omit } from 'lodash-es';
import { createContext, PropsWithChildren, useCallback, useContext, useState } from 'react';

export type DialogOptions = AlertDialogProps &
  Pick<DialogContainerProps, 'onOutsideClick' | 'closeOnOutsideClick'>;

type DialogContextType = {
  open: (option: DialogOptions) => void;
  close: () => void;
};

const DialogContext = createContext<DialogContextType>({
  open: () => undefined,
  close: () => undefined,
});

export const useDialog = () => useContext(DialogContext);

export const DialogContextProvider = ({ children }: PropsWithChildren) => {
  const [dialogOptions, setDialogOptions] = useState<DialogOptions | undefined>();

  const open = useCallback((option: DialogOptions) => {
    setDialogOptions(option);
  }, []);

  const close = useCallback(() => {
    setDialogOptions(undefined);
  }, []);

  return (
    <DialogContext.Provider value={{ open, close }}>
      {children}
      {dialogOptions && (
        <DialogContainer
          isOpen={true}
          onClose={close}
          onOutsideClick={dialogOptions.onOutsideClick}
          closeOnOutsideClick={dialogOptions.closeOnOutsideClick}
        >
          <AlertDialog {...omit(dialogOptions, ['onOutsideClick', 'closeOnOutsideClick'])} />
        </DialogContainer>
      )}
    </DialogContext.Provider>
  );
};
