import { HStack } from '@chakra-ui/layout';
import styled from '@emotion/styled';
import { IconViewCountFill } from '@seed-design/react-icon';
import { vars } from '@seed-design/design-token';
import Image from 'components/Base/Image';
import Thumbnail from 'components/Base/Thumbnail';
import { zIndices } from 'constants/zIndices';
import { graphql, useFragment } from 'react-relay';
import { getThumbnailUrl } from 'utils/Image';
import { ArticleThumbnail_article$key } from '__generated__/ArticleThumbnail_article.graphql';
import FontSizeLimiter from 'components/Base/FontSizeLimiter';
import IconPlayWithDimmed from 'components/icons/IconPlayWithDimmed';

type Props = {
  articleRef: ArticleThumbnail_article$key;
  width?: string | number;
  height?: string | number;
  lazy?: boolean;
  isWatched?: boolean;
  showVideoIcon?: boolean;
};

const ArticleThumbnail = ({
  articleRef,
  width = 118,
  height = 118,
  lazy = true,
  isWatched,
  showVideoIcon,
}: Props) => {
  const article = useFragment(
    graphql`
      fragment ArticleThumbnail_article on Article {
        id
        imageThumbnailUrl
        videoThumbnailUrl
        images {
          id
        }
        videos {
          id
        }
      }
    `,
    articleRef
  );
  const hasVideo = article.videos.length > 0;
  const hasImage = article.images.length > 0;

  const thumbnailUrl =
    !hasImage && article.videoThumbnailUrl ? article.videoThumbnailUrl : article.imageThumbnailUrl;

  return (
    <div>
      <Thumbnail width={width} height={height}>
        <Image lazy={lazy} src={getThumbnailUrl(thumbnailUrl)} style={{ objectFit: 'cover' }} />
        {isWatched && (
          <WatchedDimmed>
            <HStack spacing={2}>
              <IconViewCountFill width={12} height={12} />
              <FontSizeLimiter limit={10}>
                <span>본 매물</span>
              </FontSizeLimiter>
            </HStack>
          </WatchedDimmed>
        )}
        {showVideoIcon && hasVideo && (
          <PlayIconWrap>
            <IconPlayWithDimmed />
          </PlayIconWrap>
        )}
      </Thumbnail>
    </div>
  );
};

export default ArticleThumbnail;

const WatchedDimmed = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  padding: 6px 4px 40%;
  color: ${vars.$static.color.staticWhite};
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.25) 0%,
    rgba(0, 0, 0, 0.12) 48.96%,
    rgba(0, 0, 0, 0) 100%
  );
  z-index: ${zIndices.docked};
  ${vars.$semantic.typography.label6Bold};
`;

const PlayIconWrap = styled.div`
  display: flex;
  position: absolute;
  right: 0px;
  bottom: 0px;
  color: ${vars.$static.color.staticWhite};

  img {
    width: 100%;
    height: 100%;
  }
`;
