import { Activity } from '@stackflow/core';
import { RouteName } from 'routes';
import { actions } from 'stackflow';

export const getLastActivity = () => {
  return actions
    .getStack()
    .activities?.filter((v) => v.transitionState !== 'exit-done')
    .slice(-2)?.[0] as (Activity & { name: RouteName }) | undefined;
};

// prev 는 replace 도 포함
class PrevActivity {
  private prevActivity: Activity | null = null;

  get() {
    return this.prevActivity;
  }

  set(activity: Activity) {
    this.prevActivity = activity;
  }
}

export const prevActivity = new PrevActivity();
