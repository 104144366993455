import React from 'react';
import styled from '@emotion/styled';
import { Box, Flex, Stack } from '@chakra-ui/layout';
import Placeholder from 'components/Base/Skeleton/Placeholder';
import { css } from '@emotion/react';

export default function ResultSkeleton() {
  return (
    <Wrapper>
      <Box px={20}>
        <Flex flexDirection="column" gap={16} alignItems="center" marginTop={12}>
          <img
            src="https://s3-alpha-sig.figma.com/img/e63b/b90b/c85c18be13d51ce4997bf673af0f0dd4?Expires=1731888000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=PoRX~Qsp9OT9fqaS3U37RENE~Q53dra7SDFA5ZXBpB8toSPXsDp~0Qa83EuWKbBLCHkZ3QHUBb3K8VwFbQJkDpUKr5CROo3bl9TQrgYvbT5VAWB5w1cCtphh84i42WvPKsm2FGivi8Pt3V--NnmW1asneI5Szba-NU8vy1QFztT7spawI2q8WYveoF~h7hzQ07lr7~jkWVHZgbhxoF6Mh8ZBL3vH6rGuWmxnb-sFrHyEosQ0jUxR0lCJpgQEP~rHLFs6qdW0EgVF8-qiGyITyZYtY0NJIQTAn0SpqWbrFmHuytmbcfxG6TDMZBsnoL4b62BZVNjNSBMbGRwD-raa0g__"
            width={64}
            height={64}
          />

          <Flex flexDirection="column" alignItems="center" gap={5}>
            <Placeholder.Text
              css={css`
                opacity: 0.2;
              `}
            >
              이웃들의
            </Placeholder.Text>

            <Placeholder.Text
              css={css`
                opacity: 0.2;
              `}
            >
              2024 당근 부동산 여정을 <br />
            </Placeholder.Text>

            <Placeholder.Text
              css={css`
                opacity: 0.2;
              `}
            >
              함께 살펴봤어요!
            </Placeholder.Text>
          </Flex>
        </Flex>

        <Stack gap={12} mt={24}>
          {/* 매물 조회수 */}
          <Placeholder.Box
            css={css`
              width: 100%;
              height: 60px;
              flex-shrink: 0;
              border-radius: 12px;
              opacity: 0.2;
            `}
          />

          {/* 매물 유형 태그 */}
          <Placeholder.Box
            css={css`
              width: 100%;
              height: 100px;
              flex-shrink: 0;
              border-radius: 12px;
              opacity: 0.2;
            `}
          />
        </Stack>
      </Box>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: relative;
  width: 100%;
  height: 100%;

  overflow: hidden;
`;
