import { Bridge } from '@daangn/webview-bridge-modern';
import { PluginInfo } from '@daangn/webview-bridge-modern/lib/plugins/Info';
import { PluginRouter } from '@daangn/webview-bridge-modern/lib/plugins/Router';
import { PluginAnalytics } from '@daangn/webview-bridge-modern/lib/plugins/Analytics';
import { makeKarrotBridge, installKarrotBridgeDriver } from '@daangn/karrotbridge';
import { compatWeb } from 'bridge/compat';
import { PluginToast } from '@daangn/webview-bridge-modern/lib/plugins/Toast';
import { PluginStorage } from '@daangn/webview-bridge-modern/lib/plugins/Storage';
import { PluginImage } from '@daangn/webview-bridge-modern/lib/plugins/Image';

const { driver } = installKarrotBridgeDriver();
export const karrotBridge = compatWeb(makeKarrotBridge({ driver }));

const bridgeEnvironment = karrotBridge.driver.getCurrentEnvironment();
export const isWeb = bridgeEnvironment === 'Web';

export const legacyBridge = new Bridge({
  dangerouslyInjectDriver: driver,
});

// kotisari, bizsdk, bridge/info 에서 사용 중인 legacy bridge plugin
// https://daangn.slack.com/archives/C019D3X6CGZ/p1665470850571029?thread_ts=1665469314.968679&cid=C019D3X6CGZ
legacyBridge
  .addPlugin(PluginImage)
  .addPlugin(PluginRouter)
  .addPlugin(PluginInfo)
  .addPlugin(PluginAnalytics)
  .addPlugin(PluginToast)
  .addPlugin(PluginStorage);
