import qs from 'query-string';
import { resetImageConfig } from 'bridge/image';
import { changeRouteStyle } from 'bridge/router';
import { setUpGlobalAxiosErrorHandler } from 'utils/axios';
import { SessionStorage } from 'utils/Storage';

// 외부 서비스에서 맨 처음 진입시 쿼리
type InitialQuery = {
  referrer?: string;
  search_region_id?: string;
  search_filter?: string;
  is_broker?: 'true';
};

export const initialQueries: InitialQuery = qs.parse(window.location.search);

const { referrer } = initialQueries;

referrer && SessionStorage.setReferrer(String(referrer));

setUpGlobalAxiosErrorHandler();

resetImageConfig();

changeRouteStyle({ backSwipable: true });
