import axios, { AxiosResponse } from 'axios';
import CONFIG, { IS_LOCAL } from 'config';
import { filterNil } from 'utils/Misc';
import { getApp, getRegion } from 'bridge/info';
import { SessionStorage } from 'utils/Storage';
import { addDaangnInterceptor, addRetryInterceptor } from 'apis/axiosInterceptor';

const MAX_RETRY_COUNT = 3;
const RETRY_DELAY = 1000;

const instance = ({
  baseURL,
  headers,
  isExternal,
}: {
  baseURL: string;
  headers?: () => Promise<{
    [x: string]: any;
  }>;
  isExternal?: boolean;
}) => {
  const api = axios.create({ baseURL });

  if (!isExternal) {
    addDaangnInterceptor(api);
  }

  api.interceptors.request.use(async (config) => {
    if (headers) {
      await headers().then((headers) => {
        Object.assign(config.headers || {}, filterNil(headers || {}));
      });
    }
    return config;
  });

  api.interceptors.response.use((response: AxiosResponse<{ error: any }>) => {
    const { data } = response;

    if (data.error) {
      throw data.error;
    }

    return response;
  });

  addRetryInterceptor(api, {
    retryCount: MAX_RETRY_COUNT,
    retryDelay: RETRY_DELAY,
  });

  return api;
};

export default instance;

const headers = async () => {
  const region = await getRegion();
  const app = await getApp();

  return {
    'X-USER-REFERER': !IS_LOCAL && SessionStorage.getReferrer(),
    'DEVICE-IDENTITY': app?.deviceIdentity,
    'X-REGION-ID': region?.id,
  };
};

export const RealtyInstance = instance({
  baseURL: CONFIG.API_HOST,
  headers,
});
