import { HTMLAttributes, useMemo } from 'react';
import { DEVICE_FONT_SCALE } from 'utils/style';

type Props = {
  limit?: 'inherit' | number | string;
} & HTMLAttributes<HTMLSpanElement>;

const FontSizeLimiter = ({ limit = 'inherit', ...props }: Props) => {
  const maxFontSize = useMemo(() => {
    if (limit === 'inherit') {
      return '1em';
    }
    if (typeof limit === 'number') {
      return `${limit}px`;
    }
    return limit;
  }, [limit]);

  return (
    <span
      style={{
        ...props.style,
        fontSize: `min(1em, calc(${maxFontSize} / ${DEVICE_FONT_SCALE}))`,
        lineHeight: `min(1em, calc(${maxFontSize} / ${DEVICE_FONT_SCALE}))`,
      }}
      {...props}
    />
  );
};

export default FontSizeLimiter;
