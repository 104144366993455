import { useCallback, MutableRefObject, RefCallback, ForwardedRef } from 'react';

/**
 * 여러 개의 ref를 합칠 수 있는 hook이에요.
 *
 * @example
 * const Foo = forwardRef((props, ref1) => {
 *   const ref2 = useRef();
 *   const combinedRef = useCombinedRefs(ref1, ref2);
 *
 *   // div가 업데이트되면 ref1, ref2 둘 다 업데이트 됨
 *   return <div ref={combinedRef} />
 * });
 */
export default function useCombinedRefs<T>(
  ...refs: Array<RefCallback<T> | MutableRefObject<T> | ForwardedRef<T> | undefined>
): RefCallback<T> {
  const combinedRef = useCallback(
    (value: T) => {
      refs.forEach((ref) => {
        if (!ref) {
          return;
        }

        if (typeof ref === 'function') {
          ref(value);
        } else {
          ref.current = value;
        }
      });
    },
    [refs]
  );

  return combinedRef;
}
