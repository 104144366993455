import { AppBarProps, usePageContext } from 'components/Base/PageLayout';
import { useLayoutEffect } from 'react';

const ScreenHelmet = (props: AppBarProps & { preventSwipeBack?: boolean }) => {
  const { setScreenOptions } = usePageContext();

  useLayoutEffect(() => {
    const { preventSwipeBack, ...appBar } = props;

    setScreenOptions((prev) => ({
      ...prev,
      preventSwipeBack,
      appBar,
    }));
  }, [props]);

  return null;
};

export default ScreenHelmet;
