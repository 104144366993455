import { captureException } from '@sentry/react';
import { useAdvertisementInfo_advertisementInfo$key } from '__generated__/useAdvertisementInfo_advertisementInfo.graphql';
import { AdvertisementPlacementIdBySection } from 'constants/advertisement';
import { type ArticleItemSection } from 'constants/section';
import useAdvertisementInfo from 'hooks/fragment/useAdvertisementInfo';
import useAdvertisementLogger from 'hooks/useAdvertisementLogger';

export const useAdvertisementItem = (
  infoRef: useAdvertisementInfo_advertisementInfo$key | null,
  section: ArticleItemSection
) => {
  const advertisementInfo = useAdvertisementInfo(infoRef);
  const logger = useAdvertisementLogger(advertisementInfo);
  const placementId = AdvertisementPlacementIdBySection[section];

  if (!advertisementInfo) {
    return null;
  }

  if (!placementId) {
    captureException(new Error(`[광고] ${section}에 대한 placementId가 없습니다.`));
  }

  return {
    advertisementInfo,
    logger,
    placementId,
  };
};
