import { Box, HStack, Stack, StackDivider } from '@chakra-ui/layout';
import { css } from '@emotion/react';
import { vars } from '@seed-design/design-token';
import { SectionContainer } from 'components/Base/Container';
import Placeholder from 'components/Base/Skeleton/Placeholder';

const ArticleDetailPageSkeleton = () => {
  return (
    <div>
      <Placeholder.Box
        css={css`
          width: 100%;
          height: 100vw;
        `}
      />
      <SectionContainer>
        <Stack
          spacing={16}
          divider={<StackDivider borderColor={vars.$semantic.color.divider2} />}
          css={css`
            padding: 16px 0 20px 0;
            border-bottom: 1px solid ${vars.$semantic.color.divider2};
          `}
        >
          <HStack spacing={12}>
            <Placeholder.Box
              css={css`
                width: 48px;
                height: 48px;
                border-radius: 100%;
              `}
            />
            <Stack spacing={2}>
              <Placeholder.Text css={vars.$semantic.typography.subtitle1Bold}>
                당근마켓
              </Placeholder.Text>
              <Placeholder.Text css={vars.$semantic.typography.label3Regular}>
                서초동
              </Placeholder.Text>
            </Stack>
          </HStack>
          <Stack spacing={8}>
            <Stack spacing={4}>
              <Placeholder.Text css={vars.$semantic.typography.bodyM2Regular}>
                오픈형 원룸
              </Placeholder.Text>
              <Placeholder.Text css={vars.$semantic.typography.title1Bold}>
                월세 500/40
              </Placeholder.Text>
            </Stack>
            <Box py={12}>
              <Placeholder.Text
                css={css`
                  width: 100%;
                  border-radius: 10px;
                  padding: 14px;
                  border: 1px solid transparent;
                  ${vars.$semantic.typography.label3Bold}
                `}
              >
                직거래로 아끼는 비용
              </Placeholder.Text>
            </Box>
            <Box>
              <Placeholder.Text css={vars.$semantic.typography.subtitle2Regular}>
                8일 전 · 채팅 0 · 관심 0 · 조회 7
              </Placeholder.Text>
            </Box>
          </Stack>
        </Stack>
      </SectionContainer>
    </div>
  );
};

export default ArticleDetailPageSkeleton;
