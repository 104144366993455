export enum RelayErrorCode {
  /** '오류가 발생했습니다.' */
  /** '해당 내용을 찾을 수 없습니다.' */
  RecordNotFound = 'recordNotFound',
  /** '이미 존재하는 내용입니다.' */
  /** '권한이 없어요.' */
  /** '유저 정보를 찾을 수 없어요.' */
  /** '잘못된 요청이에요.' */
}

const CommonErrorMessage = {
  WrongAccess: '잘못된 접근이에요.',
  Unknown: '알 수 없는 오류가 발생했어요.',
  BizProfileNotFound: '비즈프로필 정보가 없어요.',
  TemporaryUnavailable: '일시적인 에러가 발생하였어요.\n잠시 후 다시 시도해주세요.',
};

export type CommonErrorType = keyof typeof CommonErrorMessage;
export class CommonError extends Error {
  code: keyof typeof CommonErrorMessage;
  constructor(code: keyof typeof CommonErrorMessage) {
    super(CommonErrorMessage[code]);
    this.name = `[CommonError] ${code}`;
    this.code = code;
  }
}
