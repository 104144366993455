import type { Stack, StackflowPlugin } from '@stackflow/core';
import CONFIG from 'config';
import type { RouteName } from 'routes';
import { prevActivity } from 'stackflow/utils';
import Logger from 'utils/Logger';
import clarity from 'utils/clarity';

// TODO: 페이지별 clarity 업그레이드 원하는 경우
const CLARITY_UPGRADE_ACTIVITY: RouteName[] = ['article_new', 'advertisement_create'];

const getActiveActivityName = (stack: Stack) => {
  return stack.activities.find((activity) => activity.isActive)?.name as RouteName | undefined;
};

const setDocumentTitle = (stack: Stack) => {
  const activityName = getActiveActivityName(stack);

  document.title = [CONFIG.SERVICE_NAME, activityName].filter(Boolean).join(' | ');
};

const setClarityUpgrade = (stack: Stack) => {
  const activityName = getActiveActivityName(stack);

  if (activityName && CLARITY_UPGRADE_ACTIVITY.includes(activityName)) {
    clarity.upgrade(`enter ${activityName}`);
  }
};

const setPrevActivity = (stack: Stack) => {
  const activity = stack.activities.find((activity) => activity.isActive);

  activity && prevActivity.set(activity);
};

const realtyPlugin = (): StackflowPlugin => () => ({
  key: 'realty',
  onInit: ({ actions }) => {
    setDocumentTitle(actions.getStack());
    setClarityUpgrade(actions.getStack());
    // TODO: session_start에 잘못된 document.title이 들어가서 여기서 초기화.
    // 올바른 시점 파악하기
    Logger.initGA();
  },
  onChanged: ({ actions }) => {
    setDocumentTitle(actions.getStack());
    setClarityUpgrade(actions.getStack());
  },
  onBeforePush: ({ actions }) => {
    setPrevActivity(actions.getStack());
  },
  onBeforeReplace: ({ actions }) => {
    setPrevActivity(actions.getStack());
  },
  onBeforePop: ({ actions }) => {
    setPrevActivity(actions.getStack());
  },
});

export default realtyPlugin;
