import { stackDepthChangePlugin } from '@stackflow/plugin-stack-depth-change';
import { changeRouteStyle } from 'bridge/router';

const depthChangePlugin = () =>
  stackDepthChangePlugin({
    onInit: ({ depth }) => {
      changeRouteStyle({ backSwipable: depth <= 1 });
    },
    onDepthChanged: ({ depth }) => {
      changeRouteStyle({ backSwipable: depth <= 1 });
    },
  });

export default depthChangePlugin;
