export default class CommonStorage<T extends { [key: string]: string }> {
  prefix: string;
  storage: Storage;
  private listeners: Map<T[keyof T], ((value: string | null) => void)[]> = new Map();

  constructor(prefix: string, storage: Storage) {
    this.prefix = prefix;
    this.storage = storage;
    this.listeners = new Map();
  }

  getKey(key: T[keyof T]) {
    return `${this.prefix}#${key}`;
  }

  addListener(key: T[keyof T], listener: (value: string | null) => void) {
    const listeners = this.listeners.get(key) || [];
    listeners.push(listener);
    this.listeners.set(key, listeners);

    return () => {
      this.removeListener(key, listener);
    };
  }

  removeListener(key: T[keyof T], listener: (value: string | null) => void) {
    const listeners = this.listeners.get(key) || [];
    this.listeners.set(
      key,
      listeners.filter((l) => l !== listener)
    );
  }

  private notify(key: T[keyof T], value: string | null) {
    const listeners = this.listeners.get(key) || [];
    listeners.forEach((listener) => listener(value));
  }

  setItem(key: T[keyof T], value: string): void {
    this.storage.setItem(this.getKey(key), value);
    this.notify(key, value);
  }

  getItem(key: T[keyof T]): string | null {
    return this.storage.getItem(this.getKey(key));
  }

  removeItem(key: T[keyof T]): void {
    this.storage.removeItem(this.getKey(key));
    this.notify(key, null);
  }
}
