import './shim';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import './index.css';
import './style';
import './setupApp';
import { StrictMode, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import '@stackflow/plugin-basic-ui/index.css';
import '@daangn/karrot-clothes/lib/index.css';
import '@daangn/karrot-clothes/lib/pullToRefresh.css';
import 'utils/Sentry';
import App from './App';
import GlobalError from 'components/Error/GlobalError';
import ToastContainer from 'components/Toast/ToastContainer';
import AppLoading from 'components/Base/AppLoading';
import KeyboardVisibleContextProvider from 'contexts/KeyboardVisibleContext';
import { DialogContextProvider } from 'contexts/DialogContext';

const root = createRoot(document.getElementById('root')!);

root.render(
  <StrictMode>
    <Sentry.ErrorBoundary fallback={<GlobalError />}>
      <ToastContainer baseOptions={{ duration: 3000 }} />
      <Suspense fallback={<AppLoading />}>
        <DialogContextProvider>
          <KeyboardVisibleContextProvider>
            <App />
          </KeyboardVisibleContextProvider>
        </DialogContextProvider>
      </Suspense>
    </Sentry.ErrorBoundary>
  </StrictMode>
);
