/**
 * @generated SignedSource<<bade478a73a57c2038fbbfa841f0d0d4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Step2_userYearReport$data = {
  readonly countPercentile: number;
  readonly readArticleCount: number;
  readonly " $fragmentType": "Step2_userYearReport";
};
export type Step2_userYearReport$key = {
  readonly " $data"?: Step2_userYearReport$data;
  readonly " $fragmentSpreads": FragmentRefs<"Step2_userYearReport">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Step2_userYearReport",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "readArticleCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "countPercentile",
      "storageKey": null
    }
  ],
  "type": "UserYearReport",
  "abstractKey": null
};

(node as any).hash = "129bb2c5fed3bb620cca6340fe527994";

export default node;
