/**
 * @generated SignedSource<<f9f973e4ecf2fdbca78f0c36f6502047>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type SalesTypeEnum = "APART" | "ETC" | "OFFICETEL" | "OPEN_ONE_ROOM" | "SPLIT_ONE_ROOM" | "STORE" | "TWO_ROOM";
import { FragmentRefs } from "relay-runtime";
export type ArticleSalesTypeText_article$data = {
  readonly salesTypeV2: {
    readonly etcType?: string;
    readonly type: SalesTypeEnum;
  };
  readonly " $fragmentType": "ArticleSalesTypeText_article";
};
export type ArticleSalesTypeText_article$key = {
  readonly " $data"?: ArticleSalesTypeText_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArticleSalesTypeText_article">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "ArticleSalesTypeText_article"
};

(node as any).hash = "f7df6fb6f21eddc42ac4647949a6bc7d";

export default node;
