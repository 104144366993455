import { useAtomValue } from 'jotai';
import { loadable } from 'jotai/utils';
import debugState from 'store/debug';

const useIsDebug = () => {
  const debugLoadable = useAtomValue(loadable(debugState));

  return debugLoadable.state === 'hasData' && debugLoadable.data;
};

export default useIsDebug;
