/**
 * @generated SignedSource<<abc363f1ea1a6bf7019152f15e903587>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ArticleTradeTypeAndPriceText_article$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ArticleMainTrade_article">;
  readonly " $fragmentType": "ArticleTradeTypeAndPriceText_article";
};
export type ArticleTradeTypeAndPriceText_article$key = {
  readonly " $data"?: ArticleTradeTypeAndPriceText_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArticleTradeTypeAndPriceText_article">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "ArticleTradeTypeAndPriceText_article"
};

(node as any).hash = "2a4860441b59bd976d101a70e3747b60";

export default node;
