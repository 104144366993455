import { Flex } from '@chakra-ui/layout';
import FilterSection from 'components/Filter/FilterSection';
import { FloorFilterEnum } from 'types/schemaEnums';
import { xor } from 'lodash-es';
import { getFloorTypeText } from '@daangn/realty-sdk';
import { Checkbox } from '@daangn/sprout-components-checkbox';
import { filterType } from 'constants/article';

export const FloorTypes: FloorFilterEnum[] = [
  'SEMI_BASEMENT',
  'FIRST',
  'SECOND_TO_FIFTH',
  'SIX_TO_NINTH',
  'TENTH_OR_MORE',
];

type Props = {
  value?: readonly FloorFilterEnum[] | null;
  onChange: (floor: readonly FloorFilterEnum[] | null) => void;
};

const FloorSetting = ({ value, onChange }: Props) => {
  const handleClickFloor = (floor: FloorFilterEnum) => {
    onChange(xor(value, [floor]));
  };

  return (
    <FilterSection title={filterType.floor}>
      <Flex flexDirection="column">
        {FloorTypes.map((floorType) => {
          const isChecked = value?.includes(floorType);

          return (
            <Checkbox
              key={floorType}
              onChange={() => handleClickFloor(floorType)}
              size="large"
              isSelected={!!isChecked}
            >
              {getFloorTypeText(floorType)}
            </Checkbox>
          );
        })}
      </Flex>
    </FilterSection>
  );
};

export default FloorSetting;
