import { IS_DEV } from 'config';
import { fetchQuery, graphql } from 'relay-runtime';
import MainEnvironment from 'relay/environment';
import { debugUserStateQuery } from '__generated__/debugUserStateQuery.graphql';
import { atom } from 'jotai';
import { atomWithLazy } from 'jotai/utils';

export const debugModeState = atom(false);

export const debugUserState = atomWithLazy(() =>
  fetchQuery<debugUserStateQuery>(
    MainEnvironment,
    graphql`
      query debugUserStateQuery {
        viewer {
          isDebugUser
        }
      }
    `,
    {}
  )
    .toPromise()
    .then((result) => !!result?.viewer?.isDebugUser)
    .catch(() => false)
);

export const debugDateState = atom<Date | undefined>(undefined);

const debugState = atom((get) => {
  if (IS_DEV) {
    return true;
  }

  return get(debugModeState) && get(debugUserState);
});

export default debugState;
