import { KarrotBridge } from '@daangn/karrotbridge';
import { copyToClipboard } from 'utils/Clipboard';
import { actionToast } from 'utils/actionToast';

const webCompatMap = {
  share: async ({ share: { url } }) => {
    try {
      await copyToClipboard(url);
      actionToast({ message: '링크가 복사되었습니다.' });
    } catch (e) {
      actionToast({ message: '링크 복사에 실패했습니다.' });
    }

    return Promise.resolve({});
  },
  openToast({ toast }) {
    actionToast({ message: toast.body });
    return Promise.resolve({});
  },
} as const satisfies Partial<KarrotBridge>;

const checkIsWebCompatible = (key: string | number | symbol): key is keyof typeof webCompatMap => {
  return key in webCompatMap;
};

export const compatWeb = <T extends KarrotBridge>(bridge: T) => {
  return new Proxy(bridge, {
    get(target, prop, receiver) {
      const key = prop as keyof typeof target;
      const isWeb = bridge.driver.getCurrentEnvironment() === 'Web';

      if (isWeb && checkIsWebCompatible(key)) {
        return (...args: [any]) => {
          webCompatMap[key](...args);

          return target[key](...args);
        };
      }

      return Reflect.get(target, prop, receiver);
    },
  });
};
