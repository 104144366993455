import { ArticleConfig } from 'constants/article';
import { graphql, readInlineData } from 'relay-runtime';
import { TradeTypeFilterEnum, type TradeTypeEnum } from 'types/schemaEnums';
import {
  formatDistanceKo,
  areaText,
  billionConvert,
  getTradeTypeAndPrice,
  getArticleMainTradeType,
  getArticleSalesTypeText,
  getFloorsText,
  getManageCostPrice,
  isOneOfSalesType,
  BuildingNameAvailableSalesTypes,
  SupplyAreaFirstSalesTypes,
} from '@daangn/realty-sdk';
import { truthy } from 'utils/Misc';
import { ArticleCaptionText_article$key } from '__generated__/ArticleCaptionText_article.graphql';
import { ArticleCostText_article$key } from '__generated__/ArticleCostText_article.graphql';
import { ArticlePublishedAtText_article$key } from '__generated__/ArticlePublishedAtText_article.graphql';
import { ArticleSalesTypeText_article$key } from '__generated__/ArticleSalesTypeText_article.graphql';
import { ArticleSummaryInfo_article$key } from '__generated__/ArticleSummaryInfo_article.graphql';
import { ArticleMainTrade_article$key } from '__generated__/ArticleMainTrade_article.graphql';
import { ArticleTradeTypeAndPriceText_article$key } from '__generated__/ArticleTradeTypeAndPriceText_article.graphql';
import { ArticleAreaInfoText_article$key } from '__generated__/ArticleAreaInfoText_article.graphql';
import { isFieldIncluded } from 'utils/Form';
import type { ArticleInvisibleState_article$key } from '__generated__/ArticleInvisibleState_article.graphql';
import { P, match } from 'ts-pattern';
import { checkIsWithInPriceRange, type PriceFilter } from 'utils/filter';
import type { ArticlePublic_article$key } from '__generated__/ArticlePublic_article.graphql';

export type TradeFilterOptions = {
  tradeTypes: readonly TradeTypeFilterEnum[] | TradeTypeFilterEnum[];
} & PriceFilter;

export const readArticleSummaryInfo = (
  articleRef: ArticleSummaryInfo_article$key,
  option?: {
    areaUnit?: '평' | 'm²';
    simple?: boolean;
    showCostText?: boolean;
  }
) => {
  const { areaUnit = '평', simple = false, showCostText = true } = option ?? {};

  const article = readInlineData(
    graphql`
      fragment ArticleSummaryInfo_article on Article @inline {
        ...ArticleSalesTypeText_article
        ...ArticleCostText_article
        salesTypeV2 {
          type
        }
        area
        supplyArea
        addressInfo
        floor
        topFloor
      }
    `,
    articleRef
  );

  const isSupplyAreaFirstType = SupplyAreaFirstSalesTypes.some(
    (v) => v === article.salesTypeV2.type
  );
  const salesTypeText = readArticleSalesTypeText(article);
  const allAreaText = [
    isSupplyAreaFirstType &&
      article.supplyArea &&
      areaText(article.supplyArea, areaUnit, { withUnit: false }),
    article.area && areaText(article.area, areaUnit, { withUnit: false }),
  ]
    .filter(truthy)
    .join('/');
  const allAreaTextWithUnit = allAreaText ? `${allAreaText}${areaUnit}` : '';

  if (simple) {
    return [salesTypeText, allAreaTextWithUnit, getFloorsText(article, { simple: true })]
      .filter(truthy)
      .join(' · ');
  }

  const costText = readArticleCostText(article);

  return [
    salesTypeText,
    allAreaText,
    article.addressInfo,
    getFloorsText(article, { simple: true }),
    showCostText && costText,
  ]
    .filter(truthy)
    .join(' · ');
};

const readArticleCostText = (articleRef: ArticleCostText_article$key) => {
  const article = readInlineData(
    graphql`
      fragment ArticleCostText_article on Article @inline {
        writerType
        salesTypeV2 {
          type
        }
        manageCost
        etcManageCost
        includeManageCostOptionV2 {
          option
          payOption
          fixedCost
        }
        isUnknownManageCost
        premiumMoney
      }
    `,
    articleRef
  );

  const showManageCost = isFieldIncluded(
    {
      salesType: article.salesTypeV2.type,
    },
    ArticleConfig.manageCost
  );
  const showPremiumMoney =
    article.premiumMoney &&
    isFieldIncluded(
      {
        salesType: article.salesTypeV2.type,
      },
      ArticleConfig.premiumMoney
    );

  if (showManageCost) {
    const priceText = article.isUnknownManageCost
      ? '확인 필요'
      : !article.manageCost
        ? '없음'
        : billionConvert(getManageCostPrice(article), { withUnit: true });

    return ['관리비', priceText].join(' ');
  }

  if (showPremiumMoney) {
    return `권리금 ${billionConvert(article.premiumMoney, { withUnit: true })}`;
  }

  return '';
};

export const readArticleSalesTypeText = (
  articleRef: ArticleSalesTypeText_article$key,
  option?: {
    subText?: boolean;
  }
) => {
  const { subText = true } = option ?? {};

  const article = readInlineData(
    graphql`
      fragment ArticleSalesTypeText_article on Article @inline {
        salesTypeV2 {
          type
          ... on EtcSalesType {
            etcType
          }
        }
      }
    `,
    articleRef
  );

  const showEtcTypeText = article.salesTypeV2.type === 'ETC' && article.salesTypeV2.etcType;
  const salesTypeText = getArticleSalesTypeText(article.salesTypeV2.type, {
    simple: true,
    subText,
    etcText: showEtcTypeText ? article.salesTypeV2.etcType : undefined,
  });

  return salesTypeText;
};

export const readArticlePublishedAtText = (articleRef: ArticlePublishedAtText_article$key) => {
  const article = readInlineData(
    graphql`
      fragment ArticlePublishedAtText_article on Article @inline {
        pullUpCount
        publishedAt
      }
    `,
    articleRef
  );

  const { pullUpCount, publishedAt } = article;

  return [pullUpCount > 0 && '끌올', formatDistanceKo(publishedAt)].filter(truthy).join(' ');
};

export const readArticleCaptionText = (articleRef: ArticleCaptionText_article$key) => {
  const article = readInlineData(
    graphql`
      fragment ArticleCaptionText_article on Article @inline {
        ...ArticleCostText_article
        salesTypeV2 {
          type
        }
        addressInfo
        region {
          originalId
          name
        }
        buildingName
      }
    `,
    articleRef
  );
  const articleCostText = readArticleCostText(article);
  const showBuildingName = isOneOfSalesType(
    article.salesTypeV2.type,
    BuildingNameAvailableSalesTypes
  );
  const buildingInfo = showBuildingName ? article.buildingName : '';
  const addressInfo = buildingInfo || article.addressInfo || article.region.name;

  return [articleCostText, addressInfo].filter(truthy).join(' · ');
};

export const readArticleAreaInfoText = (
  articleRef: ArticleAreaInfoText_article$key,
  options?: { areaUnit?: '평' | 'm²' }
) => {
  const article = readInlineData(
    graphql`
      fragment ArticleAreaInfoText_article on Article @inline {
        area
        floor
        topFloor
      }
    `,
    articleRef
  );
  const { areaUnit = '평' } = options ?? {};

  return [areaText(article.area, areaUnit), getFloorsText(article, { simple: true })]
    .filter(truthy)
    .join(' · ');
};

export const readArticleTradeTypeAndPriceText = (
  articleRef: ArticleTradeTypeAndPriceText_article$key,
  filterOptions?: TradeFilterOptions
) => {
  const article = readInlineData<ArticleTradeTypeAndPriceText_article$key>(
    graphql`
      fragment ArticleTradeTypeAndPriceText_article on Article @inline {
        ...ArticleMainTrade_article
      }
    `,
    articleRef
  );

  const { mainTrade } = readArticleMainTrade(article, filterOptions);

  return getTradeTypeAndPrice(
    mainTrade.type,
    mainTrade?.deposit || mainTrade?.price || 0,
    mainTrade?.monthlyPay || mainTrade?.yearlyPay || 0
  );
};

export const readArticleMainTrade = (
  articleRef: ArticleMainTrade_article$key,
  filterOptions?: TradeFilterOptions
) => {
  const article = readInlineData<ArticleMainTrade_article$key>(
    graphql`
      fragment ArticleMainTrade_article on Article @inline {
        trades {
          type
          preferred
          ... on ShortTrade {
            deposit
            description
            monthlyPay
          }
          ... on MonthTrade {
            deposit
            description
            monthlyPay
          }
          ... on YearTrade {
            deposit
            description
            yearlyPay
          }
          ... on BorrowTrade {
            deposit
            description
          }
          ... on BuyTrade {
            price
          }
        }
      }
    `,
    articleRef
  );

  const filteredTradeTypes = filterOptions?.tradeTypes.filter((filterType) => {
    const matchedTrade = article.trades.find((trade) => trade.type === filterType);

    return (
      !!matchedTrade &&
      match(matchedTrade)
        .with(
          {
            type: 'YEAR',
          },
          (trade) => {
            return (
              checkIsWithInPriceRange(trade.deposit!, filterOptions.depositPay) &&
              checkIsWithInPriceRange(trade.yearlyPay!, filterOptions.yearlyPay)
            );
          }
        )
        .with(
          {
            type: P.union('MONTH', 'SHORT'),
          },
          (trade) => {
            return (
              checkIsWithInPriceRange(trade.deposit!, filterOptions.depositPay) &&
              checkIsWithInPriceRange(trade.monthlyPay!, filterOptions.monthlyPay)
            );
          }
        )
        .with(
          {
            type: 'BORROW',
          },
          (trade) => {
            return checkIsWithInPriceRange(trade.deposit!, filterOptions.depositPay);
          }
        )
        .with({ type: 'BUY' }, (trade) => {
          return checkIsWithInPriceRange(trade.price!, filterOptions.pricePay);
        })
        .exhaustive()
    );
  });
  const articleTradeTypes = article.trades.map((trade: any) => trade.type);
  const preferredTradeType = article.trades?.find((trade: any) => trade.preferred === true)?.type;

  const mainTradeType = getArticleMainTradeType(
    articleTradeTypes,
    preferredTradeType,
    filteredTradeTypes
  );

  const mainTrade = article.trades.find((trade: any) => trade.type === mainTradeType)!;
  const otherTrades = article.trades.filter((trade: any) => trade.type !== mainTradeType);

  return { mainTrade, otherTrades };
};

export const isOneOfTradeType = <T extends TradeTypeEnum, K extends T>(
  currentTradeType: T,
  tradeTypes: K[]
): currentTradeType is K => {
  return !!currentTradeType && tradeTypes.includes(currentTradeType as K);
};

export const readArticleInvisibleState = (articleRef: ArticleInvisibleState_article$key) => {
  const article = readInlineData(
    graphql`
      fragment ArticleInvisibleState_article on Article @inline {
        isHide
        invisibleStatus
      }
    `,
    articleRef
  );

  const isVisible = !article.isHide && article.invisibleStatus === 'NONE';

  if (isVisible) {
    return null;
  }

  return {
    byUser: article.isHide,
    byAdmin: article.invisibleStatus !== 'NONE',
  };
};

export const readIsArticlePublic = (articleRef: ArticlePublic_article$key) => {
  const article = readInlineData(
    graphql`
      fragment ArticlePublic_article on Article @inline {
        isHide
        status
        invisibleStatus
      }
    `,
    articleRef
  );

  return !article.isHide && article.status !== 'TRADED' && article.invisibleStatus === 'NONE';
};
