import type { KarrotBridge } from '@daangn/karrotbridge';
import { karrotBridge } from 'bridge';

export const closeWebview = () => karrotBridge.closeRouter({});

export const changeRouteStyle = (
  option: Parameters<KarrotBridge['styleCurrentRouter']>[0]['router']
) => {
  karrotBridge.styleCurrentRouter({
    router: {
      navbar: false,
      scrollable: false,
      ...option,
    },
  });
};
