import { Text as ChakraText, TextProps } from '@chakra-ui/layout';
import { vars } from '@seed-design/design-token';
import { textEllipsis } from 'styles/mixin';

const allTextColor = {
  ...vars.$semantic.color,
  ...vars.$static.color,
  ...vars.$scale.color,
};

type TextColor = keyof typeof allTextColor;
type TextVariant = keyof typeof vars.$semantic.typography;

type Props = {
  variant: TextVariant;
  color?: TextColor;
  ellipsis?: number;
} & Omit<TextProps, 'variant' | 'color'>;

const Text = ({ variant, color, ellipsis, ...props }: Props) => {
  return (
    <ChakraText
      color={color && allTextColor[color]}
      css={ellipsis && textEllipsis(ellipsis)}
      {...vars.$semantic.typography[variant]}
      {...props}
    />
  );
};

export default Text;
