/**
 * @generated SignedSource<<de946f2758b38e78d802d2f2df9be12d>>
 * @relayHash d0195c02e4d41c79a7bda06cd3c01c6f
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID d0195c02e4d41c79a7bda06cd3c01c6f

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type MakeViewerUnwatchArticleInput = {
  originalArticleId: string;
};
export type useWatchArticle_MakeViewerUnwatchArticleMutation$variables = {
  connections: ReadonlyArray<string>;
  input: MakeViewerUnwatchArticleInput;
};
export type useWatchArticle_MakeViewerUnwatchArticleMutation$data = {
  readonly makeViewerUnwatchArticle: {
    readonly result?: {
      readonly article: {
        readonly id: string;
        readonly isViewerWatched: boolean;
        readonly watchCount: number;
      };
    };
  };
};
export type useWatchArticle_MakeViewerUnwatchArticleMutation = {
  response: useWatchArticle_MakeViewerUnwatchArticleMutation$data;
  variables: useWatchArticle_MakeViewerUnwatchArticleMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isViewerWatched",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "watchCount",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useWatchArticle_MakeViewerUnwatchArticleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "makeViewerUnwatchArticle",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MakeViewerWatchArticleOutput_ResultPayload",
                "kind": "LinkedField",
                "name": "result",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Article",
                    "kind": "LinkedField",
                    "name": "article",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "MakeViewerWatchArticleOutput_Result",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "useWatchArticle_MakeViewerUnwatchArticleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "makeViewerUnwatchArticle",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MakeViewerWatchArticleOutput_ResultPayload",
                "kind": "LinkedField",
                "name": "result",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Article",
                    "kind": "LinkedField",
                    "name": "article",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "filters": null,
                        "handle": "deleteEdge",
                        "key": "",
                        "kind": "ScalarHandle",
                        "name": "id",
                        "handleArgs": [
                          {
                            "kind": "Variable",
                            "name": "connections",
                            "variableName": "connections"
                          }
                        ]
                      },
                      (v4/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "MakeViewerWatchArticleOutput_Result",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "d0195c02e4d41c79a7bda06cd3c01c6f",
    "metadata": {},
    "name": "useWatchArticle_MakeViewerUnwatchArticleMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "5ee240385d65289bb9463c3b1bef7466";

export default node;
