import bigPicture from './bigPicture';
import bizAccount from './bizAccount';
import bizPost from './bizPost';
import realty from './realty';
import kakao from './kakao';

export default {
  bigPicture,
  bizAccount,
  bizPost,
  realty,
  kakao,
};
