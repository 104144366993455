import { HStack, Stack } from '@chakra-ui/layout';
import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { textEllipsis } from 'styles/mixin';

const ArticleCardStyle = {
  Container: styled.div`
    background-color: ${vars.$semantic.color.paperDefault};
  `,
  Body: styled(HStack)`
    align-items: stretch;
  `,
  Content: styled(Stack)`
    width: 100%;
  `,
  Label: styled.h3`
    ${vars.$semantic.typography.label4Bold}
  `,
  Title: styled.h3`
    ${vars.$semantic.typography.subtitle1Bold}
  `,
  SubTitle: styled.h4`
    ${vars.$semantic.typography.subtitle2Regular}
  `,
  Information: styled.p`
    ${textEllipsis(2)}
    ${vars.$semantic.typography.caption1Regular}
    color: ${vars.$scale.color.gray700}
  `,
  DateText: styled.div`
    color: ${vars.$scale.color.gray600};
    ${vars.$semantic.typography.caption2Regular}

    ${textEllipsis(1)}
  `,
  AdvertisementHelper: styled.div`
    display: inline-flex;
    flex-shrink: 0;
    color: ${vars.$scale.color.gray600};
    ${vars.$semantic.typography.caption2Regular}
  `,
};

export default ArticleCardStyle;
