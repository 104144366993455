/* eslint-disable @typescript-eslint/ban-types */
import { SalesCategory } from 'constants/category';
import { EventProviderType } from 'logger/types/common';
import { GuideTradeType } from 'pages/Guide/constants/type';
import type { BirthTimeType } from 'pages/MyRealtyFortune/components/FortuneForm';
import { RouteName } from 'routes';
import { TradeToolType } from 'types/tradeTool';
import { LandTitleSafetyType } from 'utils/landTitle';
import { ArticleStepKeyType } from 'utils/step';

export type LoggerPageViewType = keyof LoggerPageViewSchema;
export type LoggerPageViewParams<T extends LoggerPageViewType> = LoggerPageViewSchema[T];

/**
 * LoggerPageViewSchema - 페이지뷰 이벤트별 타입을 정의
 */
export type LoggerPageViewSchema = {
  [K in RouteName]: {};
} & {
  setting_notification: {
    notification_type: 'RECOMMEND' | 'WATCH_FOMO';
  };
  advertisement_create: {
    article_id: string;
  };
  article_channel_list: {
    region2_name: string;
  };
  article_compare: {
    articleCount: number;
    from: string;
  };
  article_detail: {
    article_id: string;
    region2_name: string;
    is_my_article: boolean;
    is_broker_article: boolean;
  };
  article_edit: {
    article_id: string;
    region2_name: string;
  };
  article_images: {
    article_id: string;
  };
  land_title: {
    article_id: string;
    safety_type: LandTitleSafetyType | null;
  };
  land_title_certificate: {
    article_id: string;
  };
  article_new: {
    region2_name: string;
  };
  article_pullup: {
    article_id: string;
    region2_name: string;
    pullup_able: boolean;
  };
  article_request_verify: {
    article_id: string;
  };
  article_road_view: {
    article_id: string;
  };
  article_step_edit: {
    step_id: ArticleStepKeyType;
  };
  article_sunlight: {
    article_id: string;
  };
  article_trade_survey: {
    article_id: string;
  };
  main: {
    region2_name: string;
  };
  main_article: {
    region2_name: string;
  };
  marketing_price_search_result: {
    is_empty: boolean;
  };
  my: {
    region2_name: string;
  };
  review: {
    send_user_id: string;
    receiver_user_id: string;
    article_id: string;
  };
  review_new: {
    article_id: string;
    biz_profile_id: string | undefined;
    is_biz_profile_review: boolean;
    receiver_user_id: string;
  };
  satisfaction_survey: {
    relatedArticleId: string | undefined;
    feedbackComment: string;
  };
  article_trade_tool: {
    article_id: string;
  };
  article_trade_tool_detail: {
    article_id: string;
    type: TradeToolType;
  };
  article_verify: {
    article_id: string;
  };
  biz_profile_articles: {
    article_id: string;
    biz_profile_id: string;
  };
  biz_profile_reviews: {
    biz_profile_id: string;
  };
  article_guide: {
    tradeType: GuideTradeType;
    salesType: SalesCategory;
  };
  subscribe_onboarding: {
    is_subscribed: boolean;
  };
  trade_review_new: {
    article_id: string;
    receiver_user_id: string;
  };
  trade_review_select_buyer: {
    article_id: string;
  };
  my_realty_fortune: {};
  my_realty_fortune_result: {
    gender: 'MALE' | 'FEMALE';
    year: '양력' | '음력';
    birthDate: string;
    birthTime?: BirthTimeType;
  };
  recap: {
    hasData: boolean;
  };
  recap_result: {
    hasData: boolean;
  };
};

/**
 * LoggerPageViewEventProperties - 페이지뷰 이벤트별 값을 정의
 */
export const LoggerPageViewEventProperties: {
  [K in keyof LoggerPageViewSchema]: {
    description: string;
    provider: EventProviderType[];
  };
} = {
  main: {
    description: 'main 페이지뷰',
    provider: ['firebase', 'ga4'],
  },
  main_article: {
    description: 'main_article 페이지뷰',
    provider: ['firebase', 'ga4'],
  },
  article_new: {
    description: 'article_new 페이지뷰',
    provider: ['firebase', 'ga4'],
  },
  ai_conversion: {
    description: 'ai_conversion 페이지뷰',
    provider: ['firebase', 'ga4', 'mixpanel'],
  },
  article_new_simple: {
    description: 'article_new_simple 페이지뷰',
    provider: ['firebase', 'ga4'],
  },
  article_step_edit: {
    description: 'article_step_edit 페이지뷰',
    provider: ['firebase', 'ga4'],
  },
  article_draft: {
    description: 'article_draft 페이지뷰',
    provider: ['firebase', 'ga4'],
  },
  article_detail: {
    description: 'article_detail 페이지뷰',
    provider: ['firebase', 'ga4'],
  },
  article_compare: {
    description: 'article_compare 페이지뷰',
    provider: ['firebase', 'ga4'],
  },
  article_road_view: {
    description: 'article_road_view 페이지뷰',
    provider: ['firebase', 'ga4'],
  },
  article_images: {
    description: 'article_images 페이지뷰',
    provider: ['firebase', 'ga4'],
  },
  article_edit: {
    description: 'article_edit 페이지뷰',
    provider: ['firebase', 'ga4'],
  },
  article_edit_image: {
    description: 'article_edit_image 페이지뷰',
    provider: ['firebase', 'ga4'],
  },
  article_pullup: {
    description: 'article_pullup 페이지뷰',
    provider: ['firebase', 'ga4'],
  },
  article_channel_list: {
    description: 'article_channel_list 페이지뷰',
    provider: ['firebase', 'ga4'],
  },
  article_trade_survey: {
    description: 'article_trade_survey 페이지뷰',
    provider: ['firebase', 'ga4'],
  },
  search_address: {
    description: 'search_address 페이지뷰',
    provider: ['firebase', 'ga4'],
  },
  article_checklist: {
    description: 'article_checklist 페이지뷰',
    provider: ['firebase', 'ga4'],
  },
  article_checklist_memo: {
    description: 'article_checklist_memo 페이지뷰',
    provider: ['firebase', 'ga4'],
  },
  article_trade_tool: {
    description: 'article_trade_tool 페이지뷰',
    provider: ['firebase', 'ga4'],
  },
  article_trade_tool_detail: {
    description: 'article_trade_tool_detail 페이지뷰',
    provider: ['firebase', 'ga4'],
  },
  article_request_verify: {
    description: 'article_request_verify 페이지뷰',
    provider: ['firebase', 'ga4'],
  },
  article_verify: {
    description: 'article_verify 페이지뷰',
    provider: ['firebase', 'ga4'],
  },
  land_title: {
    description: 'land_title 페이지뷰',
    provider: ['firebase', 'ga4'],
  },
  land_title_certificate: {
    description: 'land_title_certificate 페이지뷰',
    provider: ['firebase', 'ga4'],
  },
  article_sunlight: {
    description: 'article_sunlight 페이지뷰',
    provider: ['firebase', 'ga4'],
  },
  search_region: {
    description: 'search_region 페이지뷰',
    provider: ['firebase', 'ga4'],
  },

  subscribe: {
    description: 'subscribe 페이지뷰',
    provider: ['firebase', 'ga4'],
  },
  subscribe_setting: {
    description: 'subscribe_setting 페이지뷰',
    provider: ['firebase', 'ga4'],
  },
  subscribe_region: {
    description: 'subscribe_region 페이지뷰',
    provider: ['firebase', 'ga4'],
  },
  subscribe_onboarding: {
    description: 'subscribe_onboarding 페이지뷰',
    provider: ['firebase', 'ga4'],
  },
  satisfaction_survey: {
    description: 'satisfaction_survey 페이지뷰',
    provider: ['firebase', 'ga4'],
  },
  setting_notification: {
    description: 'setting_notification 페이지뷰',
    provider: ['firebase', 'ga4'],
  },
  my: {
    description: 'my 페이지뷰',
    provider: ['firebase', 'ga4'],
  },
  review: {
    description: 'review 페이지뷰',
    provider: ['firebase', 'ga4'],
  },
  review_new: {
    description: 'review_new 페이지뷰',
    provider: ['firebase', 'ga4'],
  },
  trade_review_new: {
    description: 'trade_review_new 페이지뷰',
    provider: ['firebase', 'ga4'],
  },
  trade_review_select_buyer: {
    description: 'trade_review_select_buyer 페이지뷰',
    provider: ['firebase', 'ga4'],
  },
  send_reviews: {
    description: 'send_reviews 페이지뷰',
    provider: ['firebase', 'ga4'],
  },
  seller_reviews: {
    description: 'seller_reviews 페이지뷰',
    provider: ['firebase', 'ga4'],
  },
  article_guide: {
    description: 'article_guide 페이지뷰',
    provider: ['firebase', 'ga4'],
  },
  image_guide: {
    description: 'image_guide 페이지뷰',
    provider: ['firebase', 'ga4'],
  },
  filter: {
    description: 'filter 페이지뷰',
    provider: ['firebase', 'ga4'],
  },
  my_sales_history: {
    description: 'my_sales_history 페이지뷰',
    provider: ['firebase', 'ga4'],
  },
  verify_articles: {
    description: 'verify_articles 페이지뷰',
    provider: ['firebase', 'ga4'],
  },
  faq: {
    description: 'faq 페이지뷰',
    provider: ['firebase', 'ga4'],
  },
  advertisement_create: {
    description: 'advertisement_create 페이지뷰',
    provider: ['firebase', 'ga4'],
  },
  recent_watched_articles: {
    description: 'recent_watched_articles 페이지뷰',
    provider: ['firebase', 'ga4'],
  },
  broker_pre_open_application: {
    description: 'broker_pre_open_application 페이지뷰',
    provider: ['firebase', 'ga4'],
  },
  broker_onboarding: {
    description: 'broker_onboarding 페이지뷰',
    provider: ['firebase', 'ga4', 'mixpanel'],
  },
  biz_profile_approval_setting: {
    description: 'biz_profile_approval_setting 페이지뷰',
    provider: ['firebase', 'ga4'],
  },
  biz_profile_reviews: {
    description: 'biz_profile_reviews 페이지뷰',
    provider: ['firebase', 'ga4'],
  },
  biz_profile_articles: {
    description: 'biz_profile_articles 페이지뷰',
    provider: ['firebase', 'ga4'],
  },
  biz_profile_create: {
    description: 'biz_profile_create 페이지뷰',
    provider: ['firebase', 'ga4'],
  },
  biz_profile_info: {
    description: 'biz_profile_info 페이지뷰',
    provider: ['firebase', 'ga4'],
  },
  marketing_price_search: {
    description: 'marketing_price_search 페이지뷰',
    provider: ['firebase', 'ga4'],
  },
  marketing_price_search_result: {
    description: 'marketing_price_search_result 페이지뷰',
    provider: ['firebase', 'ga4'],
  },
  sms_identification: {
    description: 'sms_identification 페이지뷰',
    provider: ['firebase', 'ga4'],
  },
  alpha_login: {
    description: 'alpha_login 페이지뷰',
    provider: ['firebase', 'ga4'],
  },
  complete: {
    description: 'complete 페이지뷰',
    provider: ['firebase', 'ga4'],
  },
  search_university: {
    description: 'search_university 페이지뷰',
    provider: ['firebase', 'ga4'],
  },
  my_realty_fortune: {
    description: 'my_realty_fortune 페이지뷰',
    provider: ['firebase', 'ga4'],
  },
  my_realty_fortune_result: {
    description: 'my_realty_fortune_result 페이지뷰',
    provider: ['firebase', 'ga4'],
  },
  recap: {
    description: '피처톤 - 연말결산 페이지뷰',
    provider: ['firebase', 'ga4'],
  },
  recap_result: {
    description: '피처톤 - 연말결산 결과 페이지뷰',
    provider: ['firebase', 'ga4'],
  },
};
