import { useAtomValue } from 'jotai';
import { useState } from 'react';
import { debugDateState } from 'store/debug';

export const useToday = () => {
  const [today] = useState(() => new Date());
  const debugDate = useAtomValue(debugDateState);

  return debugDate ?? today;
};
