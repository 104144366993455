import { Box, Flex } from '@chakra-ui/layout';
import { css } from '@emotion/react';
import { vars } from '@seed-design/design-token';
import React from 'react';

interface StepProgressIndicatorProps {
  steps: number;
  currentStep: number;
}

const StepIndicator = ({ steps, currentStep }: StepProgressIndicatorProps) => {
  return (
    <Flex align="center" gap={2}>
      {Array.from({ length: steps }).map((_, index) => (
        <Box key={index} css={stepStyle(index <= currentStep, steps)} />
      ))}
    </Flex>
  );
};

export default StepIndicator;

const stepStyle = (isActive: boolean, length: number) => css`
  width: calc((100% - ${2 * (length - 1)}px) / ${length});
  height: 2px;
  border-radius: 2px;

  background-color: ${vars.$static.color.staticWhite};
  opacity: ${isActive ? '1' : '0.4'};

  transition: background-color 0.3s ease;
`;
