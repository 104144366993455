import { Center, Stack } from '@chakra-ui/layout';
import { SeedTextButtonProps, TextButton } from '@daangn/sprout-components-button';
import Divider from 'components/Base/Divider';
import BottomSheet, { BottomSheetProps } from 'components/BottomSheet/BottomSheet';
import { LoggerComponentProps } from 'logger/types/common';
import Logger from 'utils/Logger';

type ActionSheetLoggerProps = LoggerComponentProps<'click_action_sheet'>;

export type Action = {
  label: string;
} & ActionSheetLoggerProps &
  Omit<SeedTextButtonProps, 'children'>;

type Props = {
  actions: Action[];
} & Omit<BottomSheetProps, 'event'> &
  ActionSheetLoggerProps;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ActionSheet = ({ actions, event, ...props }: Props) => {
  return (
    <BottomSheet showCloseButton={false} {...props}>
      <Stack spacing={6} divider={<Divider type="nav" />}>
        {actions.map(({ label, onClick, event, ...buttonProps }) => (
          <Center key={label} py={12} px={16}>
            <TextButton
              UNSAFE_style={{ width: '100%' }}
              size="medium"
              variant="secondary"
              {...buttonProps}
              onClick={(e) => {
                event && Logger.track('click_action_sheet', event.params);

                onClick?.(e);
                props.onClose();
              }}
            >
              {label}
            </TextButton>
          </Center>
        ))}
      </Stack>
    </BottomSheet>
  );
};

export default ActionSheet;
