import { Flex, Stack } from '@chakra-ui/layout';
import Text from 'components/Base/Text';
import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { useAppPreloadedQuery } from 'contexts/AppPreloadedContext';
import { motion } from 'framer-motion';
import { graphql, useFragment } from 'react-relay';
import { Step5_userYearReport$key } from '__generated__/Step5_userYearReport.graphql';

export default function Step5({
  userYearReportRef,
}: {
  userYearReportRef: Step5_userYearReport$key;
}) {
  const { viewer } = useAppPreloadedQuery();

  const userYearReport = useFragment(
    graphql`
      fragment Step5_userYearReport on UserYearReport {
        tags
      }
    `,
    userYearReportRef
  );

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        type: 'spring',
        staggerChildren: 0.2,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 },
  };

  return (
    <Wrapper initial="hidden" animate="show" variants={container}>
      <Stack gap={48} px={20}>
        <Text variant="title1Bold">
          {viewer?.nickname}님은 <br />
          다음 유형의 매물을 <br />
          가장 많이 조회했어요
        </Text>

        <Flex gap={16} flexWrap="wrap" rowGap={16} columnGap={16}>
          {userYearReport.tags.map((tag, idx) => (
            <Tag key={idx} variants={item}>
              #{tag}
            </Tag>
          ))}
        </Flex>
      </Stack>
    </Wrapper>
  );
}

const Wrapper = styled(motion.div)`
  display: relative;
  width: 100%;
  height: 100%;

  overflow: hidden;
`;

const Tag = styled(motion.span)`
  padding: 12px 24px;
  background: ${vars.$static.color.staticWhite};
  color: ${vars.$static.color.staticBlack};
  border-radius: 70px;
  width: fit-content;
  ${vars.$semantic.typography.title1Bold}
`;
