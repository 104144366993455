import { captureException } from '@sentry/react';

class Clarity {
  private clarity = (type: string, ...args: any[]) => {
    try {
      window.clarity?.(type, ...args);
    } catch (err: any) {
      if (err) {
        err.message = `[clarity ${type} error]: ${err.message}`;
        captureException(err, {
          level: 'warning',
          extra: {
            args,
          },
        });
      }
    }
  };

  init = (userId: string, tags?: Record<string, any>) => {
    this.clarity('identify', userId);

    Object.entries(tags || {}).forEach(([key, value]) => {
      this.clarity('set', key, value);
    });
  };

  upgrade = (reason: string) => {
    this.clarity('set', 'upgrade', reason);
    this.clarity('upgrade', reason);
  };
}

export default new Clarity();
