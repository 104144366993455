import ArticleDetailPageSkeleton from 'components/Base/Skeleton/ArticleDetailPageSkeleton';
import MainPageSkeleton from 'components/Base/Skeleton/MainPageSkeleton';
import { matchPath } from 'react-router';
import routes, { RouteName } from 'routes';

const PageSkeletonMap: Partial<Record<RouteName, () => JSX.Element>> = {
  main: MainPageSkeleton,
  article_detail: ArticleDetailPageSkeleton,
};

export const getCurrentPageSkeleton = () => {
  const routename = routes.find((v) =>
    matchPath(window.location.pathname || '', {
      path: v.path,
      exact: true,
    })
  )?.name;

  if (!routename) {
    return null;
  }

  return PageSkeletonMap[routename] ?? null;
};
