export enum UserSegmentType {
  Buyer = 'Buyer',
  Seller = 'Seller',
  Broker = 'Broker',
  // ex) A 실험 집단
  // A_Experiment = 'A_Experiment',
}

export type UserSegment = {
  [UserSegmentType.Buyer]: boolean;
  [UserSegmentType.Seller]: boolean;
  [UserSegmentType.Broker]: boolean;
  // ex) A 실험 집단
  // [UserSegmentType.A_Experiment]: boolean;
};
