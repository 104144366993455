import { Box } from '@chakra-ui/layout';
import type { PropsWithChildren } from 'react';

export const SECTION_NO_DIVIDER_CLASS_NAME = 'section_stack_no_divider';

const SectionNoDivider = ({ children }: PropsWithChildren) => {
  return <Box className={SECTION_NO_DIVIDER_CLASS_NAME}>{children}</Box>;
};

export default SectionNoDivider;
