import { Duration, Easing } from 'constants/animation';
import { motion, HTMLMotionProps, Variant } from 'framer-motion';
import { forwardRef } from 'react';

type Animate = 'show' | 'hide';

type Props = {
  show?: Variant;
  hide?: Variant;
  animate?: Animate;
  durationType?: keyof typeof Duration;
} & Omit<HTMLMotionProps<'div'>, 'variants' | 'animate'>;

const VisibilityMotion = forwardRef<HTMLDivElement, Props>(
  ({ show, hide, animate, durationType = 'medium', ...props }: Props, ref) => {
    const duration = Duration[durationType];

    return (
      <motion.div
        ref={ref}
        variants={{
          show: {
            opacity: 1,
            ...show,
            transition: {
              ease: Easing.emphasized.deceleration,
              duration: duration.Expanding,
            },
          },
          hide: {
            opacity: 0,
            ...hide,
            transition: {
              ease: Easing.emphasized.acceleration,
              duration: duration.Collapsing,
            },
          },
        }}
        initial="hide"
        animate={animate}
        exit="hide"
        {...props}
      />
    );
  }
);

export default VisibilityMotion;
