const IconPlayWithDimmed = () => {
  return (
    <svg width="31" height="32" viewBox="0 0 31 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_132_9562)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.9334 13.8836C21.1778 14.602 21.1778 16.3982 19.9334 17.1167L12.9334 21.1582C11.6889 21.8766 10.1334 20.9785 10.1334 19.5416L10.1334 11.4587C10.1334 10.0217 11.6889 9.12362 12.9334 9.8421L19.9334 13.8836Z"
          fill="white"
          shapeRendering="crispEdges"
        />
        <path
          d="M20.1834 17.5497C21.7611 16.6388 21.7611 14.3615 20.1834 13.4505L13.1834 9.40909C11.6056 8.49816 9.63337 9.63683 9.63337 11.4587L9.63337 19.5416C9.63337 21.3634 11.6056 22.5021 13.1834 21.5912L20.1834 17.5497Z"
          stroke="black"
          strokeOpacity="0.1"
          shapeRendering="crispEdges"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_132_9562"
          x="0.244534"
          y="0.587768"
          width="30.5111"
          height="31.6025"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.888889" />
          <feGaussianBlur stdDeviation="4.44444" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_132_9562" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_132_9562"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default IconPlayWithDimmed;
