/**
 * @generated SignedSource<<ef16c2dd286ea88f86106ca24bab9732>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useWatchArticle_Article$data = {
  readonly id: string;
  readonly isViewerWatched: boolean;
  readonly originalId: string;
  readonly watchCount: number;
  readonly " $fragmentType": "useWatchArticle_Article";
};
export type useWatchArticle_Article$key = {
  readonly " $data"?: useWatchArticle_Article$data;
  readonly " $fragmentSpreads": FragmentRefs<"useWatchArticle_Article">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useWatchArticle_Article",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "originalId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isViewerWatched",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "watchCount",
      "storageKey": null
    }
  ],
  "type": "Article",
  "abstractKey": null
};

(node as any).hash = "00b935ee4c2accb803661932dd8cb6d3";

export default node;
