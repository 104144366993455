import React, { useLayoutEffect, useRef } from 'react';
import { actionToastStore } from 'utils/actionToast';

const AvoidToast = ({ children }: { children: React.ReactElement }) => {
  const ref = useRef<HTMLElement>();

  useLayoutEffect(() => {
    const $element = ref.current;
    if (!$element) return;

    const handleResize = () => {
      actionToastStore.upsertOffsetElement($element);
    };

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          actionToastStore.upsertOffsetElement(entry.target as HTMLElement);
        } else {
          actionToastStore.deleteOffsetElement(entry.target as HTMLElement);
        }
      });
    };

    const resizeObserver = new ResizeObserver(handleResize);
    const intersectionObserver = new IntersectionObserver(handleIntersection);

    resizeObserver.observe($element);
    intersectionObserver.observe($element);

    actionToastStore.upsertOffsetElement($element);

    return () => {
      resizeObserver.unobserve($element);
      intersectionObserver.unobserve($element);

      actionToastStore.deleteOffsetElement($element);
    };
  }, []);

  return (
    <>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, {
          ref: (node: HTMLElement) => {
            ref.current = node;

            const originalRef = (child as any).ref;

            if (typeof originalRef === 'function') originalRef(node);
            else if (originalRef) originalRef.current = node;
          },
        })
      )}
    </>
  );
};

export default AvoidToast;
