import { Box } from '@chakra-ui/layout';
import { css, PropsOf } from '@emotion/react';
import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import AvoidToast from 'components/Toast/AvoidToast';
import { zIndices } from 'constants/zIndices';
import { forwardRef, PropsWithChildren } from 'react';

export const BOTTOM_FIXED_CLASS_NAME = 'bottom-fixed';

type Props = {
  position?: 'fixed' | 'sticky' | 'relative' | 'absolute';
  border?: boolean;
  rounded?: boolean;
  shadow?: boolean;
} & PropsOf<typeof Contents>;

const borderStyle = css`
  border-width: 1px;
  border-style: solid;
  border-color: ${vars.$semantic.color.divider1};
`;

const roundedStyle = css`
  border-radius: 24px 24px 0px 0px;
`;

const borderWithRoundedStyle = css`
  border-width: 1px 1px 0px 1px;
`;

const shadowStyle = css`
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
`;

const BottomFixed = forwardRef<HTMLDivElement, Props>(
  (
    { position = 'fixed', border, shadow, rounded, children, ...props }: PropsWithChildren<Props>,
    ref
  ) => {
    return (
      <AvoidToast>
        <Container
          className={BOTTOM_FIXED_CLASS_NAME}
          ref={ref}
          css={[
            css`
              position: ${position};
            `,
            border && borderStyle,
            rounded && roundedStyle,
            border && rounded && borderWithRoundedStyle,
            shadow && shadowStyle,
          ]}
        >
          <Contents {...props}>{children}</Contents>
        </Container>
      </AvoidToast>
    );
  }
);

const Container = styled.div`
  bottom: 0;
  left: 0;
  right: 0;
  z-index: ${zIndices.sticky};

  background: ${vars.$semantic.color.paperDefault};
`;

const Contents = styled(Box)`
  width: 100%;
  padding: 12px 16px calc(8px + env(safe-area-inset-bottom));
`;

export default BottomFixed;
