import {
  TextField as SeedTextField,
  SeedTextFieldProps,
} from '@daangn/sprout-components-text-field';
import { karrotBridge } from 'bridge';
import { forwardRef, useEffect, useId } from 'react';
import { replaceEmoji } from 'utils/validate';

export type TextFieldProps = Omit<SeedTextFieldProps, 'variant'> & {
  variant?: SeedTextFieldProps['variant'];
  removeEmoji?: boolean;
};

const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  ({ variant = 'outlined', removeEmoji = false, onChange, ...props }, ref) => {
    const id = useId();
    const inputId = props.ids?.input ?? id;

    const handleChange = (value: string) => {
      if (removeEmoji) {
        const filteredValue = replaceEmoji(value);
        onChange?.(filteredValue);
      } else {
        onChange?.(value);
      }
    };

    useEffect(() => {
      if (props.autoFocus) {
        karrotBridge.focusInput({
          inputId,
        });
      }
    }, [props.autoFocus, inputId]);

    return (
      <SeedTextField
        ids={{ ...props.ids, input: inputId }}
        ref={ref}
        minWidth={0}
        variant={variant}
        onChange={handleChange}
        {...props}
      />
    );
  }
);

export default TextField;
