/**
 * @generated SignedSource<<68c92ccd9333cf6b194ca6b18641c795>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useAdvertisementInfo_advertisementInfo$data = {
  readonly clickTrackingUrl: string;
  readonly hideUrl: string;
  readonly impressionTrackingUrl: string;
  readonly reportUrl: string;
  readonly targetUri: string;
  readonly " $fragmentType": "useAdvertisementInfo_advertisementInfo";
};
export type useAdvertisementInfo_advertisementInfo$key = {
  readonly " $data"?: useAdvertisementInfo_advertisementInfo$data;
  readonly " $fragmentSpreads": FragmentRefs<"useAdvertisementInfo_advertisementInfo">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useAdvertisementInfo_advertisementInfo",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "clickTrackingUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hideUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "impressionTrackingUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reportUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "targetUri",
      "storageKey": null
    }
  ],
  "type": "AdvertisementInfo",
  "abstractKey": null
};

(node as any).hash = "4a34e0c11bd68c70022b5304a959b81a";

export default node;
