import { atom } from 'jotai';
import { atomFamily, atomWithStorage } from 'jotai/utils';
import { LocalStorage, LocalStorageKey } from 'utils/Storage';

export const watchedArticlesState = atomWithStorage(
  LocalStorage.getKey(LocalStorageKey.WatchedArticles),
  {},
  {
    getItem: (_) => {
      return LocalStorage.getWatchedArticles();
    },
    setItem: (_, newValue) => {
      LocalStorage.setWatchedArticles(newValue);
    },
    removeItem: () => {
      LocalStorage.removeItem(LocalStorageKey.WatchedArticles);
    },
  },
  { getOnInit: true }
);

export const isWatchedArticleState = atomFamily((articleId: string) =>
  atom((get) => get(watchedArticlesState)[articleId] || false)
);
