import { useLayoutEffect, useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { motion, useAnimationControls } from 'framer-motion';
import { useIO } from '@daangn/realty-react/hooks';

const ZeroToTen = [...Array(10)].map((_, i) => i);
const Numbers = [...ZeroToTen, ...ZeroToTen];

type Props = {
  targetNumber: number | string;
};

const RollingNumber = ({ targetNumber }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [numberHeight, setNumberHeight] = useState(0);
  const numberOnlyIndexRef = useRef(0);
  const numberArr = useMemo(
    () => [...String(targetNumber)].map((n) => (Number.isNaN(Number(n)) ? n : Number(n))),
    [targetNumber]
  );
  const controls = useAnimationControls();

  const hiddenNumberRef = useRef<HTMLDivElement>(null);

  const handleAnimation = () => {
    controls.start(({ number, index }) => ({
      y: -numberHeight * number - numberHeight * (Numbers.length - 10),
      transition: {
        type: 'spring',
        stiffness: 100 + 50 * (index + 1),
        from: 0,
        damping: 20,
      },
    }));
  };

  useIO(containerRef, (e) => e.isIntersecting && handleAnimation(), { once: true });
  useLayoutEffect(() => {
    const height = hiddenNumberRef.current?.getBoundingClientRect()?.height;

    if (height) {
      setNumberHeight(height);
    }
  }, [targetNumber]);

  numberOnlyIndexRef.current = 0;

  return (
    <>
      <Container ref={containerRef}>
        {numberArr.map((n, index) => {
          if (typeof n === 'string') {
            return <div key={index}>{n}</div>;
          }

          return (
            <div
              key={index}
              style={{
                height: numberHeight,
                overflow: 'hidden',
              }}
            >
              <motion.div
                custom={{ number: n, index: numberOnlyIndexRef.current++ }}
                animate={controls}
              >
                {Numbers.map((number, i) => (
                  <div key={i}>{number}</div>
                ))}
              </motion.div>
            </div>
          );
        })}
      </Container>
      <Hidden ref={hiddenNumberRef}>{targetNumber}</Hidden>
    </>
  );
};

export default RollingNumber;

const Container = styled(motion.div)`
  display: flex;
  text-align: center;
`;

const Hidden = styled.div`
  position: absolute;
  top: -999px;

  opacity: 0;
`;
