import { useActivity } from '@stackflow/react';
import useSessionState from 'hooks/useSessionState';
import { useEffect } from 'react';
import Logger from 'utils/Logger';
import shortcut from 'apis/shortcut';
import { RouteName } from 'routes';
import {
  LoggerPageViewEventProperties,
  LoggerPageViewParams,
  LoggerPageViewType,
} from 'logger/schema/pvSchema';

const SHORTCUT_TARGET_PAGES = ['main', 'article_detail'] satisfies RouteName[];

// bridge - 액티비티 첫 진입시 로그 한번
// 그 외 - 액티비티 활성화시마다 로그
const usePageLog = <T extends LoggerPageViewType>(name: T, params: LoggerPageViewParams<T>) => {
  const { user } = useSessionState();
  const { isActive } = useActivity();

  const eventProps = LoggerPageViewEventProperties[name];
  const paramPayload = {
    name: name,
    description: eventProps.description,
    ...params,
  };

  useEffect(() => {
    let providers = {};
    for (const provider of eventProps.provider) {
      if (provider == 'ga4') continue;
      providers = {
        ...providers,
        [provider]: true,
      };
    }

    Logger.pageView(paramPayload, providers);

    if (user && SHORTCUT_TARGET_PAGES.some((v) => v === name)) {
      shortcut.visited();
    }
  }, []);

  useEffect(() => {
    if (isActive) {
      Logger.pageView(paramPayload, {
        firebase: false,
        amplitude: false,
        mixpanel: false,
        ga4: true,
      });
    }
  }, [isActive]);
};

export default usePageLog;
