import React from 'react';
import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { zIndices } from 'constants/zIndices';
import VisibilityMotion from 'components/Base/VisibilityMotion';
import { PropsOf } from 'utils/Type';

type Props = PropsOf<typeof VisibilityMotion>;

const Dim: React.FC<Props> = ({ ...props }) => {
  return <DimContainer {...props} initial="hide" animate="show" exit="hide" />;
};

export default Dim;

const DimContainer = styled(VisibilityMotion)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;

  background: ${vars.$static.color.staticBlackAlpha500};
  z-index: ${zIndices.overlay};
`;
