import React, { Suspense } from 'react';
import { ActivityComponentType } from '@stackflow/react';
import ScreenHelmet from 'components/Base/ScreenHelmet';
import BoxButton from 'components/Base/BoxButton';
import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { Box, Flex } from '@chakra-ui/layout';

import useCustomFlow from 'hooks/useCustomFlow';
import { karrotBridge } from 'bridge';
import { TextButton } from '@daangn/sprout-components-button';
import Result from 'pages/RecapResult/components/Result';
import usePageLog from 'hooks/usePageLog';
import { getLastActivity } from 'stackflow/utils';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { RecapResultUserYearReportQuery } from '__generated__/RecapResultUserYearReportQuery.graphql';
import ResultSkeleton from './components/ResultSkeleton';
import { IconCloseRegular } from '@seed-design/react-icon';

const PageRecapResult: ActivityComponentType = () => {
  const { replace, pop } = useCustomFlow();

  const { userYearReport } = useLazyLoadQuery<RecapResultUserYearReportQuery>(
    graphql`
      query RecapResultUserYearReportQuery {
        userYearReport {
          readArticleCount
          ...Result_userYearReport
        }
      }
    `,
    {}
  );

  const handleGoRealty = () => {
    const lastActivity = getLastActivity();
    if (lastActivity?.isRoot) replace('main', {});
    else pop();
  };

  const handleShare = () => {
    karrotBridge.share({
      share: {
        url: 'https://daangn.onelink.me/HhUa/lca9447h',
        text: `2024년 당근 부동산 연말 결산 리포트가 도착했어요 💌`,
      },
    });
  };

  usePageLog('recap_result', { hasData: userYearReport.readArticleCount >= 5 });

  return (
    <Wrapper>
      <ScreenHelmet height="0" />

      {/* Top */}
      <Box px={16} py={10}>
        <IconCloseRegular
          width={24}
          height={24}
          color={vars.$static.color.staticWhite}
          onClick={() => pop()}
        />
      </Box>

      <Suspense fallback={<ResultSkeleton />}>
        <Result userYearReportRef={userYearReport} />
      </Suspense>

      {/* Button */}
      <Flex px={16} pt={12} pb={8} gap={16} direction="column" alignItems="center">
        <BoxButton onClick={handleGoRealty} width="100%" variant="primary" size="xlarge">
          당근 부동산 구경하기
        </BoxButton>
        <TextButton
          onClick={handleShare}
          UNSAFE_style={{
            ...vars.$semantic.typography.label3Bold,
            color: vars.$static.color.staticWhite,
          }}
        >
          공유하기
        </TextButton>
      </Flex>
    </Wrapper>
  );
};

export default PageRecapResult;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);

  background-color: ${vars.$static.color.staticBlack};
  color: ${vars.$static.color.staticWhite};
`;
