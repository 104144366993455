import { Box } from '@chakra-ui/layout';
import { css } from '@emotion/react';
import GlobalLoading from 'components/Base/GlobalLoading';
import { useMemo } from 'react';
import { getCurrentPageSkeleton } from 'utils/skeleton';

const AppLoading = () => {
  const MatchingSkeleton = useMemo(() => getCurrentPageSkeleton(), []);

  if (MatchingSkeleton) {
    return (
      <div>
        <Box
          css={css`
            margin-top: max(
              var(--stackflow-plugin-basic-ui-app-bar-min-safe-area-inset-top),
              env(safe-area-inset-top)
            );
            min-height: 2.75rem;
          `}
        />
        <MatchingSkeleton />
      </div>
    );
  }

  return <GlobalLoading />;
};

export default AppLoading;
