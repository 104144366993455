import { closeWebview } from 'bridge/router';
import ErrorBase from 'components/Error/ErrorBase';

const GlobalError = () => {
  return (
    <ErrorBase
      buttonOption={{
        label: '돌아가기',
        onClick: closeWebview,
      }}
    />
  );
};

export default GlobalError;
