/**
 * @generated SignedSource<<4b5d68acb5739ad93bcb00c024ac4c08>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Result_userYearReport$data = {
  readonly countPercentile: number;
  readonly readArticleCount: number;
  readonly recommendArticles: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"ArticleCarouselItem_article">;
  }>;
  readonly tags: ReadonlyArray<string>;
  readonly " $fragmentType": "Result_userYearReport";
};
export type Result_userYearReport$key = {
  readonly " $data"?: Result_userYearReport$data;
  readonly " $fragmentSpreads": FragmentRefs<"Result_userYearReport">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Result_userYearReport",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "readArticleCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "countPercentile",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tags",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Article",
      "kind": "LinkedField",
      "name": "recommendArticles",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ArticleCarouselItem_article"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserYearReport",
  "abstractKey": null
};

(node as any).hash = "800cf6de849a30937c84ac4fa619c2d6";

export default node;
