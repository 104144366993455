import { HStack, Stack } from '@chakra-ui/layout';
import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { ArticleCarouselItem_article$key } from '__generated__/ArticleCarouselItem_article.graphql';
import ArticleThumbnail from 'components/Article/ArticleThumbnail';
import { zIndices } from 'constants/zIndices';
import { graphql, useFragment } from 'react-relay';
import {
  readArticleAreaInfoText,
  readArticleSalesTypeText,
  readArticleTradeTypeAndPriceText,
  type TradeFilterOptions,
} from 'utils/Article';
import WatchButton from './WatchButton';
import { textEllipsis } from 'styles/mixin';
import { isWatchedArticleState } from 'store/watchedArticle';
import { ArticleCarouselSection } from 'constants/section';
import ArticleAdvertisementHelpButton from 'components/Article/ArticleAdvertisementHelpButton';
import { useState } from 'react';
import { actionToast } from 'utils/actionToast';
import { useAdvertisementInfo_advertisementInfo$key } from '__generated__/useAdvertisementInfo_advertisementInfo.graphql';
import { useAdvertisementItem } from 'hooks/useAdvertisementItem';
import { useAtomValue } from 'jotai';
import ArticleStatusBadge from 'components/Article/ArticleStatusBadge';
import ArticleItemWrapper, {
  ArticleItemWrapperLoggerProps,
} from 'components/Article/ArticleItemWrapper';

export type ArticleCarouselItemProps = {
  articleRef: ArticleCarouselItem_article$key;
  advertisementInfoRef?: useAdvertisementInfo_advertisementInfo$key | null;
  section: ArticleCarouselSection;
  index: number;
  tradeFilterOptions?: TradeFilterOptions;
  hasWatchButton?: boolean;
  imageLazyLoad?: boolean;
  onClick?: () => void;
  showIsWatched?: boolean;
  showAdvertisementHelpButton?: boolean;
} & ArticleItemWrapperLoggerProps;

const ArticleCarouselItem = ({
  articleRef,
  advertisementInfoRef,
  tradeFilterOptions,
  section,
  index,
  hasWatchButton,
  imageLazyLoad,
  onClick,
  event,
  showIsWatched = true,
  showAdvertisementHelpButton = true,
}: ArticleCarouselItemProps) => {
  const article = useFragment(
    graphql`
      fragment ArticleCarouselItem_article on Article {
        id
        originalId
        ...ArticleItemWrapper_article
        ...ArticleSalesTypeText_article
        ...ArticleTradeTypeAndPriceText_article
        ...ArticleThumbnail_article
        ...ArticleAreaInfoText_article
        ...ArticleStatusBadge_article
        ...WatchButton_article
        status
        area
        writerType
        region {
          name
          name1
          name2
          name3
        }
      }
    `,
    articleRef
  );
  const advertisementItem = useAdvertisementItem(advertisementInfoRef ?? null, section);
  const [hideAdvertise, setHideAdvertise] = useState(false);
  const salesTypeText = readArticleSalesTypeText(article);
  const areaInfoText = readArticleAreaInfoText(article);
  const tradeTypeAndPriceText = readArticleTradeTypeAndPriceText(article, tradeFilterOptions);
  const watchedDate = useAtomValue(isWatchedArticleState(article.originalId));
  const isWatched = !!watchedDate;

  const handelHideAdvertise = () => {
    advertisementItem?.logger.hide();
    actionToast({
      message: '광고를 숨겼어요.',
    });
    setHideAdvertise(true);
  };

  if (hideAdvertise) {
    return null;
  }

  return (
    <ArticleItemWrapper
      articleRef={article}
      advertisementInfoRef={advertisementInfoRef}
      tradeFilterOptions={tradeFilterOptions}
      section={section}
      index={index}
      onClick={onClick}
      event={event}
    >
      <ArticleCarouselItemStyle.Container spacing={8}>
        <ArticleCarouselItemStyle.Thumbnail>
          <ArticleThumbnail
            articleRef={article}
            width={128}
            height={96}
            isWatched={showIsWatched && isWatched}
            lazy={imageLazyLoad}
          />
          {hasWatchButton && (
            <WatchButtonWrapper>
              <WatchButton
                articleRef={article}
                enableClickAnimation={false}
                onClick={(e) => e.stopPropagation()}
                size={20}
                color={vars.$static.color.staticWhite}
                position={`${section}_article_carousel`}
              />
            </WatchButtonWrapper>
          )}
        </ArticleCarouselItemStyle.Thumbnail>
        <ArticleCarouselItemStyle.TextContainer spacing={4}>
          {salesTypeText && (
            <HStack spacing={4}>
              <ArticleStatusBadge articleRef={article} />
              <ArticleCarouselItemStyle.SalesTypeText>
                {salesTypeText}
              </ArticleCarouselItemStyle.SalesTypeText>
            </HStack>
          )}
          {tradeTypeAndPriceText && (
            <ArticleCarouselItemStyle.TradeTypeAndPriceText>
              {tradeTypeAndPriceText}
            </ArticleCarouselItemStyle.TradeTypeAndPriceText>
          )}
          {areaInfoText && (
            <ArticleCarouselItemStyle.AreaInfoText>
              {areaInfoText}
            </ArticleCarouselItemStyle.AreaInfoText>
          )}
          <ArticleCarouselItemStyle.RegionText>
            <HStack spacing={3.5}>
              <span>{article.region.name3}</span>
              {showAdvertisementHelpButton && !!advertisementItem && (
                <>
                  <span>⸱</span>
                  <ArticleAdvertisementHelpButton
                    articleId={article.originalId}
                    onHideAdvertise={handelHideAdvertise}
                  />
                </>
              )}
            </HStack>
          </ArticleCarouselItemStyle.RegionText>
        </ArticleCarouselItemStyle.TextContainer>
      </ArticleCarouselItemStyle.Container>
    </ArticleItemWrapper>
  );
};

export default ArticleCarouselItem;

export const ArticleCarouselItemStyle = {
  Container: styled(Stack)``,
  Thumbnail: styled.div`
    position: relative;
    width: 128px;
    height: 96px;
    border-radius: 6px;
    overflow: hidden;
  `,
  TextContainer: styled(Stack)`
    span {
      ${textEllipsis(1)}
      white-space: pre-wrap;
    }
  `,
  SalesTypeText: styled.span`
    ${vars.$semantic.typography.caption1Regular}
  `,
  TradeTypeAndPriceText: styled.span`
    ${vars.$semantic.typography.label3Bold}
  `,
  AreaInfoText: styled.span`
    ${vars.$semantic.typography.subtitle2Regular}
  `,
  RegionText: styled.span`
    ${vars.$semantic.typography.caption1Regular}
    color: ${vars.$scale.color.gray600};
  `,
};

const WatchButtonWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: ${zIndices.docked};
`;
