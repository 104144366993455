// src/index.ts
import { addBreadcrumb } from "@sentry/browser";
var CATEGORY = "relay";
var isExecuteEvent = (logEvent) => logEvent.name.startsWith("execute");
var isQueryResourceEvent = (logEvent) => logEvent.name.startsWith("queryresource");
var isNetworkEvent = (logEvent) => logEvent.name.startsWith("network");
var errorsIsGraphQLError = (errors) => Array.isArray(errors) && errors.some((item) => "message" in item);
var logFunction = ({ filterEvents } = {}) => (logEvent) => {
  var _a, _b;
  if (typeof filterEvents === "function" && !filterEvents(logEvent)) return;
  const category = `${CATEGORY}.${logEvent.name}`;
  if (isExecuteEvent(logEvent)) {
    const { executeId } = logEvent;
    switch (logEvent.name) {
      case "execute.start":
        addBreadcrumb({
          type: "info",
          level: "info",
          category,
          data: {
            executeId,
            ...logEvent.params,
            variables: logEvent.variables
          }
        });
        break;
      case "execute.next":
        addBreadcrumb({
          type: "debug",
          level: "debug",
          category,
          data: {
            executeId,
            response: logEvent.response,
            duration: logEvent.duration
          }
        });
        break;
      case "execute.complete":
        addBreadcrumb({
          type: "info",
          level: "info",
          category,
          data: {
            executeId
          }
        });
        break;
      case "execute.error":
        addBreadcrumb({
          type: "error",
          level: "error",
          category,
          data: {
            executeId,
            error: logEvent.error
          }
        });
        break;
      default: {
        addBreadcrumb({
          type: "debug",
          level: "debug",
          category,
          data: {
            executeId
          }
        });
        break;
      }
    }
  } else if (isQueryResourceEvent(logEvent)) {
    const { resourceID } = logEvent;
    switch (logEvent.name) {
      case "queryresource.fetch": {
        const {
          node: { params },
          variables
        } = logEvent.operation.request;
        addBreadcrumb({
          type: "info",
          level: "info",
          category,
          data: {
            resourceID,
            id: (_a = params.id) != null ? _a : params.cacheID,
            kind: params.operationKind,
            name: params.name,
            variables,
            shouldFetch: logEvent.shouldFetch ? "yes" : "no",
            fetchPolicy: logEvent.fetchPolicy,
            renderPolicy: logEvent.renderPolicy,
            queryAvailability: logEvent.queryAvailability
          }
        });
        break;
      }
      default: {
        addBreadcrumb({
          type: "debug",
          level: "debug",
          category,
          data: {
            resourceID
          }
        });
        break;
      }
    }
  } else if (isNetworkEvent(logEvent)) {
    const { networkRequestId } = logEvent;
    switch (logEvent.name) {
      case "network.start": {
        const params = logEvent.params;
        addBreadcrumb({
          type: "info",
          level: "info",
          category,
          data: {
            networkRequestId,
            id: (_b = params.id) != null ? _b : params.cacheID,
            kind: params.operationKind,
            name: params.name,
            variables: logEvent.variables
          }
        });
        break;
      }
      case "network.error": {
        const data = {
          networkRequestId,
          name: logEvent.name
        };
        const error = logEvent.error;
        if ("graphqlErrors" in error && errorsIsGraphQLError(error == null ? void 0 : error.graphqlErrors)) {
          data.errors = error.graphqlErrors;
        }
        addBreadcrumb({
          type: "error",
          level: "error",
          category,
          data
        });
        break;
      }
      case "network.info": {
        const { info } = logEvent;
        addBreadcrumb({
          type: "info",
          level: "info",
          category,
          data: {
            networkRequestId,
            info
          }
        });
        break;
      }
      case "network.complete": {
        addBreadcrumb({
          type: "info",
          level: "info",
          category,
          data: {
            networkRequestId
          }
        });
        break;
      }
      default: {
        addBreadcrumb({
          type: "debug",
          level: "debug",
          category,
          data: {
            networkRequestId
          }
        });
        break;
      }
    }
  } else {
    addBreadcrumb({
      type: "debug",
      level: "debug",
      category,
      data: {}
    });
  }
};
export {
  logFunction
};
