import { Stack } from '@chakra-ui/layout';
import { css } from '@emotion/react';
import { vars } from '@seed-design/design-token';
import BoxButton from 'components/Base/BoxButton';
import BottomSheet from 'components/BottomSheet/BottomSheet';
import withDev from 'hoc/withDev';
import useCustomFlow from 'hooks/useCustomFlow';
import { useState } from 'react';
import Path from 'constants/path';
import routes, { RouteName } from 'routes';
import TextField from 'components/Base/TextField';
import { SalesCategory } from 'constants/category';
import { GuideTradeType } from 'pages/Guide/constants/type';
import SearchField from 'components/Base/SearchField';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const DoorBottomSheet = ({ isOpen, onClose }: Props) => {
  const [doorInput, setDoorInput] = useState<{
    activityName: RouteName;
    segments: Record<string, string>;
  } | null>(null);
  const [doorSearch, setDoorSearch] = useState('');
  const { push } = useCustomFlow();

  const handleClickLink = (path: string) => {
    const activityName = routes.find((v) => v.path === path)?.name;
    const segments = path.match(/:([\d\w-_.~]+)/g);
    onClose();

    if (!activityName) {
      return;
    }

    if (!segments) {
      push(activityName, {});
      return;
    }

    const defaultParams = {
      articleId: '16631',
      salesType: SalesCategory.House,
      tradeType: GuideTradeType.Borrow,
    };

    setDoorInput({
      activityName,
      segments:
        segments?.reduce((acc, cur) => {
          const [, key] = cur.split(':');

          return { ...acc, [key]: defaultParams[key as keyof typeof defaultParams] ?? '' };
        }, {}) ?? {},
    });
  };

  return (
    <>
      <BottomSheet
        css={css`
          height: 600px;
        `}
        isOpen={isOpen}
        onClose={onClose}
        title={
          <SearchField
            value={doorSearch}
            onChange={setDoorSearch}
            placeholder="이름 검색"
            onClickDelete={() => setDoorSearch('')}
          />
        }
        showDivider
        showCloseButton={false}
      >
        <Stack spacing={8} pb="40px">
          {Object.entries(Path)
            .filter(([name]) => new RegExp(doorSearch, 'i').test(name))
            .map(([name, path]) => (
              <div
                key={name}
                css={vars.$semantic.typography.title3Bold}
                onClick={() => handleClickLink(path)}
              >
                {name}
              </div>
            ))}
        </Stack>
      </BottomSheet>
      {doorInput && (
        <BottomSheet
          isOpen={!!doorInput}
          onClose={() => setDoorInput(null)}
          footer={
            <BoxButton
              width="100%"
              onClick={() => {
                setDoorInput(null);
                push(doorInput.activityName, doorInput.segments);
              }}
            >
              이동하기
            </BoxButton>
          }
        >
          {Object.entries(doorInput.segments).map(([key, value]) => (
            <div key={key}>
              <TextField
                key={key}
                value={value}
                label={key}
                onChange={(v) =>
                  setDoorInput((prev) =>
                    prev
                      ? {
                          ...prev,
                          segments: {
                            ...prev.segments,
                            [key]: v,
                          },
                        }
                      : null
                  )
                }
              ></TextField>
            </div>
          ))}
        </BottomSheet>
      )}
    </>
  );
};

export default withDev(DoorBottomSheet);
