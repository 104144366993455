import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { IconHeartFill, IconHeartRegular } from '@seed-design/react-icon';
import { heartOverrideStyle } from 'constants/animation';
import useSessionState from 'hooks/useSessionState';
import useWatchArticle from 'hooks/mutation/useWatchArticle';
import { graphql, useFragment } from 'react-relay';
import NudgeEventCollector from 'utils/EventCollector';
import Logger from 'utils/Logger';
import { ActionHistoryKey, LocalStorage } from 'utils/Storage';

type Props = {
  articleRef: any;
  size?: number;
  color?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onWatchArticle?: () => void;
  enableClickAnimation?: boolean;
  position: string;
};

function WatchButton({
  articleRef,
  size = 24,
  color = vars.$scale.color.gray600,
  onClick,
  onWatchArticle,
  position,
  enableClickAnimation = true,
}: Props) {
  const { checkIsLogin } = useSessionState();

  const article = useFragment(
    graphql`
      fragment WatchButton_article on Article {
        id
        originalId
        isViewerWatched
        ...useWatchArticle_Article
      }
    `,
    articleRef
  );

  const { watchArticle, unWatchArticle, isUnWatchArticleInFlight, isWatchArticleInFlight } =
    useWatchArticle(article);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    onClick?.(e);

    if (!checkIsLogin()) return;

    if (article.isViewerWatched) {
      unWatchArticle({
        onCompleted: () => {
          Logger.click({
            name: `article_card_watch_button_unlike`,
            article_id: article.originalId,
            position,
            description: `${position}에서 매물의 관심 해제을 클릭한 이벤트예요.`,
          });
        },
      });
    } else {
      watchArticle({
        onCompleted: () => {
          onWatchArticle?.();

          Logger.click({
            name: `article_card_watch_button_like`,
            article_id: article.originalId,
            position,
            description: `${position}에서 매물의 관심을 클릭한 이벤트예요.`,
          });
          Logger.appsflyerLog('click_realty_article_card_watch_button_like');

          if (!LocalStorage.getActionHistory(ActionHistoryKey.WatchArticle)) {
            LocalStorage.setActionHistory(ActionHistoryKey.WatchArticle);
            NudgeEventCollector.push({
              key: 'ClickLike',
              value: true,
            });
          }
        },
      });
    }
  };

  return (
    <Container disabled={isWatchArticleInFlight || isUnWatchArticleInFlight} onClick={handleClick}>
      {article.isViewerWatched ? (
        <IconHeartFill
          width={size}
          height={size}
          css={enableClickAnimation && heartOverrideStyle}
          color={vars.$scale.color.carrot500}
        />
      ) : (
        <IconHeartRegular width={size} height={size} color={color} />
      )}
    </Container>
  );
}

export default WatchButton;

const Container = styled.button`
  display: inline-block;
  padding: 6px;
  margin: -6px;
`;
