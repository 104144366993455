import { css, keyframes } from '@emotion/react';

const heartBounce = keyframes`
0% {
  transform: scale(0.05)
}
10% {
  transform: scale(0.1)
}
20% {
  transform: scale(0.2)
}
30% {
  transform: scale(0.3)
}
40% {
  transform: scale(0.4)
}
50% {
  transform: scale(1.1)
}
60% {
  transform: scale(1.2)
}
70% {
  transform: scale(1.3)
}
80% {
  transform: scale(1.2)
}
90% {
  transform: scale(1.1)
}
100% {
  transform: scale(1)
}
`;

export const heartOverrideStyle = css`
  animation-name: ${heartBounce};
  animation-duration: 300ms;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
`;

// https://m3.material.io/styles/motion/easing-and-duration/tokens-specs#601d5552-a6e6-4d74-9886-ff8f24b9ec35
export const Easing = {
  // 간단한 유틸리티 transition
  standard: {
    base: [0.2, 0, 0, 1],
    deceleration: [0, 0, 0, 1],
    acceleration: [0.3, 0, 1, 1],
  },
  // 대부분의 transition의 사용
  emphasized: {
    // begin/end in screen
    base: [0.2, 0, 0, 1],
    deceleration: [0.05, 0.7, 0.1, 1],
    acceleration: [0.3, 0, 0.8, 0.15],
  },
};

export const Duration = {
  small: {
    Expanding: 0.1,
    Collapsing: 0.1,
  },
  medium: {
    Expanding: 0.25,
    Collapsing: 0.2,
  },
  large: {
    Expanding: 0.3,
    Collapsing: 0.25,
  },
};
