import { captureException, withScope } from '@sentry/react';
import axios, { AxiosError } from 'axios';

export const logApiError = (error: AxiosError) => {
  withScope((scope) => {
    if (error.name) {
      let apiURL = error.config?.baseURL || '';

      try {
        const urlObj = new URL(error.config?.url || '');
        urlObj.search = '';
        urlObj.hash = '';
        apiURL = urlObj.toString();
      } catch (e) {}

      error.name = `${error.name} - ${apiURL}`;
    }

    const graphqlConfig = error.config?.realtyCustomConfig?.graphqlRequestParams;

    if (error.message) {
      error.message =
        `${error.message} - ` +
        [
          error.config?.method,
          error.response?.status,
          error.config?.url,
          graphqlConfig?.operationKind,
          graphqlConfig?.name,
        ]
          .filter(Boolean)
          .map((msg) => `[${msg}]`)
          .join('');
    }

    if (graphqlConfig) {
      scope.setTag('kind', graphqlConfig.operationKind);
      scope.setTag('operationName', graphqlConfig.name);
    }

    scope.setTag('retry', (error.config?.['axios-retry']?.retryCount ?? 0) > 0);

    captureException(error, {
      extra: error as any,
    });
  });
};

const errorLogInterceptor = (error: AxiosError) => {
  logApiError(error);
  return Promise.reject(error);
};

export const setUpGlobalAxiosErrorHandler = () => {
  axios.interceptors.response.use(undefined, errorLogInterceptor);
};
