import { vars } from '@seed-design/design-token';
import { forwardRef } from 'react';
import styled from '@emotion/styled';
import TextField, { TextFieldProps } from 'components/Base/TextField';

export type InputNumberProps = {
  sideText?: string;
  /** iOS에서 숫자 키보드가 뜨도록 해요. */
  iOSNumberKeyboard?: boolean;
  /** iOS 숫자 키보드에 소수점 입력이 가능하도록 해줘요. */
  decimalPoint?: boolean;
} & TextFieldProps;

const InputNumber = forwardRef<HTMLInputElement, InputNumberProps>(
  (
    {
      sideText,
      value,
      onChange,
      placeholder = '0',
      decimalPoint = true,
      iOSNumberKeyboard = true,
      ...props
    },
    ref
  ) => {
    const handleChange = (v: string) => {
      const value = decimalPoint ? v : v.split(/\D/g)[0];
      onChange?.(value);
    };

    return (
      <TextField
        ref={ref}
        {...props}
        type="number"
        inputMode={decimalPoint ? 'decimal' : undefined}
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        pattern={iOSNumberKeyboard ? '\\d*' : undefined}
        suffix={sideText && <SideText>{sideText}</SideText>}
      />
    );
  }
);

export default InputNumber;

const SideText = styled.p`
  ${vars.$semantic.typography.bodyL1Regular}
`;
