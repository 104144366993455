import { P, match } from 'ts-pattern';

type GetRangeParam = {
  minValue: number;
  maxValue: number;
} & ({ increment: number } | { incrementRanges: { threshold: number; increment: number }[] });

export const generateRange = (params: GetRangeParam) => {
  const { minValue, maxValue } = params;

  const amountArray: number[] = [];
  const increments = match(params)
    .with(
      {
        increment: P.select(),
      },
      (p) => [{ threshold: 0, increment: p }]
    )
    .with(
      {
        incrementRanges: P.select(),
      },
      (p) => p
    )
    .exhaustive();

  let currentValue = minValue;
  let rangeIndex = 0;

  while (currentValue <= maxValue) {
    amountArray.push(currentValue);

    if (rangeIndex < increments.length - 1 && currentValue >= increments[rangeIndex].threshold) {
      rangeIndex++;
    }
    currentValue += increments[rangeIndex].increment;
  }

  amountArray.push(maxValue + 1);

  return amountArray;
};
