import React, { useState } from 'react';
import { copyToClipboard } from 'utils/Clipboard';
import { toast } from 'bridge/toast';
import BottomSheet from 'components/BottomSheet/BottomSheet';
import { HStack, Stack } from '@chakra-ui/layout';
import { IconCopyThin, IconArrowThin } from '@seed-design/react-icon';
import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { openExternalUrl } from 'utils/Url';

type Props = {
  to: string;
} & React.AnchorHTMLAttributes<HTMLAnchorElement>;

const LinkWithCopy = ({ to, children, ...props }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const openSheet = () => setIsOpen(true);
  const closeSheet = () => setIsOpen(false);

  const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    props.onClick?.(e);
    openSheet();
  };

  const handleCopyClick = () => {
    copyToClipboard(to)
      .then(() => {
        toast('클립보드에 복사되었어요.');
      })
      .catch(() => {
        toast('링크 복사에 실패했어요.');
      });

    closeSheet();
  };

  const handleSiteClick = () => {
    openExternalUrl(to);

    closeSheet();
  };

  return (
    <>
      <a href={to} {...props} onClick={handleLinkClick}>
        {children}
      </a>
      <BottomSheet isOpen={isOpen} onClose={closeSheet} showCloseButton={false}>
        <Container p={7.5} spacing={0}>
          <HStack py={11} spacing={12} onClick={handleCopyClick}>
            <IconCopyThin width={24} height={24} />
            <span>주소 복사</span>
          </HStack>
          <HStack py={11} spacing={12} onClick={handleSiteClick}>
            <IconArrowThin width={24} height={24} />
            <span>사이트 열기</span>
          </HStack>
        </Container>
      </BottomSheet>
    </>
  );
};

export default LinkWithCopy;

const Container = styled(Stack)`
  ${vars.$semantic.typography.bodyM2Regular};
`;
