import { EventProviderType } from './types/common';

/**
 * params의 event_type를 key로 사용하여 providerMapping에서 맵핑되는 provider를 리턴하는 함수
 * @param params
 * @param providerMapping
 */
export const getEventProvider = (
  event_type?: string,
  providerMapping?: { [key: string]: EventProviderType[] }
) => {
  if (!event_type || !providerMapping) return null;

  if (providerMapping[event_type]) {
    return providerMapping[event_type];
  }

  return null;
};
