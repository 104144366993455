/**
 * @generated SignedSource<<b7608a94564f47f1ccadd7486d26b3d2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type ArticleInvisibleStatusEnum = "BLOCKED" | "CHECK_DENY" | "NONE";
export type ArticleStatusEnum = "ON_GOING" | "RESERVED" | "TRADED";
import { FragmentRefs } from "relay-runtime";
export type ArticlePublic_article$data = {
  readonly invisibleStatus: ArticleInvisibleStatusEnum;
  readonly isHide: boolean;
  readonly status: ArticleStatusEnum;
  readonly " $fragmentType": "ArticlePublic_article";
};
export type ArticlePublic_article$key = {
  readonly " $data"?: ArticlePublic_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArticlePublic_article">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "ArticlePublic_article"
};

(node as any).hash = "562ac1df20549aa041a0b66620aff8e1";

export default node;
