import { useSetAtom } from 'jotai';
import { useEffect, useRef } from 'react';
import { debugModeState } from 'store/debug';

const DEBUG_MODE_THRESHOLD = {
  time: 500,
  pos: 10,
  count: 5,
};

const useDebugModeObserver = () => {
  const debugClicks = useRef<{ x: number; y: number }[]>([]);
  const timerId = useRef<number | null>(null);
  const setDebugMode = useSetAtom(debugModeState);

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      const { clientX, clientY } = e;
      const prevClick = debugClicks.current[debugClicks.current.length - 1];

      timerId.current && window.clearTimeout(timerId.current);
      timerId.current = window.setTimeout(() => {
        debugClicks.current = [];
      }, DEBUG_MODE_THRESHOLD.time);

      if (
        prevClick &&
        Math.abs(prevClick.x - clientX) < DEBUG_MODE_THRESHOLD.pos &&
        Math.abs(prevClick.y - clientY) < DEBUG_MODE_THRESHOLD.pos
      ) {
        debugClicks.current.push({ x: clientX, y: clientY });
      } else {
        debugClicks.current = [{ x: clientX, y: clientY }];
      }

      if (debugClicks.current.length >= DEBUG_MODE_THRESHOLD.count) {
        setDebugMode(true);
      }
    };

    window.addEventListener('click', handleClick);

    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, []);
};

export default useDebugModeObserver;
