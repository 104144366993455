import React from 'react';
import { Stack } from '@chakra-ui/layout';
import Text from 'components/Base/Text';
import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { useAppPreloadedQuery } from 'contexts/AppPreloadedContext';
import { graphql, useFragment } from 'react-relay';
import { Step2_userYearReport$key } from '__generated__/Step2_userYearReport.graphql';

export default function Step2({
  userYearReportRef,
}: {
  userYearReportRef: Step2_userYearReport$key;
}) {
  const { viewer } = useAppPreloadedQuery();

  const userYearReport = useFragment(
    graphql`
      fragment Step2_userYearReport on UserYearReport {
        readArticleCount
        countPercentile
      }
    `,
    userYearReportRef
  );

  return (
    <Wrapper>
      <TextWrapper gap={16} px={20}>
        <Text variant="title1Bold">
          {viewer?.nickname}님은 총 {userYearReport.readArticleCount}개의 매물을 <br />
          조회한 <ColoredText>상위 {userYearReport.countPercentile}%</ColoredText> 이웃이에요
        </Text>
        <Text variant="title3Bold">
          그렇다면, <br />
          우리 동네 이웃들은
          <br />
          얼마나 많이 매물을 조회했을까요?
        </Text>
      </TextWrapper>

      <BackgroundImage src="https://assetstorage.krrt.io/1420322515413545053/f847d761-76d7-4881-a7e2-6149a538acb3/width=750,height=752.webp" />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

const TextWrapper = styled(Stack)`
  position: relative;
  z-index: 100;
`;

const ColoredText = styled.span`
  color: ${vars.$semantic.color.primary};
`;

const BackgroundImage = styled.img`
  position: absolute;
  left: 0;
  top: 0;

  width: 100%;
  z-index: 0;
`;
