import { captureException } from '@sentry/core';
import { useAdvertisementInfo_advertisementInfo$data } from '__generated__/useAdvertisementInfo_advertisementInfo.graphql';
import instance from 'apis/instance';
import { useState } from 'react';
import type { Nilable } from 'utils/Type';

const useAdvertisementLogger = (
  advertisementInfo: Nilable<useAdvertisementInfo_advertisementInfo$data>
) => {
  const [adApi] = useState(() => instance({ baseURL: '' }));

  const callAPI = (url: string | undefined) => () => {
    try {
      url && adApi.post(url);
    } catch (e: unknown) {
      const error = e as Error;
      error.message = `[광고 로그] ${error.message}`;
      captureException(error, {
        extra: {
          url,
        },
      });
    }
  };

  const logger = {
    click: callAPI(advertisementInfo?.clickTrackingUrl),
    impression: callAPI(advertisementInfo?.impressionTrackingUrl),
    hide: callAPI(advertisementInfo?.hideUrl),
    report: callAPI(advertisementInfo?.reportUrl),
  };

  return logger;
};

export default useAdvertisementLogger;
