import { getRangeText } from 'pages/Filter/components/RangeFilter';
import type { SearchFilter } from 'store/searchFilter';
import { Nilable } from 'utils/Type';
import { SubscribeForm } from 'store/subscribe';
import {
  billionConvert,
  getArticleOptionNameText,
  getFloorTypeText,
  SalesTypeText,
} from '@daangn/realty-sdk';
import {
  ArticleOptionNameEnum,
  FloorFilterEnum,
  SalesTypeEnum,
  SalesTypeFilterEnum,
  type BuildingApprovalDateFilterEnum,
  type TradeTypeFilterEnum,
} from 'types/schemaEnums';
import { OptionTypes } from 'components/Filter/OptionsSetting';
import { FloorTypes } from 'components/Filter/FloorSetting';
import { generateRange } from 'utils/slider';
import { match } from 'ts-pattern';
import type { PriceSearchAmountRangeInput } from '__generated__/searchFilterMutation.graphql';

export type FilterPriceType = 'pricePay' | 'depositPay' | 'monthlyPay' | 'yearlyPay';
export type PriceFilter = Pick<SearchFilter, FilterPriceType>;

const MonthlyPayTradeType = ['MONTH', 'SHORT'] as TradeTypeFilterEnum[];
const DepositPayTradeType = ['MONTH', 'SHORT', 'YEAR', 'BORROW'] as TradeTypeFilterEnum[];
export const FilterSalesTypes: SalesTypeFilterEnum[] = [
  'ONE_ROOM',
  'TWO_ROOM',
  'OFFICETEL',
  'APART',
  'STORE',
  'ETC',
];
export const FilterTradeTypes = [
  'MONTH',
  'YEAR',
  'BORROW',
  'BUY',
  'SHORT',
] as TradeTypeFilterEnum[];
export const FilterPriceTypes = [
  'depositPay',
  'yearlyPay',
  'monthlyPay',
  'pricePay',
] satisfies FilterPriceType[];
export const FilterBuildingApprovalDateTypes = [
  'LESS_2YEARS',
  'LESS_5YEARS',
  'LESS_10YEARS',
  'LESS_15YEARS',
  'MORE_15YEARS',
] satisfies BuildingApprovalDateFilterEnum[];

export const checkIsFilterSalesType = (salesType: string): salesType is SalesTypeFilterEnum => {
  return FilterSalesTypes.includes(salesType as SalesTypeFilterEnum);
};

export const convertArticleSalesTypeToFilter = (salesType: SalesTypeEnum): SalesTypeFilterEnum => {
  if (salesType === 'OPEN_ONE_ROOM' || salesType === 'SPLIT_ONE_ROOM') {
    return 'ONE_ROOM';
  }
  return salesType;
};

const checkIsMonthlyPayTradeType = (tradeType: TradeTypeFilterEnum) =>
  MonthlyPayTradeType.includes(tradeType);

const checkIsDepositPayTradeType = (tradeType: TradeTypeFilterEnum) =>
  DepositPayTradeType.includes(tradeType);

export const getFilterPriceText = (type: FilterPriceType) => {
  switch (type) {
    case 'pricePay':
      return '매매가';
    case 'monthlyPay':
      return '월세';
    case 'yearlyPay':
      return '연세';
    case 'depositPay':
      return '보증금';
    default:
      return '가격';
  }
};

export const getFilterTradeTypeText = (tradeType: TradeTypeFilterEnum) => {
  switch (tradeType) {
    case 'SHORT':
      return '단기';
    case 'MONTH':
      return '월세';
    case 'YEAR':
      return '연세';
    case 'BORROW':
      return '전세';
    case 'BUY':
      return '매매';
  }
};

export const getFilterRangeText = (
  minValue: Nilable<number>,
  maxValue: Nilable<number>,
  options: {
    formatValue?: (v: number) => string;
    prefix?: string;
  }
) => {
  const { formatValue = (v) => String(v), prefix } = options;
  const rangeText = getRangeText(minValue, maxValue, formatValue);

  if (rangeText === '전체') {
    return '';
  }

  return `${prefix ? `${prefix} ` : ''}${rangeText}`;
};

export const getFilterPriceRangeText = (
  priceType: FilterPriceType,
  value: PriceSearchAmountRangeInput
) => {
  const priceText = getFilterPriceText(priceType);

  return getFilterRangeText(value.minAmount, value.maxAmount, {
    prefix: priceText,
    formatValue: (v) => billionConvert(v, { withUnit: true }),
  });
};

export const getFilterAreaRangeText = (
  { minArea, maxArea }: { minArea: Nilable<string>; maxArea: Nilable<string> },
  prefix?: string
) =>
  getFilterRangeText(
    minArea ? Math.ceil(Number(minArea) / 3.3) : null,
    maxArea ? Math.ceil(Number(maxArea) / 3.3) : null,
    { prefix, formatValue: (v) => `${v}평` }
  );

export const getFilterSalesTypesText = (salesTypes: readonly SalesTypeFilterEnum[]) => {
  const sortedSalesTypes = [...salesTypes].sort((a, b) => {
    return FilterSalesTypes.indexOf(a) - FilterSalesTypes.indexOf(b);
  });

  return sortedSalesTypes
    ?.map((salesType) => SalesTypeText(salesType, { shorten: true }))
    .filter(Boolean)
    .join(', ');
};

export const getFilterTradeTypesText = (tradeTypes: readonly TradeTypeFilterEnum[]) => {
  const sortedTradeTypes = [...tradeTypes].sort((a, b) => {
    return FilterTradeTypes.indexOf(a) - FilterTradeTypes.indexOf(b);
  });

  return sortedTradeTypes
    ?.map((trade) => getFilterTradeTypeText(trade))
    .filter(Boolean)
    .join(',');
};

export const getFilterVideoText = (withVideo?: boolean | null | undefined) =>
  withVideo ? '영상' : undefined;

export const getFilterOptionsText = (
  options: Nilable<readonly ArticleOptionNameEnum[]>,
  moveInNow?: boolean | null
) => {
  const sortedOptions = [...(options || [])].sort((a, b) => {
    return OptionTypes.indexOf(a) - OptionTypes.indexOf(b);
  });
  const optionText = sortedOptions?.map((option) =>
    getArticleOptionNameText(option, { isBroker: false })
  );

  return [...optionText, moveInNow && '즉시입주'].filter(Boolean).join(', ');
};

export const getFilterFloorText = (floorTypes: Nilable<readonly FloorFilterEnum[]>) => {
  if (!floorTypes) return '';

  const sortedFloorTypes = [...floorTypes].sort((a, b) => {
    return FloorTypes.indexOf(a) - FloorTypes.indexOf(b);
  });

  return sortedFloorTypes
    ?.map((floor) => getFloorTypeText(floor))
    .filter(Boolean)
    .join(', ');
};

export const getFilterBuildingApprovalDateText = (
  buildingApprovalDate: BuildingApprovalDateFilterEnum
) => {
  return match(buildingApprovalDate)
    .with('LESS_2YEARS', () => '2년 이내')
    .with('LESS_5YEARS', () => '5년 이내')
    .with('LESS_10YEARS', () => '10년 이내')
    .with('LESS_15YEARS', () => '15년 이내')
    .with('MORE_15YEARS', () => '15년 이상')
    .exhaustive();
};

const filterNestedEmpty = (obj: Record<string, any>): any[] => {
  return Object.values(obj).filter((value) => {
    if (Array.isArray(value)) {
      return value.length !== 0;
    }

    if (typeof value === 'object' && value !== null) {
      return filterNestedEmpty(value).length !== 0;
    }

    return !!value;
  });
};

export const getFilterCount = (filterState: SearchFilter | SubscribeForm) => {
  return filterNestedEmpty(filterState).length;
};

export const getValidFilterPriceTypes = (
  tradeTypes: TradeTypeFilterEnum[] | readonly TradeTypeFilterEnum[],
  isJeju?: boolean
): Record<FilterPriceType, boolean> => {
  if (tradeTypes.length === 0) {
    return {
      monthlyPay: true,
      depositPay: true,
      pricePay: true,
      yearlyPay: !!isJeju,
    };
  }

  const monthlyPay = tradeTypes.some((type) => checkIsMonthlyPayTradeType(type));
  const depositPay = tradeTypes.some((type) => checkIsDepositPayTradeType(type));
  const pricePay = tradeTypes.some((type) => type === 'BUY');
  const yearlyPay = !!isJeju && tradeTypes.some((type) => type === 'YEAR');

  return {
    monthlyPay,
    depositPay,
    pricePay,
    yearlyPay,
  };
};

// https://daangn.slack.com/archives/C019D3X6CGZ/p1697516398539849
export const getFilterPriceRange = (priceType: FilterPriceType) => {
  switch (priceType) {
    case 'pricePay':
      return generateRange({
        minValue: 0,
        maxValue: 100000,
        incrementRanges: [
          {
            threshold: 1000,
            increment: 100,
          },
          { threshold: 50000, increment: 1000 },
          { threshold: Infinity, increment: 2000 },
        ],
      });
    case 'depositPay':
      return generateRange({
        minValue: 0,
        maxValue: 100000,
        incrementRanges: [
          {
            threshold: 1000,
            increment: 100,
          },
          { threshold: 50000, increment: 1000 },
          { threshold: Infinity, increment: 2000 },
        ],
      });
    case 'monthlyPay':
      return generateRange({
        minValue: 0,
        maxValue: 500,
        incrementRanges: [
          {
            threshold: 100,
            increment: 5,
          },
          {
            threshold: Infinity,
            increment: 50,
          },
        ],
      });
    case 'yearlyPay':
      return generateRange({
        minValue: 0,
        maxValue: 2000,
        incrementRanges: [
          {
            threshold: 100,
            increment: 5,
          },
          {
            threshold: Infinity,
            increment: 50,
          },
        ],
      });
  }
};

export const checkIsWithInPriceRange = (
  price: number,
  range?: Nilable<PriceSearchAmountRangeInput>
) => {
  if (!range) {
    return true;
  }

  if (range.minAmount && price < range.minAmount) {
    return false;
  }

  if (range.maxAmount && price > range.maxAmount) {
    return false;
  }

  return true;
};
