import CONFIG from 'config';
import instance from './instance';

const api = instance({ baseURL: CONFIG.BIZ_ACCOUNT_HOST });

export default {
  fetchBizAccounts: async () => {
    const { data } = await api.get(`/api/v2/business_accounts`);

    return data.data.businessAccounts || [];
  },
};
