/**
 * @generated SignedSource<<f9bb636650ae750dfb4a0b4b8abdd109>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ArticleStatusEnum = "ON_GOING" | "RESERVED" | "TRADED";
import { FragmentRefs } from "relay-runtime";
export type ArticleStatusBadge_article$data = {
  readonly id: string;
  readonly status: ArticleStatusEnum;
  readonly " $fragmentType": "ArticleStatusBadge_article";
};
export type ArticleStatusBadge_article$key = {
  readonly " $data"?: ArticleStatusBadge_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArticleStatusBadge_article">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArticleStatusBadge_article",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "Article",
  "abstractKey": null
};

(node as any).hash = "0f195dc3c13513ede6c7852e49065479";

export default node;
