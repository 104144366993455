import CONFIG from 'config';
import instance from './instance';

const SHORTCUT_SERVICE_NAME = 'VISITABLE_SERVICE_REALTY';

const api = instance({ baseURL: CONFIG.SHORTCUT_HOST });

export default {
  visited: async () => {
    try {
      await api.post(`/public/shortcut/v1/users/me/services/${SHORTCUT_SERVICE_NAME}/visited`, {
        visitedAt: new Date().toISOString(),
      });
    } catch (e) {}
  },
};
