import { karrotBridge } from 'bridge';

const DEFAULT_MAX_IMAGE_COUNT = 10;

type BridgeImageConfig = Parameters<typeof karrotBridge.configureImage>[0]['image'];

const DefaultImageConfig = {
  maxImageCount: DEFAULT_MAX_IMAGE_COUNT,
  disableCompress: true,
} satisfies BridgeImageConfig;

export const setImageConfig = (config: BridgeImageConfig) => {
  karrotBridge.configureImage({
    image: {
      ...DefaultImageConfig,
      ...config,
    },
  });
};

export const resetImageConfig = () => {
  setImageConfig(DefaultImageConfig);
};
