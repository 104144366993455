/**
 * @generated SignedSource<<ba5aa885c7c90a2526cb3dd8f7fd27ca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Step5_userYearReport$data = {
  readonly tags: ReadonlyArray<string>;
  readonly " $fragmentType": "Step5_userYearReport";
};
export type Step5_userYearReport$key = {
  readonly " $data"?: Step5_userYearReport$data;
  readonly " $fragmentSpreads": FragmentRefs<"Step5_userYearReport">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Step5_userYearReport",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tags",
      "storageKey": null
    }
  ],
  "type": "UserYearReport",
  "abstractKey": null
};

(node as any).hash = "c574bd4cf944de0c67b9f5f02014e2c6";

export default node;
