import instance from 'apis/instance';
import CONFIG from 'config';

const api = instance({ baseURL: CONFIG.API_HOST });

export default {
  /** In use on useSessionStore */
  getCenterCoordinate: async (
    regionId: number
  ): Promise<{
    lat: string;
    lon: string;
  }> => {
    const { data } = await api.get(`/api/v1/regions/${regionId}/center_coord`);

    return data.data.coord;
  },
};
