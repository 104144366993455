/* eslint-disable @typescript-eslint/ban-types */
import {
  AccordionDefaultEventParams,
  ActionSheetEventType,
  ArticleItemDefaultEventParams,
  ArticlePullupDefaultEventParams,
  BetaActionSheetEventType,
  BottomSheetEventType,
  DialogEventType,
  ToastEventType,
} from 'logger/types/params';
import {
  ListTopRecommendAgeRangeEnum,
  ListTopRecommendArticleExperimentSegmentEnum,
  ListTopRecommendGenderEnum,
  SalesTypeEnum,
  TradeTypeEnum,
  ViewerWriteFailureReason,
} from 'types/schemaEnums';
import { FilterType } from 'constants/article';
import { MainBannerServiceType } from 'pages/Main/components/Banner/useMainAdBanner';
import { EventProviderType } from 'logger/types/common';
import type { FortuneResultTab } from 'pages/MyRealtyFortuneResult/components/FortuneResult';
import type { FortuneFormInput } from 'pages/MyRealtyFortune/components/FortuneForm';
import { RecapStepKey } from 'pages/Recap';
import { AreaUnit } from 'types/Article';
import type { RegionSearch } from 'store/regionSearch';
import type { SearchFilter } from 'store/searchFilter';

export type LoggerEventType = keyof LoggerEventSchema;
export type LoggerEventParams<T extends LoggerEventType> = LoggerEventSchema[T];

/**
 * EventSchema - 이벤트별 타입을 정의
 */
export type LoggerEventSchema = {
  /* BottomSheet */
  bottom_sheet:
    | {
        event_type: BottomSheetEventType;
      }
    | {
        event_type: 'auto_approve_articles';
        position: 'detail_footer' | 'my_article_item_actions';
      };
  click_bottom_sheet_close:
    | {
        event_type: BottomSheetEventType;
      }
    | {
        event_type: 'auto_approve_articles';
        position: 'detail_footer' | 'my_article_item_actions';
      };
  click_bottom_sheet_dim:
    | {
        event_type: BottomSheetEventType;
      }
    | {
        event_type: 'auto_approve_articles';
        position: 'detail_footer' | 'my_article_item_actions';
      };
  drag_bottom_sheet_handler:
    | {
        event_type: BottomSheetEventType;
      }
    | {
        event_type: 'auto_approve_articles';
        position: 'detail_footer' | 'my_article_item_actions';
      };
  /* ActionSheet */
  click_action_sheet: {
    event_type: ActionSheetEventType;
  };
  /* BetaActionSheet */
  beta_action_sheet_close: {
    event_type: BetaActionSheetEventType;
  };
  beta_action_sheet_action: {
    event_type: BetaActionSheetEventType;
  };
  /* AlertDialog */
  show_dialog:
    | {
        event_type: DialogEventType;
      }
    | {
        event_type: 'non_writable';
        reason: ViewerWriteFailureReason;
      };
  click_dialog_primary_action:
    | {
        event_type: DialogEventType;
      }
    | {
        event_type: 'non_writable';
        reason: ViewerWriteFailureReason;
      };
  click_dialog_secondary_action:
    | {
        event_type: DialogEventType;
      }
    | {
        event_type: 'non_writable';
        reason: ViewerWriteFailureReason;
      };
  /* ActionToast */
  show_toast: {
    event_type: ToastEventType;
  };
  click_toast_action: {
    event_type: ToastEventType;
  };
  /* ArticleItem */
  show_article_item: ArticleItemDefaultEventParams &
    (
      | {}
      | {
          type: 'main_broker';
          region_2_name: string;
          sales_type: SalesTypeEnum | null;
          trade_type: TradeTypeEnum | null;
        }
    );
  click_article_item: ArticleItemDefaultEventParams &
    (
      | {}
      | {
          type: 'main_broker';
          region_2_name: string;
          sales_type: SalesTypeEnum | null;
          trade_type: TradeTypeEnum | null;
        }
    );
  /* ImpressionLog */
  show_detail_section: {
    article_id: string;
    section: string;
  };
  show_banner_icon:
    | {}
    | {
        event_type: 'article_tool_certification';
      };
  click_banner_icon:
    | {}
    | {
        event_type: 'article_tool_certification';
      };
  show_broker_notice_banner: {
    id: string;
  };
  click_broker_notice_banner: {
    id: string;
  };
  show_broker_faq_banner: {};
  click_broker_faq_banner: {};
  show_main_minimum_realty_carousel: {};
  click_main_minimum_realty_carousel: {
    title: string;
  };
  show_detail_minimum_realty_carousel: {};
  click_detail_minimum_realty_carousel: {
    title: string;
  };
  show_search_region_recommend_list: {
    region: string;
  };
  show_empty_image_button: {
    article_id: string;
    is_my_article: boolean;
  };
  click_request_upload_image: {
    article_id: string;
    is_my_article: boolean;
  };
  click_edit_image: {
    article_id: string;
    is_my_article: boolean;
  };
  show_pullup_bottom_btn_sheet: ArticlePullupDefaultEventParams;
  article_pullup: ArticlePullupDefaultEventParams;
  click_article_update_trades: ArticlePullupDefaultEventParams & {
    is_trade_changed: boolean;
  };
  article_update_trades: ArticlePullupDefaultEventParams;
  click_advertisement_my_article: ArticlePullupDefaultEventParams;
  click_advertisement_no_wait: ArticlePullupDefaultEventParams & {
    is_trade_changed: boolean;
  };
  click_edit_advertisement: ArticlePullupDefaultEventParams;
  click_edit_advertisement_sub: ArticlePullupDefaultEventParams;
  satisfaction_rate_banner_show: {};
  show_main_filter_chip: { type: FilterType };
  click_main_filter_chip: { type: FilterType };
  click_main_reset_filter_button: {};
  list_top_brokers: {
    region_2_name: string;
    sales_type: SalesTypeEnum | null;
    trade_type: TradeTypeEnum | null;
  };
  list_top_recommend: {
    has_advertisement: boolean;
    region_2_name: string | undefined;
    segment_group: ListTopRecommendArticleExperimentSegmentEnum | undefined;
    ageRange?: ListTopRecommendAgeRangeEnum | undefined;
    gender?: ListTopRecommendGenderEnum | undefined;
  };
  click_refresh_recommend: {
    has_advertisement: boolean;
    region_2_name: string | undefined;
    segment_group: ListTopRecommendArticleExperimentSegmentEnum | undefined;
    ageRange?: ListTopRecommendAgeRangeEnum | undefined;
    gender?: ListTopRecommendGenderEnum | undefined;
  };
  show_recent_watched_articles: {};
  show_selected_article_sheet: {};
  show_seller_review_banner: {};
  click_seller_review_banner_title: {};
  click_seller_review_banner_more: {};
  scroll_seller_review_banner: {};
  show_subscribe_chip: {
    is_create: boolean;
    is_edit: boolean;
  };
  click_create_subscribe_chip: {};
  click_edit_subscribe_chip: {};
  show_main_watch_articles: {};
  show_main_ad_banner: {
    service: MainBannerServiceType;
    banner_type: string;
  };
  click_main_ad_banner: {
    service: MainBannerServiceType;
    banner_type: string;
  };
  show_marketing_banner: {
    campaign: string;
  };
  click_marketing_banner: {
    campaign: string;
  };
  suggest_verify: {};
  suggest_request_verify: {};
  suggest_video_upload: {};
  show_direct_deal_filter: {};
  /* Review */
  click_review_confirm_button: {
    send_user_id: string;
    receiver_user_id: string;
    article_id: string;
    is_viewer_sent_review: boolean;
  };
  show_approve_article_button: {
    article_id: string;
    position: string;
    is_pending: boolean;
  };
  show_request_approve_article_button: {
    article_id: string;
    position: string;
    is_pending: boolean;
  };
  show_detail_realty_home_shortcut: {
    article_id: string;
  };
  /* Main */
  show_main_marketing_simple_form_banner: {};
  click_main_marketing_simple_form_banner: {};
  /* BannerCallout */
  click_callout_banner: { event_type: 'tell_us' };
  /* CarouselSection */
  click_more_carousel:
    | {
        event_type: 'similar_articles' | 'recent_watched' | 'main_watch_articles';
      }
    | {
        event_type: 'main_broker';
        region_2_name: string;
        sales_type: SalesTypeEnum | null;
        trade_type: TradeTypeEnum | null;
      }
    | {
        event_type: 'recommend';
        has_advertisement: boolean;
        region_2_name: string | undefined;
        segment_group: ListTopRecommendArticleExperimentSegmentEnum | undefined;
        ageRange?: ListTopRecommendAgeRangeEnum | undefined;
        gender?: ListTopRecommendGenderEnum | undefined;
      }
    | {
        event_type: 'my_realty_fortune';
        tab: FortuneResultTab;
      };
  scroll_carousel:
    | {
        event_type: 'similar_articles' | 'recent_watched' | 'main_watch_articles';
      }
    | {
        event_type: 'main_broker';
        region_2_name: string;
        sales_type: SalesTypeEnum | null;
        trade_type: TradeTypeEnum | null;
      }
    | {
        event_type: 'recommend';
        has_advertisement: boolean;
        region_2_name: string | undefined;
        segment_group: ListTopRecommendArticleExperimentSegmentEnum | undefined;
        ageRange?: ListTopRecommendAgeRangeEnum | undefined;
        gender?: ListTopRecommendGenderEnum | undefined;
      }
    | {
        event_type: 'my_realty_fortune';
        tab: FortuneResultTab;
      };
  /* FloatingTooltip */
  show_tooltip: { event_type: 'broker_banner' | 'verify_banner' | 'sun_section' };
  /* PcUploadBanner */
  click_broker_pc_upload_banner: {} | { event_type: 'sales_type' | 'address' };
  /* Accordion */
  click_accordion: AccordionDefaultEventParams & {
    event_type: 'land_title_bond' | 'land_title_keyword' | 'land_title_small_deposit';
    article_id: string;
  };
  show_detail_fortune_banner: {
    article_id: string;
  };
  click_detail_fortune_banner: {
    article_id: string;
  };
  click_fortune_share_top: {};
  click_fortune_share_button: {};
  click_fortune_form: {};
  click_fortune_realty_more_button: {};
  click_fortune_result_submit_button: FortuneFormInput;
  click_fortune_tab_button: {
    tab: FortuneResultTab;
  };
  show_recap_banner: {};
  click_recap_banner: {};
  show_recap_step: {
    step: RecapStepKey;
  };
  click_recap_step: {
    prev_step: RecapStepKey;
    next_step: RecapStepKey;
    action: 'button' | 'touch';
  };
  click_recap_realty_button: {};
  click_recap_share_button: {};
  click_recap_more_button: {};
  show_form_area_chip: {
    sales_type: SalesTypeEnum | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    values: { 평: string; 'm²': string }[];
  };
  click_form_area_chip: {
    sales_type: SalesTypeEnum | null;
    value: string | null | undefined;
    area_unit: AreaUnit;
  };
  // 비즈프로필 연결
  click_biz_profile_check: {};
  complete_biz_profile_check: {
    has_biz_profile: boolean;
  };
  click_biz_profile_bottomsheet_create: {};
  click_biz_profile_bottomsheet_connect: {};
  // 메인
  hamburger_menu: {};
  article_new: {
    location: 'main_header' | 'empty_articles';
  };
  sidebar_write_article: {};
  sidebar_my_article: {};
  sidebar_my_like: {};
  sidebar_subscribe: {};
  sidebar_search_article_number: {};
  click_sidebar_biz_profile: {};
  click_sidebar_biz_profile_approval: {};
  click_sidebar_biz_profile_manager: {};
  sidebar_submenu_faq: {};
  sidebar_submenu_guide: {};
  sidebar_broker: {};
  sidebar_pre_sale: {};
  click_search_realty_onboarding_confirm: {};
  click_search_realty_onboarding_close: {};
  main_list_view: {};
  main_map_view: {};
  main_select_article: {};
  main_change_region: {};
  has_article_id_query: { article_id: string };
  click_map_button: {
    is_map_view: boolean;
  };
  click_direct_deal_filter: {};
  cluster_click: {
    zoom_level: number;
  };
  pin_click: {
    zoom_level: number;
  };
  main_current_bound: {
    zoom_level: number;
  };
  main_current_position: {
    zoom_level: number;
  };
  main_subscribe_banner: {
    subscribed: boolean;
  };
  empty_create_subscribe: {
    has_filter: boolean;
  };
  empty_delete_subscribe: {
    has_filter: boolean;
  };
  empty_subscribe: {};
  main_more_articles: {
    page: number;
  };
  main_suggest_parent_region: {};
  main_suggest_parent_region_show: {};
  show_main_article_list: {
    page: number;
    article_count: number;
    all_page: number;
    article_per_page: number;
    rs: Partial<RegionSearch>;
    sf: SearchFilter;
  };
  // 게시글 상세
  article_detail_refresh: {
    article_id: string;
  };
  realty_home_shortcut: {
    article_id: string;
  };
  more_recommend: {
    article_id: string;
  };
  click_broker_rewrite: {
    article_id: string;
  };
  scroll_article_detail: {
    article_id: string;
    scroll_percentage: number;
  };
  article_report: {
    article_id: string;
  };
  click_detail_biz_profile_recheck: {
    article_id: string;
  };
  video_encoding_fail_edit: {};
  video_encoding_fail_close: {};
  article_image_gallery: {
    article_id: string;
    from?: string;
  };
  article_image_lightbox: {
    article_id: string;
    from?: string;
  };
  play_video: {
    article_id?: string;
    from?: string;
  };
  playtime_video: {
    article_id?: string;
    from?: string;
    playtime: string;
    source?: string;
  };
  article_user: {
    article_id: string;
    target_user_id: number;
  };
  article_status_change: {
    article_id: string;
    prev_status: string;
    next_status: string;
  };
  open_advertisement_create: {
    article_id: string;
  };
  click_recommend_create_advertisement: {
    article_id: string;
    is_hide: boolean;
  };
  click_land_title: {
    article_id: string;
  };
  click_detail_map_type: {
    article_id: string;
    map_type: string;
  };
  article_map: {
    article_id: string;
  };
  click_detail_address_toggle: {
    address_type: string;
  };
  click_detail_address_copy: {
    article_id: string;
    address_type: string;
  };
  click_facility_detail_view: {
    article_id: string;
  };
  click_show_all_traded_result: {
    article_id: string;
  };
  click_detail_more_broker_articles: {
    article_id: string;
    biz_profile_id: string;
  };
  click_broker_reviews_more: {
    article_id: string;
    biz_profile_id: string;
  };
  click_broker_info_section_write_reivew: {
    article_id: string;
  };
  click_report_broker_review: {
    visit_review_id: string;
    biz_profile_id: string;
  };
  click_article_detail_guide: {};
  click_article_detail_broker: {};
  click_approve_article_button: {
    article_id: string;
    position: 'detail_footer' | 'my_article_item_actions';
    is_pending: boolean;
  };
  click_request_approve_article_button: {
    article_id: string;
    position: 'detail_footer' | 'my_article_item_actions';
    is_pending: boolean;
  };
  article_chat_list: {
    article_id: string;
  };
  click_go_like_article_button: {
    article_id: string;
  };
  click_contact_button: {
    article_id: string;
  };
  click_article_info_button: {
    article_id: string;
  };
  click_edit_phone_number_button: {
    article_id: string;
  };
  show_watch_button_price_down_tooltip: {};
  click_verify_fail_dialog_confirm: {
    article_id: string;
  };
  verify_fail_dialog_faq: {};
  bottom_sheet_suggest_verify_confirm: {
    article_id: string;
  };
  bottom_sheet_suggest_verify_close: {
    article_id: string;
  };
};

export type EventProviderMapping<T extends LoggerEventType> = LoggerEventSchema[T] extends {
  event_type: string;
}
  ? Record<LoggerEventSchema[T]['event_type'], EventProviderType[]>
  : never;

/**
 * EventProperties - 이벤트별 값을 정의
 */
export const LoggerEventProperties: {
  [K in keyof LoggerEventSchema]: {
    description: string;
    provider: EventProviderType[];
    type: 'click' | 'impression';
    providerMapping?: Partial<EventProviderMapping<K>>;
  };
} = {
  /* BottomSheet */
  bottom_sheet: {
    description: '공통 바텀시트 노출',
    provider: ['firebase', 'ga4'],
    type: 'impression',
    providerMapping: {
      ai_conversion: ['firebase', 'ga4', 'mixpanel'],
      biz_profile_connect: ['firebase', 'ga4', 'amplitude'],
      biz_profile_invitation_onboarding: ['firebase', 'ga4', 'amplitude'],
      biz_profile_image_verification_fail: ['firebase', 'ga4', 'amplitude'],
    },
  },
  click_bottom_sheet_close: {
    description: '공통 바텀시트 닫기 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
    providerMapping: {
      ai_conversion: ['firebase', 'ga4', 'amplitude', 'mixpanel'],
    },
  },
  click_bottom_sheet_dim: {
    description: '공통 바텀시트 dim 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
    providerMapping: {
      ai_conversion: ['firebase', 'ga4', 'amplitude', 'mixpanel'],
    },
  },
  drag_bottom_sheet_handler: {
    description: '공통 바텀시트 핸들러 드래그',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
    providerMapping: {
      ai_conversion: ['firebase', 'ga4', 'amplitude', 'mixpanel'],
    },
  },
  /* ActionSheet */
  click_action_sheet: {
    description: '액션시트 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  /* BetaActionSheet */
  beta_action_sheet_close: {
    description: '베타 액션시트 닫기 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  beta_action_sheet_action: {
    description: '베타 액션시트 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  /* AlertDialog */
  show_dialog: {
    description: '공통 다이얼로그 노출',
    provider: ['firebase', 'ga4'],
    type: 'impression',
    providerMapping: {
      biz_profile_invalid_brn: ['firebase', 'ga4', 'amplitude'],
      biz_profile_duplicated_brn: ['firebase', 'ga4', 'amplitude'],
      biz_profile_create_block_leave: ['firebase', 'ga4', 'amplitude'],
    },
  },
  click_dialog_primary_action: {
    description: '공통 다이얼로그 Primary 버튼 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_dialog_secondary_action: {
    description: '공통 다이얼로그 Secondary 버튼 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  /* ActionToast */
  show_toast: {
    description: '공통 토스트 노출',
    provider: ['firebase', 'ga4'],
    type: 'impression',
  },
  click_toast_action: {
    description: '공통 토스트 액션 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  /* ArticleItem */
  show_article_item: {
    description: '아티클 아이템 노출',
    provider: ['firebase', 'ga4'],
    type: 'impression',
  },
  click_article_item: {
    description: '아티클 아이템 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  /* ImpressionLog */
  show_detail_section: {
    description: '아티클 상세 섹션 노출',
    provider: ['firebase', 'ga4'],
    type: 'impression',
  },
  show_banner_icon: {
    description: '공통 배너 아이콘 노출',
    provider: ['firebase', 'ga4'],
    type: 'impression',
  },
  click_banner_icon: {
    description: '공통 배너 아이콘 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  show_broker_notice_banner: {
    description: '메인 > 중개사용 공지사항 배너 노출',
    provider: ['firebase', 'ga4'],
    type: 'impression',
  },
  click_broker_notice_banner: {
    description: '메인 > 중개사용 공지사항 배너 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  show_broker_faq_banner: {
    description: '메인 > 중개사용 FAQ 배너 노출',
    provider: ['firebase', 'ga4'],
    type: 'impression',
  },
  click_broker_faq_banner: {
    description: '메인 > 중개사용 FAQ 배너 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  show_main_minimum_realty_carousel: {
    description: '메인 > 최소한의 부동산 정보 캐로셀 노출',
    provider: ['firebase', 'ga4'],
    type: 'impression',
  },
  click_main_minimum_realty_carousel: {
    description: '메인 > 최소한의 부동산 정보 캐로셀 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  show_detail_minimum_realty_carousel: {
    description: '상세 > 최소한의 부동산 정보 캐로셀 노출',
    provider: ['firebase', 'ga4'],
    type: 'impression',
  },
  click_detail_minimum_realty_carousel: {
    description: '상세 > 최소한의 부동산 정보 캐로셀 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  show_search_region_recommend_list: {
    description: '지역 검색 페이지 > region과 함께 자주 찾는 곳 목록이 보였을 때',
    provider: ['firebase', 'ga4'],
    type: 'impression',
  },
  show_empty_image_button: {
    description: '상세 > 사진 올리기/업로드 버튼 노출',
    provider: ['firebase', 'ga4'],
    type: 'impression',
  },
  click_request_upload_image: {
    description: '상세 > 사진 올리기 요청 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_edit_image: {
    description: '상세 > 사진 올리기 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  show_pullup_bottom_btn_sheet: {
    description: '끌어올리기 > 하단 버튼 시트 노출',
    provider: ['firebase', 'ga4'],
    type: 'impression',
  },
  article_pullup: {
    description: '끌어올리기 > 끌어올리기 버튼 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_article_update_trades: {
    description: '끌어올리기 > 매물 타입 변경 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  article_update_trades: {
    description: '끌어올리기 > 매물 타입 변경',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_advertisement_my_article: {
    description: '끌어올리기 > 내 매물 맞춤 광고하기 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_advertisement_no_wait: {
    description: '끌어올리기 > 기다리지 않고 광고하기 버튼 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_edit_advertisement: {
    description: '끌어올리기 > 광고 수정하기 버튼 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_edit_advertisement_sub: {
    description: '끌어올리기 > 광고 수정하기 서브 버튼 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  satisfaction_rate_banner_show: {
    description: '메인 > 만족도 조사 배너 노출',
    provider: ['firebase', 'ga4'],
    type: 'impression',
  },
  show_main_filter_chip: {
    description: '메인 > 필터칩 노출',
    provider: ['firebase', 'ga4'],
    type: 'impression',
  },
  click_main_filter_chip: {
    description: '메인 > 필터칩 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_main_reset_filter_button: {
    description: '메인 > 필터칩 초기화 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  list_top_brokers: {
    description: '메인 > 최상단 중개사 매물 섹션 노출',
    provider: ['firebase', 'ga4'],
    type: 'impression',
  },
  list_top_recommend: {
    description: '메인 > 최상단 추천매물 섹션 노출',
    provider: ['firebase', 'ga4'],
    type: 'impression',
  },
  click_refresh_recommend: {
    description: '메인 > 최상단 추천매물 섹션 > 새로고침 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  show_recent_watched_articles: {
    description: '메인 > 최근 본 매물 섹션 노출',
    provider: ['firebase', 'ga4'],
    type: 'impression',
  },
  show_selected_article_sheet: {
    description: '메인 > 지도탭 아티클 시트 노출',
    provider: ['firebase', 'ga4'],
    type: 'impression',
  },
  show_seller_review_banner: {
    description: '메인 > 판매자 리뷰 배너 노출',
    provider: ['firebase', 'ga4'],
    type: 'impression',
  },
  click_seller_review_banner_title: {
    description: '메인 > 판매자 리뷰 배너 타이틀 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_seller_review_banner_more: {
    description: '메인 > 판매자 리뷰 배너 더보기 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  scroll_seller_review_banner: {
    description: '메인 > 판매자 리뷰 배너 스크롤',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  show_subscribe_chip: {
    description: '메인 > 새글알림 Chip 노출',
    provider: ['firebase', 'ga4'],
    type: 'impression',
  },
  click_create_subscribe_chip: {
    description: '메인 > 새글알림 생성 chip 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_edit_subscribe_chip: {
    description: '메인 > 새글알림 수정 chip 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  show_main_watch_articles: {
    description: '메인 > 관심목록 캐로셀 노출',
    provider: ['firebase', 'ga4'],
    type: 'impression',
  },
  show_main_ad_banner: {
    description: '메인 > 광고 배너 노출',
    provider: ['firebase', 'ga4'],
    type: 'impression',
  },
  click_main_ad_banner: {
    description: '메인 > 광고 배너 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  show_marketing_banner: {
    description: '메인 > 마케팅 배너 노출',
    provider: ['firebase', 'ga4'],
    type: 'impression',
  },
  click_marketing_banner: {
    description: '메인 > 마케팅 배너 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  suggest_verify: {
    description: '상세 > 집주인 인증 유도 섹션 노출',
    provider: ['firebase', 'ga4'],
    type: 'impression',
  },
  suggest_request_verify: {
    description: '상세 > 집주인 인증 요청 섹션 노출',
    provider: ['firebase', 'ga4'],
    type: 'impression',
  },
  suggest_video_upload: {
    description: '상세 > 비디오 업로드 유도 섹션 노출',
    provider: ['firebase', 'ga4'],
    type: 'impression',
  },
  show_direct_deal_filter: {
    description: '메인 > 직거래 매물만 보기 필터 노출',
    provider: ['firebase', 'ga4'],
    type: 'impression',
  },
  /* Review */
  click_review_confirm_button: {
    description: '리뷰 > 확인 버튼 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  show_approve_article_button: {
    description: '상세 > 매물 등록 승인하기 버튼 노출',
    provider: ['firebase', 'ga4'],
    type: 'impression',
  },
  show_request_approve_article_button: {
    description: '상세 > 매물 등록 승인하기 버튼 노출',
    provider: ['firebase', 'ga4'],
    type: 'impression',
  },
  show_detail_realty_home_shortcut: {
    description: '상세 > 직거래 바로가기 노출',
    provider: ['firebase', 'ga4'],
    type: 'impression',
  },
  show_main_marketing_simple_form_banner: {
    description: '마케팅 매물 작성 배너 임프레션',
    provider: ['firebase', 'ga4'],
    type: 'impression',
  },
  click_main_marketing_simple_form_banner: {
    description: '마케팅 매물 작성 배너 클릭',
    provider: ['firebase', 'ga4'],
    type: 'click',
  },
  click_callout_banner: {
    description: '콜아웃 배너 클릭',
    provider: ['firebase', 'ga4'],
    type: 'click',
  },
  click_more_carousel: {
    description: '캐로셀 섹션 더보기 클릭',
    provider: ['firebase', 'ga4'],
    type: 'click',
  },
  scroll_carousel: {
    description: '캐로셀 섹션 스크롤',
    provider: ['firebase', 'ga4'],
    type: 'click',
  },
  show_tooltip: {
    description: 'floating tooltip 노출 공통 이벤트',
    provider: ['firebase', 'ga4'],
    type: 'impression',
  },
  click_broker_pc_upload_banner: {
    description: 'PC 버전 업로드 배너 클릭한 이벤트예요.',
    provider: ['firebase', 'ga4'],
    type: 'click',
  },
  click_accordion: {
    description: '아코디언 클릭',
    provider: ['firebase', 'ga4'],
    type: 'click',
  },
  show_detail_fortune_banner: {
    description: '상세 > 운세 배너 노출',
    provider: ['firebase', 'ga4'],
    type: 'impression',
  },
  click_detail_fortune_banner: {
    description: '상세 > 운세 배너 클릭',
    provider: ['firebase', 'ga4'],
    type: 'click',
  },
  click_fortune_share_top: {
    description: '운세 > 공유 탑 클릭',
    provider: ['firebase', 'ga4'],
    type: 'click',
  },
  click_fortune_share_button: {
    description: '운세 > 공유 버튼 클릭',
    provider: ['firebase', 'ga4'],
    type: 'click',
  },
  click_fortune_realty_more_button: {
    description: '운세 > 매물 더보기 버튼 클릭',
    provider: ['firebase', 'ga4'],
    type: 'click',
  },
  click_fortune_form: {
    description: '운세 > 폼 클릭',
    provider: ['firebase', 'ga4'],
    type: 'click',
  },
  click_fortune_result_submit_button: {
    description: '운세 > 결과 제출 버튼 클릭',
    provider: ['firebase', 'ga4'],
    type: 'click',
  },
  click_fortune_tab_button: {
    description: '운세 > 탭 버튼 클릭',
    provider: ['firebase', 'ga4'],
    type: 'click',
  },
  show_recap_banner: {
    description: '메인 > 2024 연말정산 배너 노출',
    provider: ['firebase', 'ga4'],
    type: 'impression',
  },
  click_recap_banner: {
    description: '메인 > 2024 연말정산 배너 클릭',
    provider: ['firebase', 'ga4'],
    type: 'click',
  },
  show_recap_step: {
    description: '2024 연말정산 스텝 노출',
    provider: ['firebase', 'ga4'],
    type: 'impression',
  },
  click_recap_step: {
    description: '2024 연말정산 스텝 이동',
    provider: ['firebase', 'ga4'],
    type: 'click',
  },
  click_recap_realty_button: {
    description: '2024 연말정산 결과 > 당근 부동산 구경하기 버튼 클릭',
    provider: ['firebase', 'ga4'],
    type: 'click',
  },
  click_recap_share_button: {
    description: '2024 연말정산 결과 > 공유하기 버튼 클릭',
    provider: ['firebase', 'ga4'],
    type: 'click',
  },
  click_recap_more_button: {
    description: '2024 연말정산 결과 > 추천 게시글 더보기 버튼 클릭',
    provider: ['firebase', 'ga4'],
    type: 'click',
  },
  show_form_area_chip: {
    description: '폼 > 전용면적 칩 노출',
    provider: ['firebase', 'ga4'],
    type: 'impression',
  },
  click_form_area_chip: {
    description: '폼 > 전용면적 칩 클릭 (value: undefined - 해제, null - 확인이 필요해요)',
    provider: ['firebase', 'ga4'],
    type: 'click',
  },
  click_biz_profile_check: {
    description: '공통 > 비즈프로필 존재 여부 체크 클릭',
    provider: ['firebase', 'ga4'],
    type: 'click',
  },
  complete_biz_profile_check: {
    description: '공통 > 비즈프로필 존재 여부 체크 완료',
    provider: ['firebase', 'ga4'],
    type: 'click',
  },
  click_biz_profile_bottomsheet_create: {
    description: '공통 > 비즈프로필 연결 바텀시트 > 생성 클릭',
    provider: ['firebase', 'ga4'],
    type: 'click',
  },
  click_biz_profile_bottomsheet_connect: {
    description: '공통 > 비즈프로필 연결 바텀시트 > 연결 클릭',
    provider: ['firebase', 'ga4'],
    type: 'click',
  },
  hamburger_menu: {
    description: '메인 > 햄버거 메뉴 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  article_new: {
    description: '메인 > 글쓰기 버튼 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  sidebar_write_article: {
    description: '메인 > 사이드바 > 글쓰기 버튼 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  sidebar_my_article: {
    description: '메인 > 사이드바 > 내 매물 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  sidebar_my_like: {
    description: '메인 > 사이드바 > 내 관심목록 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  sidebar_subscribe: {
    description: '메인 > 사이드바 > 알림 관리 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  sidebar_search_article_number: {
    description: '메인 > 사이드바 > 매물번호 검색 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_sidebar_biz_profile: {
    description: '메인 > 사이드바 > 운영자 추가/관리 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_sidebar_biz_profile_approval: {
    description: '메인 > 사이드바 > 운영자 승인 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_sidebar_biz_profile_manager: {
    description: '메인 > 사이드바 > 운영자 관리 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  sidebar_submenu_faq: {
    description: '메인 > 사이드바 > FAQ 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  sidebar_submenu_guide: {
    description: '메인 > 사이드바 > 가이드 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  sidebar_broker: {
    description: '메인 > 사이드바 > 중개사 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  sidebar_pre_sale: {
    description: '메인 > 사이드바 > 분양 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_search_realty_onboarding_confirm: {
    description: '메인 > 매물번호 검색 온보딩 확인버튼 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_search_realty_onboarding_close: {
    description: '메인 > 매물번호 검색 온보딩 닫기버튼 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  main_list_view: {
    description: '메인 > 리스트 뷰 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  main_map_view: {
    description: '메인 > 지도 뷰 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  main_select_article: {
    description: '메인 > 매물 선택 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  has_article_id_query: {
    description: '메인 > url의 article_id로 게시물 조회',
    provider: ['firebase', 'ga4'],
    type: 'click',
  },
  main_change_region: {
    description: '메인 > 지역 변경 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_map_button: {
    description: '메인 > 지도 버튼 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_direct_deal_filter: {
    description: '메인 > 직거래 매물만 보기 필터 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  cluster_click: {
    description: '메인 > 지도 클러스터 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  pin_click: {
    description: '메인 > 지도 핀 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  main_current_bound: {
    description: '메인 > 현재 지역 바운드 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  main_current_position: {
    description: '메인 > 현재 위치 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  main_subscribe_banner: {
    description: '메인 > 새글알림 배너 노출',
    provider: ['firebase', 'ga4'],
    type: 'impression',
  },
  empty_create_subscribe: {
    description: '메인 > 매물 없을 때 알림 생성 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  empty_delete_subscribe: {
    description: '메인 > 매물 없을 때 알림 삭제 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  empty_subscribe: {
    description: '메인 > 매물 없을 때 알림 유도 노출',
    provider: ['firebase', 'ga4'],
    type: 'impression',
  },
  main_more_articles: {
    description: '메인 > 더보기 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  main_suggest_parent_region: {
    description: '메인 > 지역 추천 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  main_suggest_parent_region_show: {
    description: '메인 > 지역 추천 노출',
    provider: ['firebase', 'ga4'],
    type: 'impression',
  },
  show_main_article_list: {
    description: '메인 > 매물 리스트 노출',
    provider: ['firebase', 'ga4'],
    type: 'impression',
  },
  // 상세 페이지
  article_detail_refresh: {
    description: '상세 > Pull to Refresh 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  realty_home_shortcut: {
    description: '상세 > 부동산 바로가기 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  more_recommend: {
    description: '상세 > 추천 게시글 더보기 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_broker_rewrite: {
    description: '상세 > 중개사 글 다시쓰기 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  scroll_article_detail: {
    description: '상세 > 스크롤 퍼센트',
    provider: ['firebase', 'ga4'],
    type: 'impression',
  },
  article_report: {
    description: '상세 > 신고하기 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_detail_biz_profile_recheck: {
    description: '상세 > 비즈프로필 재검수 요청 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  video_encoding_fail_edit: {
    description: '상세 > 비디오 인코딩 실패 > 다시 시도 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  video_encoding_fail_close: {
    description: '상세 > 비디오 인코딩 실패 > 닫기 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  article_image_gallery: {
    description: '상세 > 이미지 갤러리 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  article_image_lightbox: {
    description: '상세 > 이미지 라이트박스 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  play_video: {
    description: '상세 > 비디오 재생',
    provider: ['firebase', 'ga4'],
    type: 'click',
  },
  playtime_video: {
    description: '상세 > 비디오 재생 시간',
    provider: ['firebase', 'ga4'],
    type: 'click',
  },
  article_user: {
    description: '상세 > 유저 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  article_status_change: {
    description: '상세 > 매물 상태 변경',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  open_advertisement_create: {
    description: '상세 > 광고 생성 페이지 진입',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_recommend_create_advertisement: {
    description: '상세 > 광고 생성 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_land_title: {
    description: '상세 > 등기부등본 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_detail_map_type: {
    description: '상세 > 지도 타입 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  article_map: {
    description: '상세 > 지도 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_detail_address_toggle: {
    description: '상세 > 주소 타입 토글 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_detail_address_copy: {
    description: '상세 > 주소 복사 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_facility_detail_view: {
    description: '상세 > 편의시설 상세 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_show_all_traded_result: {
    description: '상세 > 실거래가 거래 결과 더보기 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_detail_more_broker_articles: {
    description: '상세 > 중개사 게시글 더보기 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_broker_reviews_more: {
    description: '상세 > 중개사 리뷰 더보기 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_broker_info_section_write_reivew: {
    description: '상세 > 중개사 리뷰 작성 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_report_broker_review: {
    description: '상세 > 중개사 리뷰 신고 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_article_detail_guide: {
    description: '상세 > 가이드 확인하기 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_article_detail_broker: {
    description: '상세 > 우리동네 중개사 찾아보기 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_approve_article_button: {
    description: '상세 > 중개사 게시글 승인 버튼 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_request_approve_article_button: {
    description: '상세 > 중개사 게시글 승인 요청 버튼 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  article_chat_list: {
    description: '상세 > 채팅 목록보기 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_go_like_article_button: {
    description: '상세 > 게시글 관심 후 토스트에서 나의 관심목록 보기 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_contact_button: {
    description: '상세 > 문의하기 버튼 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_article_info_button: {
    description: '상세 > 게시글 정보 버튼 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  click_edit_phone_number_button: {
    description: '상세 > 연락처 수정 버튼 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  show_watch_button_price_down_tooltip: {
    description: '상세 > 관심목록 추가 툴팁 노출',
    provider: ['firebase', 'ga4'],
    type: 'impression',
  },
  click_verify_fail_dialog_confirm: {
    description: '상세 > 집주인 인증 실패 다이얼로그 확인 버튼 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  verify_fail_dialog_faq: {
    description: '상세 > 집주인 인증 실패 다이얼로그 FAQ 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  bottom_sheet_suggest_verify_confirm: {
    description: '상세 > 집주인 인증 유도 바텀시트 확인 버튼 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
  bottom_sheet_suggest_verify_close: {
    description: '상세 > 집주인 인증 유도 바텀시트 닫기 버튼 클릭',
    provider: ['firebase', 'ga4', 'amplitude'],
    type: 'click',
  },
};
