import { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';

export enum KeyboardVisibleType {
  UNKNOWN = 'unknown',
  VISIBLE = 'visible',
  HIDDEN = 'hidden',
}

const keyboardVisibleContext = createContext<KeyboardVisibleType>(KeyboardVisibleType.UNKNOWN);

export const useKeyboardVisible = () => useContext(keyboardVisibleContext);

const KeyboardVisibleContextProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [keyboardVisible, setKeyboardVisible] = useState(KeyboardVisibleType.UNKNOWN);

  useEffect(() => {
    const visualViewport = window.visualViewport;

    if (!visualViewport) return;

    // 0.25 를 키보드 비율의 최소로 추정. ex) screen height 1000px이면 250px은 resize가 되어야 키보드가 올라왔다고 판단
    const VIEWPORT_VS_CLIENT_HEIGHT_RATIO = 0.75;

    const handleResize = (event: VisualViewportEventMap['resize']) => {
      if (!event.target) return;
      const target = event.target as VisualViewport;

      setKeyboardVisible(
        (target.height * target.scale) / window.screen.height < VIEWPORT_VS_CLIENT_HEIGHT_RATIO
          ? KeyboardVisibleType.VISIBLE
          : KeyboardVisibleType.HIDDEN
      );
    };

    visualViewport.addEventListener('resize', handleResize);

    return () => {
      visualViewport.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <keyboardVisibleContext.Provider value={keyboardVisible}>
      {children}
    </keyboardVisibleContext.Provider>
  );
};

export default KeyboardVisibleContextProvider;
