const Path = {
  Main: '/',
  Articles: '/articles',
  NewArticle: '/articles/new',
  SimpleNewArticle: '/articles/new_simple',
  ArticleDraft: '/articles/draft',
  ArticleStepEdit: '/articles/step_edit/:stepId',
  Article: '/articles/:articleId',
  ArticleImages: '/articles/:articleId/images',
  ArticleCheckList: '/articles/:articleId/checklist',
  ArticleRoadView: '/articles/:articleId/road_view',
  ArticleCheckListMemo: '/articles/:articleId/checklist/memo',
  ArticleTradeTool: '/articles/:articleId/trade_tool',
  ArticleTradeToolDetail: '/articles/:articleId/trade_tool/:type',
  ArticleRequestVerify: '/articles/:articleId/request_verify',
  ArticleVerify: '/articles/:articleId/verify',
  EditArticle: '/articles/:articleId/edit',
  EditArticleImage: '/articles/:articleId/edit_image',
  PullupArticle: '/articles/:articleId/pullup',
  ArticleChannelList: '/articles/:articleId/channel_list',
  ArticleLandTitle: '/articles/:articleId/land_title',
  ArticleLandTitleCertificate: '/articles/:articleId/land_title/certificate',
  ArticleTradeSurvey: '/articles/:articleId/trade_survey',
  ArticleSunlight: '/articles/:articleId/sunlight',
  SearchAddress: '/search_address',
  SearchRegion: '/search_region',
  SearchUniversity: '/search_university',
  Subscribe: '/subscribe',
  SubscribeSetting: '/subscribe_setting',
  SubscribeRegion: '/subscribe_region',
  SubscribeOnboarding: '/subscribe_onboarding',
  SatisfactionSurvey: '/satisfaction_survey',
  My: '/my',
  Review: '/review',
  ReviewNew: '/review/new',
  TradeReviewNew: '/trade_review/new',
  TradeReviewSelectBuyer: '/trade_review/select_buyer',
  SendReviews: '/send_reviews/:articleId',
  SellerReviews: '/seller_reviews',
  Filter: '/filter',
  Guide: '/guide/:salesType/:tradeType',
  ImageGuide: '/image_guide/:salesType',
  AlphaLogin: '/alpha_login',
  SmsIdentification: '/sms_identification',
  MySalesHistory: '/my_sales_history',
  VerifyArticles: '/verify_articles',
  Faq: '/faq',
  SettingNotification: '/setting/notification/:type',
  ArticleCompare: '/article_compare',
  AdvertisementCreate: '/advertisement/create/:articleId',
  AIConversion: '/ai_conversion',
  RecentWatchedArticles: '/recent_watched_articles',
  BrokerPreOpenApplication: '/broker_pre_open_application',
  BrokerOnboarding: '/broker_onboarding',
  Complete: '/complete',
  BizProfileApprovalSetting: '/biz_profile/approval_setting',
  BizProfileCreate: '/biz_profile/create',
  BizProfileInfo: '/biz_profile/info',
  BizProfileReviews: '/biz_profile/:bizProfileId/reviews',
  BizProfileArticles: '/biz_profile/:bizProfileId/articles',
  MarketingPriceSearch: '/marketing/price_search',
  MarketingPriceSearchResult: '/marketing/price_search/result',
  MyRealtyFortune: '/my_realty_fortune',
  MyRealtyFortuneResult: '/my_realty_fortune/result',
  Recap: '/recap',
  RecapResult: '/recap/result',
} as const;

export default Path;
