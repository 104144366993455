import CONFIG from 'config';
import instance from './instance';

const api = instance({ baseURL: CONFIG.BIZ_POST_HOST });

export default {
  fetchArticle: async (bizPostId: string) => {
    const { data } = await api.get(`/api/v1/posts/${bizPostId}`);

    return data.data;
  },
};
