import { graphql } from 'relay-runtime';

export const FilterGraphql = graphql`
  query FilterQuery_SubscribeQuery {
    viewer {
      articleSubscribe {
        ...useSubscribeFragment_articleSubscribe
      }
    }
  }
`;
