import { useEffect, useState } from 'react';

const DarkModeMediaQuery = '(prefers-color-scheme: dark)';

const useDarkMode = () => {
  const [isDarkMode, setIsDarkMode] = useState(window.matchMedia(DarkModeMediaQuery).matches);

  useEffect(() => {
    const onChangeEvent: (e: MediaQueryListEvent) => void = (e) => {
      const colorScheme = e.matches ? 'dark' : 'light';
      setIsDarkMode(colorScheme === 'dark');
    };

    const mediaQuery = window.matchMedia(DarkModeMediaQuery);

    // https://www.designcise.com/web/tutorial/how-to-fix-the-javascript-typeerror-matchmedia-addeventlistener-is-not-a-function
    if (mediaQuery?.addEventListener) {
      mediaQuery.addEventListener('change', onChangeEvent);

      return () => mediaQuery.removeEventListener('change', onChangeEvent);
    } else {
      mediaQuery?.addListener(onChangeEvent);

      return () => mediaQuery?.removeListener(onChangeEvent);
    }
  }, []);

  return isDarkMode;
};

export default useDarkMode;
