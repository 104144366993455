import React from 'react';
import { Error } from '@daangn/error-component';

export type ErrorProps = {
  title?: React.ReactNode;
  children?: React.ReactNode;
  buttonOption?: {
    label?: string;
    onClick: () => void;
  };
};

const ErrorBase = ({ title, children, buttonOption }: ErrorProps) => {
  return (
    <Error
      title={title ? <>{title}</> : undefined}
      description={
        <>
          {children ?? (
            <>
              일시적인 오류가 발생했어요.
              <br />
              잠시 후 다시 시도해주세요.
            </>
          )}
        </>
      }
      buttonText={buttonOption?.label}
      onClickButton={buttonOption?.onClick}
    />
  );
};

export default ErrorBase;
