import { ComponentType, createElement, forwardRef, lazy, useRef } from 'react';

type PreloadableComponent<T extends ComponentType<any>> = T & {
  preload: () => Promise<T>;
};

// https://github.com/ianschmitz/react-lazy-with-preload/blob/master/src/index.ts
// 랜더시 preload된 컴포넌트 사용이 보장되는 로직이 추가됨. (다르면 Suspense 걸림)
export function lazyWithPreload<T extends ComponentType<any>>(
  factory: () => Promise<{ default: T }>
): PreloadableComponent<T> {
  const ReactLazyComponent = lazy(factory);
  let PreloadedComponent: T | undefined;
  let factoryPromise: Promise<T> | undefined;

  const Component = forwardRef(function LazyWithPreload(props, ref) {
    const ComponentToRender = useRef(PreloadedComponent ?? ReactLazyComponent);
    return createElement(
      ComponentToRender.current,
      Object.assign(ref ? { ref } : {}, props) as any
    );
  });

  const LazyWithPreload = Component as any as PreloadableComponent<T>;

  LazyWithPreload.preload = () => {
    if (!factoryPromise) {
      factoryPromise = factory().then((module) => {
        PreloadedComponent = module.default;
        return PreloadedComponent;
      });
    }

    return factoryPromise;
  };

  return LazyWithPreload;
}
