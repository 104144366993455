import type { ArticlePointOrderDirection, ArticlePointOrderKey } from '@daangn/realty-sdk';
import { atom, type SetStateAction } from 'jotai';
import debugState from 'store/debug';
import type { TradedTypeSurveyEnum } from 'types/schemaEnums';
import { actionToast } from 'utils/actionToast';

export type ArticlePointOrder = {
  orderDirection: ArticlePointOrderDirection;
  orderKey: ArticlePointOrderKey;
};

type MainListDebugState = {
  isTraded?: boolean | null;
  order?: ArticlePointOrder | null;
  limit?: number | null;
  tradedType?: TradedTypeSurveyEnum | null;
} | null;

const mainListDebugAtom = atom<{
  isTraded?: boolean | null;
  order?: ArticlePointOrder | null;
  limit?: number | null;
  tradedType?: TradedTypeSurveyEnum | null;
} | null>(null);

export const mainListDebugState = atom(
  (get) => {
    if (!get(debugState)) {
      return null;
    }

    const debug = get(mainListDebugAtom);
    return debug;
  },
  (get, set, newValue: SetStateAction<MainListDebugState>) => {
    if (!get(debugState)) {
      actionToast({ message: '디버그 상태가 아닙니다.' });
    } else {
      set(mainListDebugAtom, newValue);
    }
  }
);
