import {
  openExternalBrowser,
  openInAppBrowser,
  openMinikarrotWebview,
} from '@daangn/webview-link-router';
import { isWeb } from 'bridge';
import CONFIG, { IS_PROD } from 'config';
import qs from 'query-string';

export const overwriteQuery = (url: string, queries: Record<string, string | number> = {}) => {
  const [baseUrl] = url.split('?');
  const { query } = qs.parseUrl(url);

  return getUrlWithQuery(baseUrl, { ...query, ...queries });
};

const getQueryString = (queries?: Record<string, unknown>) => {
  const queryString = new URLSearchParams(queries as Record<string, string>);

  return queries ? `?${queryString}` : '';
};

export const getUrlWithQuery = (url: string, queries?: Record<string, unknown>) => {
  const queryString = getQueryString(queries);

  return `${url}${queryString}`;
};

export type ExternalUrlOption = {
  scrollable?: boolean;
  navbar?: boolean;
};

export const openExternalUrl = (url: string, option?: ExternalUrlOption) => {
  const { scrollable = false, navbar = false } = option || {};
  const isAppScheme = url.startsWith(CONFIG.APP_SCHEME);
  const isKarrotWebview = url.includes(CONFIG.KARROT_WEBVIEW_HOST);
  const stage = IS_PROD ? 'production' : 'alpha';

  if (isAppScheme) {
    openExternalBrowser({ url, stage });

    return;
  }

  if (isKarrotWebview) {
    openMinikarrotWebview({ url, navbar, scrollable, stage });
    return;
  }

  if (isWeb) {
    window.open(url, '_blank');
    return;
  }

  openInAppBrowser({ url, stage });
};
