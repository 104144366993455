import { getArticleStatusText } from '@daangn/realty-sdk';
import type { PropsOf } from '@emotion/react';
import type { ArticleStatusBadge_article$key } from '__generated__/ArticleStatusBadge_article.graphql';
import Badge from 'components/Base/Badge';
import { graphql, useFragment } from 'react-relay';
import type { ArticleStatusEnum } from 'types/schemaEnums';

export const getArticleStatusBadgeProps = (
  status: ArticleStatusEnum
): PropsOf<typeof Badge> | null => {
  if (!['RESERVED', 'TRADED'].includes(status)) {
    return null;
  }

  return {
    size: 'small',
    property: status === 'RESERVED' ? 'success' : 'basic',
    variant: 'fill',
    children: getArticleStatusText(status),
  };
};

type Props = {
  articleRef: ArticleStatusBadge_article$key;
};

const ArticleStatusBadge = ({ articleRef }: Props) => {
  const { status } = useFragment(
    graphql`
      fragment ArticleStatusBadge_article on Article {
        id
        status
      }
    `,
    articleRef
  );

  const badgeProps = getArticleStatusBadgeProps(status);

  if (!badgeProps) {
    return null;
  }

  return <Badge {...badgeProps} />;
};

export default ArticleStatusBadge;
