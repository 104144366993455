import { Stack } from '@chakra-ui/layout';
import Text from 'components/Base/Text';
import styled from '@emotion/styled';
import React, { useMemo } from 'react';
import { useAppPreloadedQuery } from 'contexts/AppPreloadedContext';
import { graphql, useFragment } from 'react-relay';
import { Step1_userYearReport$key } from '__generated__/Step1_userYearReport.graphql';

export default function Step1({
  userYearReportRef,
}: {
  userYearReportRef: Step1_userYearReport$key;
}) {
  const { viewer } = useAppPreloadedQuery();

  const userYearReport = useFragment(
    graphql`
      fragment Step1_userYearReport on UserYearReport {
        readArticleCount
      }
    `,
    userYearReportRef
  );

  const hasData = useMemo(() => {
    return userYearReport.readArticleCount >= 5;
  }, []);

  return (
    <Wrapper>
      <TextWrapper gap={16} px={20}>
        <Text variant="title1Bold">
          2024년 1,600만 명의 이웃이
          <br /> 당근 부동산을 방문했어요
        </Text>
        <Text variant="title2Bold">
          {hasData && <>{viewer?.nickname}님과 </>}
          이웃들은 <br />
          당근 부동산을 어떻게 이용했을까요?
        </Text>
      </TextWrapper>

      <ImageWrapper>
        <img
          src="https://assetstorage.krrt.io/1420322515413545053/9cd8805f-86cc-4d85-88ae-e63b58f00ee9/width=750,height=752.webp"
          width="100%"
        />
        <AssetImage
          src="https://s3-alpha-sig.figma.com/img/2c22/ba5c/5f71523babc11a972604ebaa1b0b05f9?Expires=1731888000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=H4wuKCK9ayGzlqri2d6DH~Qz~P8nealzb1bKq3hgHrHBUsyzMhlLUxX-uvEE5AP-2xj171pyHj9OdjxkyrIE7Zao4RS~4ACGF0RpuyB7SdIqsFyoq2qehHaOVmS6TwWkAh0JmyQlvp5I2dr464MxlQWtI4SdNqXV3Od4nVLNsE7FGpH-ificJNDxU9nK5uG7MKg5Sk46gTJouxegZsnKE5uS-z50CLf4S63ZKwII7ZNM1DmLQp32JOvZUs2I1Fo33oy7nDIqE8EW~1RvjxD6ZlBbgbW8mMP6aGI59dsIx4xSZOb4IQCy4ojm3DiHTLI-CcfZpBjyHds5LJJfe3DZtg__"
          width={200}
          height={200}
        />
      </ImageWrapper>
    </Wrapper>
  );
}

const TextWrapper = styled(Stack)`
  position: relative;
  z-index: 100;
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

const ImageWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
`;

const AssetImage = styled.img`
  position: absolute;
  bottom: -33px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 0;
`;
