import styled from '@emotion/styled';
import { zIndices } from 'constants/zIndices';
import Dim from 'components/Base/Dim';
import { Spinner } from '@daangn/sprout-components-spinner';

type Props = {
  dimmed?: boolean;
};

const GlobalLoading = ({ dimmed }: Props) => {
  return (
    <>
      {dimmed && <Dim />}
      <GlobalLoadingContainer>
        <Spinner />
      </GlobalLoadingContainer>
    </>
  );
};

export default GlobalLoading;

const GlobalLoadingContainer = styled.div`
  position: absolute;
  z-index: ${zIndices.outermost};
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
