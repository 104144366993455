import { stackflow } from '@stackflow/react';
import { basicRendererPlugin } from '@stackflow/plugin-renderer-basic';
import { basicUIPlugin } from '@stackflow/plugin-basic-ui';
import { preloadPlugin, PreloadPluginOptions } from '@stackflow/plugin-preload';
import { historySyncPlugin } from '@stackflow/plugin-history-sync';
import { karrotBridge } from 'bridge';
import routes, { type RouteComponentMap, type RoutePathMap } from 'routes';
import routeWrapperPlugin from 'stackflow/plugins/routeWrapper';
import realtyPlugin from 'stackflow/plugins/realty';
import closeWhenRootPopPlugin from 'stackflow/plugins/closeWhenRootPop';
import { karrotAnalyticsPlugin } from '@daangn/stackflow-plugin-karrot-analytics';
import { loadQuery } from 'react-relay';
import RelayEnvironment from 'relay/environment';
import { FilterGraphql } from 'graphql/FilterQuery';
import { FilterQuery_SubscribeQuery } from '__generated__/FilterQuery_SubscribeQuery.graphql';
import { captureMessage } from '@sentry/react';
import { browserHistory } from 'utils/history';
import depthChangePlugin from 'stackflow/plugins/depthChange';

const environment = karrotBridge.driver.getCurrentEnvironment();

const Activities = routes.reduce(
  (acc, route) => ({
    ...acc,
    [route.name]: route.component,
  }),
  {} as RouteComponentMap
);

const Routes = routes.reduce(
  (acc, route) => ({
    ...acc,
    [route.name]: route.path,
  }),
  {} as RoutePathMap
);

const Loaders = {
  filter: () => {
    const queryRef = loadQuery<FilterQuery_SubscribeQuery>(RelayEnvironment, FilterGraphql, {});
    return { tagNode: FilterGraphql, preloadRef: queryRef };
  },
} satisfies PreloadPluginOptions<ActivitiesType>['loaders'];

export const { Stack, useStepFlow, actions } = stackflow({
  transitionDuration: 350,
  activities: Activities,
  plugins: [
    basicRendererPlugin(),
    basicUIPlugin({
      // PageLayout.tsx에서 대체
      appBar: {
        backButton: {
          renderIcon: () => null,
        },
        closeButton: {
          renderIcon: () => null,
        },
      },
      theme: environment === 'Android' ? 'android' : 'cupertino',
    }),
    historySyncPlugin({
      history: browserHistory,
      routes: Routes,
      fallbackActivity: () => {
        captureMessage('존재하지 않는 activity에 접근했어요.', {
          level: 'warning',
          extra: {
            url: window.location.href,
          },
        });

        return 'main';
      },
    }),
    preloadPlugin({
      loaders: Loaders,
    }),
    depthChangePlugin(),
    routeWrapperPlugin(),
    realtyPlugin(),
    closeWhenRootPopPlugin(),
    karrotAnalyticsPlugin({
      bridge: karrotBridge,
      serviceName: 'realty',
      itemId: {
        article_detail: ({ articleId }) => {
          return articleId;
        },
      },
    }),
  ],
});

export type ActivitiesType = typeof Activities;
export type TypeLoaders = { [key in keyof typeof Loaders]: ReturnType<(typeof Loaders)[key]> };
