import { captureException } from '@sentry/react';
import { IS_REAL } from 'config';
import { UserSegmentType, type UserSegment } from 'utils/segment';

const DEFAULT_SAMPLE_RATE = IS_REAL ? 0.03 : 1;
const AmplitudeSampleRate = {
  [UserSegmentType.Buyer]: DEFAULT_SAMPLE_RATE,
  [UserSegmentType.Seller]: 0.08,
  [UserSegmentType.Broker]: 1,
} satisfies Record<UserSegmentType, number>;

const checkIsSampleUser = async (userId: number, rate: number) => {
  try {
    const md5 = (await import('ts-md5')).Md5;

    const hash = md5.hashStr(userId.toString());
    const intVal = parseInt(hash.substring(0, 8), 16);
    const decimalVal = intVal / 0xffffffff;

    return decimalVal < rate;
  } catch (e) {
    captureException(e);
    return false;
  }
};

export const checkIsAmplitudeTargetUser = async (
  userId?: number,
  segments?: Partial<UserSegment>
) => {
  if (!userId) {
    return false;
  }

  if (!segments) {
    return checkIsSampleUser(userId, DEFAULT_SAMPLE_RATE);
  }

  const segmentTypes = Object.keys(segments) as UserSegmentType[];
  const activatedSegmentTypes = segmentTypes.filter((type) => !!segments[type]);

  // 가장 샘플링 비율 높은 것으로 적용
  const sampleRate =
    activatedSegmentTypes.length > 0
      ? Math.max(...activatedSegmentTypes.map((type) => AmplitudeSampleRate[type]))
      : DEFAULT_SAMPLE_RATE;

  return checkIsSampleUser(userId, sampleRate);
};
