/**
 * @generated SignedSource<<c0fdd6f73ef3f282e000746dd53843a0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type ArticleManageCostOptionEnum = "BOILER" | "CLEANING" | "ELECTRIC" | "ELEVATOR" | "GAS" | "INTERNET" | "PARKING" | "TV" | "WATERWORKS";
export type ManageCostPayOptionEnum = "FIXED" | "NEED_TO_CHECK" | "NONE" | "USED";
export type SalesTypeEnum = "APART" | "ETC" | "OFFICETEL" | "OPEN_ONE_ROOM" | "SPLIT_ONE_ROOM" | "STORE" | "TWO_ROOM";
export type WriterTypeEnum = "BROKER" | "LESSOR" | "TENANT";
import { FragmentRefs } from "relay-runtime";
export type ArticleCostText_article$data = {
  readonly etcManageCost: number | null;
  readonly includeManageCostOptionV2: ReadonlyArray<{
    readonly fixedCost: number | null;
    readonly option: ArticleManageCostOptionEnum;
    readonly payOption: ManageCostPayOptionEnum | null;
  }>;
  readonly isUnknownManageCost: boolean | null;
  readonly manageCost: number | null;
  readonly premiumMoney: number | null;
  readonly salesTypeV2: {
    readonly type: SalesTypeEnum;
  };
  readonly writerType: WriterTypeEnum | null;
  readonly " $fragmentType": "ArticleCostText_article";
};
export type ArticleCostText_article$key = {
  readonly " $data"?: ArticleCostText_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArticleCostText_article">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "ArticleCostText_article"
};

(node as any).hash = "9d3f8061f07878d066b2929be521b151";

export default node;
