import useCustomFlow from 'hooks/useCustomFlow';
import React from 'react';
import LinkWithCopy from 'components/Base/LinkWithCopy';
import type { RouteName } from 'routes';
import { ExternalUrlOption, openExternalUrl } from 'utils/Url';
import { ExtractRouteParamsType } from 'utils/Type';

type CommonLinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement>;
type ExternalLinkProps = {
  to: string;
  external: true;
  useCopy?: boolean;
  option?: ExternalUrlOption;
};
type InternalLinkProps = {
  to: RouteName;
  params: ExtractRouteParamsType<RouteName>;
  external?: false;
  replace?: boolean;
  option?: { animate?: boolean; present?: boolean };
  onNavigate?: (activityId: string) => void;
};

type Props = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  to: string;
} & (ExternalLinkProps | InternalLinkProps);

const ExternalLink = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  external,
  useCopy,
  to,
  option,
  ...linkProps
}: CommonLinkProps & ExternalLinkProps) => {
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    linkProps.onClick?.(e);

    openExternalUrl(to, option);
  };

  return useCopy ? (
    <LinkWithCopy to={to} {...linkProps} />
  ) : (
    <a {...linkProps} onClick={handleClick} />
  );
};

const InternalLink = ({
  to,
  params,
  option,
  replace,
  onClick,
  onNavigate,
  children,
  ...linkProps
}: CommonLinkProps & InternalLinkProps) => {
  const navigator = useCustomFlow();

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    onClick?.(e);

    const { activityId } = replace
      ? navigator.replace(to, params, option)
      : navigator.push(to, params, option);
    onNavigate?.(activityId);
  };

  return (
    <a {...linkProps} onClick={handleClick}>
      {children}
    </a>
  );
};

const InAppLink = (props: Props) => {
  if ('external' in props && props.external) {
    return <ExternalLink {...props} />;
  }

  return <InternalLink {...props} />;
};

export default InAppLink;
