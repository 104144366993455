import { useAdvertisementInfo_advertisementInfo$key } from '__generated__/useAdvertisementInfo_advertisementInfo.graphql';
import { graphql, useFragment } from 'react-relay';

const useAdvertisementInfo = (
  advertisementInfo: useAdvertisementInfo_advertisementInfo$key | null
) => {
  return useFragment(
    graphql`
      fragment useAdvertisementInfo_advertisementInfo on AdvertisementInfo {
        clickTrackingUrl
        hideUrl
        impressionTrackingUrl
        reportUrl
        targetUri
      }
    `,
    advertisementInfo
  );
};

export default useAdvertisementInfo;
