import { Box } from '@chakra-ui/layout';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { zIndices } from 'constants/zIndices';

const Thumbnail = styled(Box)<{ width?: string | number; height?: string | number }>`
  position: relative;
  flex-shrink: 0;

  border: 1px solid ${vars.$scale.color.grayAlpha50};
  border-radius: 6px;

  overflow: hidden;
  z-index: ${zIndices.docked};

  ${({ width, height }) => {
    const imgWidth = width || 118;
    const imgHeight = height || 118;

    return css`
      width: ${imgWidth}px;
      height: ${imgHeight}px;

      & img {
        width: 100%;
        height: 100%;
      }
    `;
  }}
`;

export default Thumbnail;
